"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PubAdHubBrandId = exports.PubAdHubCampaignId = exports.PubAdHubPublisherId = void 0;
var PubAdHubPublisherId;
(function (PubAdHubPublisherId) {
    PubAdHubPublisherId[PubAdHubPublisherId["Aven"] = 5] = "Aven";
})(PubAdHubPublisherId = exports.PubAdHubPublisherId || (exports.PubAdHubPublisherId = {}));
var PubAdHubCampaignId;
(function (PubAdHubCampaignId) {
    PubAdHubCampaignId[PubAdHubCampaignId["Walmart"] = 2] = "Walmart";
})(PubAdHubCampaignId = exports.PubAdHubCampaignId || (exports.PubAdHubCampaignId = {}));
var PubAdHubBrandId;
(function (PubAdHubBrandId) {
    PubAdHubBrandId[PubAdHubBrandId["Walmart"] = 2] = "Walmart";
})(PubAdHubBrandId = exports.PubAdHubBrandId || (exports.PubAdHubBrandId = {}));
