"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenMyNextAction = void 0;
/** Next action after inputting phone number in Aven My */
var AvenMyNextAction;
(function (AvenMyNextAction) {
    AvenMyNextAction["CH_PORTAL"] = "CH_PORTAL";
    AvenMyNextAction["HOME_OG_FLOW"] = "HOME_OG_FLOW";
    /** If deepLink url is for redeeming group cardholder rewards but the user does not have an account */
    AvenMyNextAction["GROUP_CARDHOLDER_REWARDS_INTERSTITIAL"] = "GROUP_CARDHOLDER_REWARDS_INTERSTITIAL";
})(AvenMyNextAction = exports.AvenMyNextAction || (exports.AvenMyNextAction = {}));
