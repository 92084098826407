"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.KnownRunners = exports.Employee = exports.ComponentRunnerCategory = void 0;
/** @TranslateToPython @TranslateToEnum */
var ComponentRunnerCategory;
(function (ComponentRunnerCategory) {
    ComponentRunnerCategory["avenAdvisor"] = "AvenAdvisor";
    ComponentRunnerCategory["income"] = "Income";
    ComponentRunnerCategory["underwriting"] = "Underwriting";
    ComponentRunnerCategory["payments"] = "Payments";
    ComponentRunnerCategory["cardHolder"] = "CardHolder";
    ComponentRunnerCategory["creditPolicy"] = "CreditPolicy";
    ComponentRunnerCategory["metrics"] = "Metrics";
    ComponentRunnerCategory["pendingDisputes"] = "pendingDisputes";
    ComponentRunnerCategory["compliance"] = "Compliance";
    ComponentRunnerCategory["communications"] = "Communications";
    ComponentRunnerCategory["facebookPixel"] = "FacebookPixel";
    ComponentRunnerCategory["googleTagManager"] = "GoogleTagManager";
    ComponentRunnerCategory["utmTags"] = "UtmTags";
    ComponentRunnerCategory["payItForward"] = "payItForward";
    ComponentRunnerCategory["accountOpening"] = "AccountOpening";
    ComponentRunnerCategory["mobileApp"] = "mobileApp";
    ComponentRunnerCategory["dataIntegrity"] = "dataIntegrity";
    ComponentRunnerCategory["notary"] = "notary";
    ComponentRunnerCategory["capitalMarkets"] = "capitalMarkets";
    ComponentRunnerCategory["origination"] = "origination";
    ComponentRunnerCategory["contingencies"] = "contingencies";
    ComponentRunnerCategory["logs"] = "logs";
})(ComponentRunnerCategory = exports.ComponentRunnerCategory || (exports.ComponentRunnerCategory = {}));
/** @TranslateToPython @TranslateToEnum */
var Employee;
(function (Employee) {
    Employee["ABED_ISLAM"] = "ABED_ISLAM";
    Employee["ANIRUDDH_IYENGAR"] = "ANIRUDDH_IYENGAR";
    Employee["AMOR_MENDOZA"] = "AMOR_MENDOZA";
    Employee["ANDREI_NATIVIDAD"] = "ANDREI_NATIVIDAD";
    Employee["ANDREW_ALKHANI"] = "ANDREW_ALKHANI";
    Employee["ANNABELLE_LEAL"] = "ANNABELLE_LEAL";
    Employee["ARINDAM_BOSE"] = "ARINDAM_BOSE";
    Employee["ARYAN_ABED_ESFAHANI"] = "ARYAN_ABED_ESFAHANI";
    Employee["ARYAN_SALAM"] = "ARYAN_SALAM";
    Employee["AUGUSTE_GOODELL"] = "AUGUSTE_GOODELL";
    Employee["AVEN_INFRA"] = "AVEN_INFRA";
    Employee["AVEN_TRIGGERS"] = "AVEN_TRIGGERS";
    Employee["BEN_TRAN"] = "BEN_TRAN";
    Employee["BRETT_DRINGMAN"] = "BRETT_DRINGMAN";
    Employee["BRIAN_XU"] = "BRIAN_XU";
    Employee["BRIEH_QUEJAYCO"] = "BRIEH_QUEJAYCO";
    Employee["CARLO_BALAIS"] = "CARLO_BALAIS";
    Employee["CARMINA_SALUD"] = "CARMINA_SALUD";
    Employee["CHARMAINE_BUNO"] = "CHARMAINE_BUNO";
    Employee["CINDY_WANG"] = "CINDY_WANG";
    Employee["COLLEEN_BRUNNER"] = "COLLEEN_BRUNNER";
    Employee["COLLIN_WIKMAN"] = "COLLIN_WIKMAN";
    Employee["CONNOR_MCKEON"] = "CONNOR_MCKEON";
    Employee["DANIEL_BURKE"] = "DANIEL_BURKE";
    Employee["DANIEL_VENTURA"] = "DANIEL_VENTURA";
    Employee["DARCY_WARBURTON"] = "DARCY_WARBURTON";
    Employee["DAVI_COSTA"] = "DAVI_COSTA";
    Employee["DEANNA_LYNN_EARL"] = "DEANNA_LYNN_EARL";
    Employee["DEIRDRE_REILLY"] = "DEIRDRE_REILLY";
    Employee["DIMITRA_QUINTANILLA"] = "DIMITRA_QUINTANILLA";
    Employee["DINO_BECIROVIC"] = "DINO_BECIROVIC";
    Employee["DIVINE_BALANE"] = "DIVINE_BALANE";
    Employee["ELIEZER_PAREDES"] = "ELIEZER_PAREDES";
    Employee["ELIJAH_LITTMAN"] = "ELIJAH_LITTMAN";
    Employee["ELIZABETH_OSTLER"] = "ELIZABETH_OSTLER";
    Employee["ELLA_EDRADAN"] = "ELLA_EDRADAN";
    Employee["EMANUEL_THOMPSON"] = "EMANUEL_THOMPSON";
    Employee["ENG"] = "ENG";
    Employee["GABRIEL_DE_LA_ESPERANZA"] = "GABRIEL_DE_LA_ESPERANZA";
    Employee["GALLANT_CHEN"] = "GALLANT_CHEN";
    Employee["GERALD_YEO"] = "GERALD_YEO";
    Employee["GRACE_BAUTRO"] = "GRACE_BAUTRO";
    Employee["GRAHAM_DAVID_POWELL"] = "GRAHAM_DAVID_POWELL";
    Employee["HANXIN_JIN"] = "HANXIN_JIN";
    Employee["HIMANSHU_GUSAIN"] = "HIMANSHU_GUSAIN";
    Employee["HUI_CHEN"] = "HUI_CHEN";
    Employee["JAGDIP_SINGH"] = "JAGDIP_SINGH";
    Employee["JASON_QIAN"] = "JASON_QIAN";
    Employee["JAWWAD_ZAKARIA"] = "JAWWAD_ZAKARIA";
    Employee["JAY_DONG"] = "JAY_DONG";
    Employee["JEFF_KRAUS"] = "JEFF_KRAUS";
    Employee["JEFFREY_JIMENEZ"] = "JEFFREY_JIMENEZ";
    Employee["JEN_WIBOWO"] = "JEN_WIBOWO";
    Employee["JENINA_CRISOLOGO"] = "JENINA_CRISOLOGO";
    Employee["JENNYLYN_DACARA"] = "JENNYLYN_DACARA";
    Employee["JERMAINE_KIMBLE"] = "JERMAINE_KIMBLE";
    Employee["JERMIE_LIBUNGAN"] = "JERMIE_LIBUNGAN";
    Employee["JIAYANG_TONG"] = "JIAYANG_TONG";
    Employee["JOHNNEY_CHEN"] = "JOHNNEY_CHEN";
    Employee["JOSE_HERNANDEZ"] = "JOSE_HERNANDEZ";
    Employee["JOSH_GARDUCE"] = "JOSH_GARDUCE";
    Employee["JOSH_RINCON"] = "JOSH_RINCON";
    Employee["JOSH_SMITH"] = "JOSH_SMITH";
    Employee["JOSHUA_MEYER"] = "JOSHUA_MEYER";
    Employee["JULIA_BESERMAN"] = "JULIA_BESERMAN";
    Employee["JULIE_ANN_GEMAL"] = "JULIE_ANN_GEMAL";
    Employee["JULIUS_BRAZA"] = "JULIUS_BRAZA";
    Employee["JUSTIN_POLLARD"] = "JUSTIN_POLLARD";
    Employee["JUSTINE_ERALDO"] = "JUSTINE_ERALDO";
    Employee["KAITLYNN_LINDBO"] = "KAITLYNN_LINDBO";
    Employee["KEITH_CUETO"] = "KEITH_CUETO";
    Employee["KENNETH_BAILEY"] = "KENNETH_BAILEY";
    Employee["KORRY_THOMPSON"] = "KORRY_THOMPSON";
    Employee["KYLA_GALICIA"] = "KYLA_GALICIA";
    Employee["LILLY_LUO"] = "LILLY_LUO";
    Employee["LIZZ_BARCENAS"] = "LIZZ_BARCENAS";
    Employee["LORRIE_BILTOFT"] = "LORRIE_BILTOFT";
    Employee["LYDIA_WANG"] = "LYDIA_WANG";
    Employee["MA_RENELDA_SANTOS"] = "MA_RENELDA_SANTOS";
    Employee["MARIBETH_ALCANTARA"] = "MARIBETH_ALCANTARA";
    Employee["MARICON_JAVIER"] = "MARICON_JAVIER";
    Employee["MARK_LIU"] = "MARK_LIU";
    Employee["MATHEW_BLACK"] = "MATHEW_BLACK";
    Employee["MEGAN_GARCIA"] = "MEGAN_GARCIA";
    Employee["MICHAEL_THIEM"] = "MICHAEL_THIEM";
    Employee["MITCH_VILLANERA"] = "MITCH_VILLANERA";
    Employee["MOESHA_PASSLEY"] = "MOESHA_PASSLEY";
    Employee["MOHAMMED_ABDULRAHMAN"] = "MOHAMMED_ABDULRAHMAN";
    Employee["MURTADA_SHAH"] = "MURTADA_SHAH";
    Employee["NANCY_RANCE_DIONISIO"] = "NANCY_RANCE_DIONISIO";
    Employee["NAVIN_MANI"] = "NAVIN_MANI";
    Employee["NEIL_LIU"] = "NEIL_LIU";
    Employee["NIC_W"] = "NIC_W";
    Employee["NICK_STEPHENSON"] = "NICK_STEPHENSON";
    Employee["NIKKI_BEAVER"] = "NIKKI_BEAVER";
    Employee["PATRICK_EVANS"] = "PATRICK_EVANS";
    Employee["PEARL_FETALCORIN"] = "PEARL_FETALCORIN";
    Employee["PONJ_ASUNCION"] = "PONJ_ASUNCION";
    Employee["RACHEL_BORG"] = "RACHEL_BORG";
    Employee["RAY_SABADO"] = "RAY_SABADO";
    Employee["RAYAN_RIZVI"] = "RAYAN_RIZVI";
    Employee["REENA_JOY_GALECIO"] = "REENA_JOY_GALECIO";
    Employee["RIAH_ROSALES"] = "RIAH_ROSALES";
    Employee["RICH_WALKER"] = "RICH_WALKER";
    Employee["RONALD_PERKOWSKI"] = "RONALD_PERKOWSKI";
    Employee["RONALD_S_CHILLEMI"] = "RONALD_S_CHILLEMI";
    Employee["RUI_ZHOU"] = "RUI_ZHOU";
    Employee["RUTH_MAY_TOLETNINO"] = "RUTH_MAY_TOLETNINO";
    Employee["SADI_KHAN"] = "SADI_KHAN";
    Employee["SARANSH_SHARMA"] = "SARANSH_SHARMA";
    Employee["SARO_VASUDEVAN"] = "SARO_VASUDEVAN";
    Employee["SATCHEL_YANCEY_SIEGEL"] = "SATCHEL_YANCEY_SIEGEL";
    Employee["SCOTT_KING"] = "SCOTT_KING";
    Employee["SEAN_DOLAN"] = "SEAN_DOLAN";
    Employee["SEBASTIAN_GONZALEZ"] = "SEBASTIAN_GONZALEZ";
    Employee["SHAHEER_HASAN"] = "SHAHEER_HASAN";
    Employee["SHELLEY_WU"] = "SHELLEY_WU";
    Employee["SHUSHAUNA_BACCAS"] = "SHUSHAUNA_BACCAS";
    Employee["STEPHEN_LILLETHUN"] = "STEPHEN_LILLETHUN";
    Employee["STEVEN_THURMAN"] = "STEVEN_THURMAN";
    Employee["TARIK_GRAHAM"] = "TARIK_GRAHAM";
    Employee["TIANYANG_ZHANG"] = "TIANYANG_ZHANG";
    Employee["TONY_FORINO"] = "TONY_FORINO";
    Employee["VAIBHAV_PURANIK"] = "VAIBHAV_PURANIK";
    Employee["VALERIE_KAY"] = "VALERIE_KAY";
    Employee["VANISSA_EVANGELISTA"] = "VANISSA_EVANGELISTA";
    Employee["WILFREDO_OCIONES"] = "WILFREDO_OCIONES";
    Employee["WILLIAM_QIN"] = "WILLIAM_QIN";
    Employee["XIAOCHEN_LIU"] = "XIAOCHEN_LIU";
    Employee["XING_XU"] = "XING_XU";
    Employee["XUEMEI_LIU"] = "XUEMEI_LIU";
    Employee["YANG_PEI"] = "YANG_PEI";
    Employee["YIN_WANG"] = "YIN_WANG";
    Employee["ZEWEN_WU"] = "ZEWEN_WU";
    Employee["ISHAN_VAID"] = "ISHAN_VAID";
    Employee["USMAN_GHANI"] = "USMAN_GHANI";
    Employee["ZELA_FLORES"] = "ZELA_FLORES";
    Employee["OSWALDO_MARTINEZ"] = "OSWALDO_MARTINEZ";
})(Employee = exports.Employee || (exports.Employee = {}));
/** @TranslateToPython @TranslateToEnum */
var KnownRunners;
(function (KnownRunners) {
    // Provider data runners
    KnownRunners["coreLogicKnownLandUseRunner"] = "coreLogicKnownLandUseRunner";
    // underwriting verification runners
    KnownRunners["underwritingIncompleteAppRunner"] = "underwritingIncompleteAppRunner";
    KnownRunners["underwritingNoDuplicateHardPullsRunner"] = "underwritingNoDuplicateHardPullsRunner";
    // account opening
    KnownRunners["accountCreationRunner"] = "accountCreationRunner";
    KnownRunners["postNotarizationToAcceptedRunner"] = "postNotarizationToAcceptedRunner";
    KnownRunners["coreCardRevolvingCreditLimitRunner"] = "coreCardRevolvingCreditLimitRunner";
    KnownRunners["noInvalidRevolvingCreditLimitRunner"] = "noInvalidRevolvingCreditLimitRunner";
    // post account opening runners
    KnownRunners["rescissionPeriodRunner"] = "rescissionPeriodRunner";
    KnownRunners["creditLimitIncreaseAppliedSuccessfullyRunner"] = "creditLimitIncreaseAppliedSuccessfullyRunner";
    KnownRunners["creditLimitIncreaseNotYetAppliedRunner"] = "creditLimitIncreaseNotYetAppliedRunner";
    KnownRunners["aprReductionRunner"] = "aprReductionRunner";
    KnownRunners["accountActivationRequirementRunner"] = "accountActivationRequirementRunner";
    KnownRunners["accountActivationRequirementStatusRunner"] = "accountActivationRequirementStatusRunner";
    KnownRunners["accountActivationCashOutStatusRunner"] = "accountActivationCashOutStatusRunner";
    KnownRunners["accountActivationFeesRunner"] = "accountActivationFeesRunner";
    KnownRunners["avenCreditCardActivationRunner"] = "avenCreditCardActivationRunner";
    KnownRunners["validTransactionRunner"] = "validTransactionRunner";
    KnownRunners["avenCreditCardExpirationDateRunner"] = "avenCreditCardExpirationDateRunner";
    KnownRunners["avenCreditCardWrongExpirationDateRunner"] = "avenCreditCardWrongExpirationDateRunner";
    KnownRunners["recordedLiensRunner"] = "recordedLiensRunner";
    KnownRunners["recordedLiensThresholdRunner"] = "recordedLiensThresholdRunner";
    KnownRunners["creditBalanceRefundRunner"] = "creditBalanceRefundRunner";
    KnownRunners["creditBalanceRefundCorrectlyAppliedRunner"] = "creditBalanceRefundCorrectlyAppliedRunner";
    KnownRunners["creditBalanceRefundMissingRunner"] = "creditBalanceRefundMissingRunner";
    KnownRunners["payoffQuoteRunner"] = "payoffQuoteRunner";
    KnownRunners["accountFeatureContingencyTransactionRunner"] = "accountFeatureContingencyTransactionRunner";
    KnownRunners["accountFeatureContingencyAccountStatusRunner"] = "accountFeatureContingencyAccountStatusRunner";
    KnownRunners["accountFeatureContingencyStatusRunner"] = "accountFeatureContingencyStatusRunner";
    KnownRunners["accountFeatureContingencyCorrectlyAppliedRunner"] = "accountFeatureContingencyCorrectlyAppliedRunner";
    KnownRunners["accountFeatureContingencyMissingRunner"] = "accountFeatureContingencyMissingRunner";
    KnownRunners["accountFeatureContingencyCorrectlySucceededRunner"] = "accountFeatureContingencyCorrectlySucceededRunner";
    // post notarization runners
    KnownRunners["experianSubcodeRunner"] = "experianSubcodeRunner";
    KnownRunners["notarySessionRecordingPlaybackRunner"] = "notarySessionRecordingPlaybackRunner";
    KnownRunners["missingNotarySessionRecordingRunner"] = "missingNotarySessionRecordingRunner";
    KnownRunners["applicantNotaryAssignmentCompletenessRunner"] = "applicantNotaryAssignmentCompletenessRunner";
    KnownRunners["notarizationsCompleteRunner"] = "notarizationsCompleteRunner";
    KnownRunners["engNotaryAvailabilityRunner"] = "engNotaryAvailabilityRunner";
    // payment and fee runners
    KnownRunners["installmentLoanFixedFinanceChargeRunner"] = "installmentLoanFixedFinanceChargeRunner";
    KnownRunners["amortizationLoanPlanIdRunner"] = "amortizationLoanPlanIdRunner";
    KnownRunners["installmentLoanIncorrectTerminalStatusRunner"] = "installmentLoanIncorrectTerminalStatusRunner";
    KnownRunners["noWrongTwelveDayPaymentCommsRunner"] = "noWrongTwelveDayPaymentCommsRunner";
    KnownRunners["noWrongThreeDayPaymentCommsRunner"] = "noWrongThreeDayPaymentCommsRunner";
    KnownRunners["noWrongZeroDayPaymentCommsRunner"] = "noWrongZeroDayPaymentCommsRunner";
    KnownRunners["noMissedThreeDayPaymentCommsRunner"] = "noMissedThreeDayPaymentCommsRunner";
    KnownRunners["noMissedTwelveDayPaymentCommsRunner"] = "noMissedTwelveDayPaymentCommsRunner";
    KnownRunners["noMissedZeroDayPaymentCommsRunner"] = "noMissedZeroDayPaymentCommsRunner";
    KnownRunners["autopayRunnerAutoPayAmount"] = "autopayRunnerAutoPayAmount";
    KnownRunners["autopayRunnerAutoPayEnabledPaymentDueChecked"] = "autopayRunnerAutoPayEnabledPaymentDueChecked";
    KnownRunners["autopayNoDeletedBankAccount"] = "autopayNoDeletedBankAccount";
    KnownRunners["autoPayPaymentMadeForCorrectAmountRunner"] = "autoPayPaymentMadeForCorrectAmountRunner";
    KnownRunners["paymentVerificationRunner"] = "paymentVerificationRunner";
    KnownRunners["aciTransactionNoMissingPaymentsOrReversalRunner"] = "aciTransactionNoMissingPaymentsOrReversalRunner";
    KnownRunners["delinquencyMetricsSyncRunner"] = "delinquencyMetricsSyncRunner";
    KnownRunners["recordingChargeRunner"] = "recordingChargeRunner";
    KnownRunners["simpleInterestLoanConversionRunner"] = "simpleInterestLoanConversionRunner";
    KnownRunners["reageEligibilityRunner"] = "reageEligibilityRunner";
    KnownRunners["delinquencyNoticeRunner"] = "delinquencyNoticeRunner";
    KnownRunners["bankruptcyCommunicationRunner"] = "bankruptcyCommunicationRunner";
    KnownRunners["stalePaymentCourtesyRunner"] = "stalePaymentCourtesyRunner";
    KnownRunners["avenDebtValidationLetterRunner"] = "avenDebtValidationLetterRunner";
    KnownRunners["verifyDelinquencyStatusOnExperian"] = "verifyDelinquencyStatusOnExperian";
    KnownRunners["verifyDelinquencyStatusOnExperianThresholdRunner"] = "verifyDelinquencyStatusOnExperianThresholdRunner";
    KnownRunners["quickPromotionPaymentsRunner"] = "quickPromotionPaymentsRunner";
    // compliance runners
    KnownRunners["aanShouldBeSentButNotSentRunner"] = "aanShouldBeSentButNotSentRunner";
    KnownRunners["aanShouldNotBeSentButSentRunner"] = "aanShouldNotBeSentButSentRunner";
    KnownRunners["noiShouldBeSentButNotSentRunner"] = "noiShouldBeSentButNotSentRunner";
    KnownRunners["noiShouldNotBeSentButSentRunner"] = "noiShouldNotBeSentButSentRunner";
    KnownRunners["creditScoreDisclosureRunner"] = "creditScoreDisclosureRunner";
    KnownRunners["incompleteApplicationRunner"] = "incompleteApplicationRunner";
    KnownRunners["hmdaLarMissingApplicationsRunner"] = "hmdaLarMissingApplicationsRunner";
    KnownRunners["hmdaLarExtraApplicationsRunner"] = "hmdaLarExtraApplicationsRunner";
    KnownRunners["hmdaLarDataIntegrityRunner"] = "hmdaLarDataIntegrityRunner";
    KnownRunners["hoiRunner"] = "hoiRunner";
    KnownRunners["minimumSignerCountRunner"] = "minimumSignerCountRunner";
    KnownRunners["loanStatusMatchesCoreCard"] = "loanStatusMatchesCoreCard";
    KnownRunners["noEmptyAdverseScoreFactorsWithLowFicoRunner"] = "noEmptyAdverseScoreFactorsWithLowFicoRunner";
    // credit policy runners
    KnownRunners["creditPolicyCreditScore"] = "creditPolicyCreditScore";
    KnownRunners["creditPolicyLineSize"] = "creditPolicyLineSize";
    KnownRunners["creditPolicyDTI"] = "creditPolicyDTI";
    KnownRunners["creditPolicyCLTV"] = "creditPolicyCLTV";
    KnownRunners["creditPolicyProperty"] = "creditPolicyProperty";
    KnownRunners["creditPolicyAPR"] = "creditPolicyAPR";
    KnownRunners["creditPolicyHCM"] = "creditPolicyHCM";
    KnownRunners["creditPolicyDelinquencyModelScore"] = "creditPolicyDelinquencyModelScore";
    KnownRunners["creditPolicyDelinquencyModelAdverseAction"] = "creditPolicyDelinquencyModelAdverseAction";
    KnownRunners["creditUnderwritingSpecDelinquencyModelEnablement"] = "creditUnderwritingSpecDelinquencyModelEnablement";
    //
    // Underwriting spec based credit policy runners on data in factUnderwriting
    //
    // Runner that covers FICO/Vantage related elements in underwriting.
    KnownRunners["UwPolicyCreditScoreRunner"] = "UwPolicyCreditScoreRunner";
    // Runner that covers DTI related elements in underwriting.
    KnownRunners["UwPolicyDtiRunner"] = "UwPolicyDtiRunner";
    // Runner that covers CLTV related elements in underwriting.
    KnownRunners["UwPolicyCltvRunner"] = "UwPolicyCtlvRunner";
    // Runner that covers hardcuts.
    KnownRunners["UwPolicyHardcutsRunner"] = "UwPolicyHardcutsRunner";
    // Runner that covers APR floor/cap, and APR adders
    KnownRunners["UwPolicyAprRunner"] = "UwPolicyAprRunner";
    // Runner that covers line size floor/cap, and line size constraints
    KnownRunners["UwPolicyLineSizeRunner"] = "UwPolicyLineSizeRunner";
    // Runner that covers income and income verification in underwriting.
    KnownRunners["UwPolicyIncomeRunner"] = "UwPolicyIncomeRunner";
    // Runner that covers property in underwriting.
    KnownRunners["UwPolicyPropertyRunner"] = "UwPolicyPropertyRunner";
    // Runner that covers other elements in underwriting.
    KnownRunners["UwPolicyOthersRunner"] = "UwPolicyOthersRunner";
    // // Runner that covers human investigation checks.
    // UwPolicyHumanInvestigateRunner = 'UwPolicyHumanInvestigateRunner',
    // TODOs: After migrating naming matching from aven_backend to aven_python, followings are doable.
    // Runner that covers the matching between property and applicants
    // UwPolicyPropertyApplicantMatchingRunner = 'UwPolicyPropertyApplicantMatchingRunner',
    // Income verification runners
    KnownRunners["IncomeVerificationAllMethodsActiveRunner"] = "IncomeVerificationAllMethodsActiveRunner";
    KnownRunners["IncomeVerificationNoStuckApplicantsThresholdRunner"] = "IncomeVerificationNoStuckApplicantsThresholdRunner";
    // Compliance runners
    KnownRunners["unsupportedStatesRunner"] = "unsupportedStatesRunner";
    KnownRunners["maxLineSizeRunner"] = "maxLineSizeRunner";
    KnownRunners["lifeOfLoanMonitoringRunner"] = "lifeOfLoanMonitoringRunner";
    KnownRunners["cipVerificationRunner"] = "cipVerificationRunner";
    KnownRunners["cashoutBankVerificationRunner"] = "cashoutBankVerificationRunner";
    KnownRunners["noOriginationFeeInStatesThatDisallowOriginationFeesRunner"] = "noOriginationFeeInStatesThatDisallowOriginationFeesRunner";
    KnownRunners["approvedLoanHasCompletedPersonaRunner"] = "approvedLoanHasCompletedPersonaRunner";
    KnownRunners["mloCommsWithinLicensedStatesRunner"] = "mloCommsWithinLicensedStatesRunner";
    KnownRunners["statementFeeCashOutRunner"] = "statementFeeCashOutRunner";
    // Flood runners
    KnownRunners["floodRunner"] = "floodRunner";
    KnownRunners["floodDisclosureAcknowledgementRunner"] = "floodDisclosureAcknowledgementRunner";
    KnownRunners["notaryFloodWithoutDocsRunner"] = "notaryFloodWithoutDocsRunner";
    KnownRunners["notaryFloodWithUnknownZoneRunner"] = "notaryFloodWithUnknownZoneRunner";
    KnownRunners["floodRenewal45DayReminderRunner"] = "floodRenewal45DayReminderRunner";
    KnownRunners["floodRenewal15DayReminderRunner"] = "floodRenewal15DayReminderRunner";
    KnownRunners["floodForcePlacementRunner"] = "floodForcePlacementRunner";
    KnownRunners["clFloodErrorRateAlert"] = "clFloodErrorRateAlert";
    KnownRunners["floodContingencyRunner"] = "floodContingencyRunner";
    // Insurance Contingency runners
    KnownRunners["titleInsuranceContingencyRunner"] = "titleInsuranceContingencyRunner";
    KnownRunners["finalTitlePolicyReceivedRunner"] = "finalTitlePolicyReceivedRunner";
    KnownRunners["finalTitlePolicyReceivedThresholdRunner"] = "finalTitlePolicyReceivedThresholdRunner";
    KnownRunners["hazardInsuranceContingencyRunner"] = "hazardInsuranceContingencyRunner";
    KnownRunners["blockApplicablePastDueAccountsRunner"] = "blockApplicablePastDueAccountsRunner";
    KnownRunners["employmentVerificationContingencyRunner"] = "employmentVerificationContingencyRunner";
    KnownRunners["mlsListingContingencyRunner"] = "mlsListingContingencyRunner";
    // Comms runners
    KnownRunners["commsAdcOverrideRunner"] = "commsAdcOverrideRunner";
    KnownRunners["commsPostNotarizationDocsRunner"] = "commsPostNotarizationDocsRunner";
    KnownRunners["commsWelcomeRunner"] = "commsWelcomeRunner";
    KnownRunners["commsPreQualDripRunner"] = "commsPreQualDripRunner";
    KnownRunners["commsBadDripRunner"] = "commsBadDripRunner";
    KnownRunners["commsVoicemailRunner"] = "commsVoicemailRunner";
    KnownRunners["commsOutboundEmailRunner"] = "commsOutboundEmailRunner";
    KnownRunners["commsDataIntegrityRunner"] = "commsDataIntegrityRunner";
    KnownRunners["commsIncomeVerificationDripRunner"] = "commsIncomeVerificationDripRunner";
    KnownRunners["commsOfferedDripRunner"] = "commsOfferedDripRunner";
    KnownRunners["statementCommsRunner"] = "statementCommsRunner";
    KnownRunners["statementDownloadRunner"] = "statementDownloadRunner";
    KnownRunners["uccStatementAddendumRunner"] = "uccStatementAddendumRunner";
    KnownRunners["facebookPixelRunner"] = "facebookPixelRunner";
    KnownRunners["facebookUtmRunner"] = "facebookUtmRunner";
    KnownRunners["googleTagManagerRunner"] = "googleTagManagerRunner";
    KnownRunners["googleUtmRunner"] = "googleUtmRunner";
    KnownRunners["creditCardCustomerSmsReachabilityRunner"] = "creditCardCustomerSmsReachabilityRunner";
    KnownRunners["applicantSmsReachabilityRunner"] = "applicantSmsReachabilityRunner";
    KnownRunners["delinquencyCommsRunner"] = "delinquencyCommsRunner";
    KnownRunners["manualDelinquencyCommsThresholdRunner"] = "manualDelinquencyCommsThresholdRunner";
    KnownRunners["twilioDeliverabilityRunner"] = "twilioDeliverabilityRunner";
    KnownRunners["amazonSnsSmsDeliverabilityRunner"] = "amazonSnsSmsDeliverabilityRunner";
    KnownRunners["infobipSmsDeliverabilityRunner"] = "infobipSmsDeliverabilityRunner";
    KnownRunners["twilioPreventableErrorsRunner"] = "twilioPreventableErrorsRunner";
    KnownRunners["amazonSnsSmsPreventableErrorsRunner"] = "amazonSnsSmsPreventableErrorsRunner";
    KnownRunners["twilioBlockedPhoneNumbersRunner"] = "twilioBlockedPhoneNumbersRunner";
    KnownRunners["amazonSnsBlockedPhoneNumbersRunner"] = "amazonSnsBlockedPhoneNumbersRunner";
    KnownRunners["noSmsOnceOptedOutRunner"] = "noSmsOnceOptedOutRunner";
    KnownRunners["noEmailOnceOptedOutRunner"] = "noEmailOnceOptedOutRunner";
    KnownRunners["noRvmOnceOptedOutRunner"] = "noRvmOnceOptedOutRunner";
    KnownRunners["noCallOnceOptedOutRunner"] = "noCallOnceOptedOutRunner";
    KnownRunners["allSmsOptOutInstructionsRunner"] = "allSmsOptOutInstructionsRunner";
    KnownRunners["marketingSmsOptOutInstructionsRunner"] = "marketingSmsOptOutInstructionsRunner";
    KnownRunners["stopCommsOnRequestRunner"] = "stopCommsOnRequestRunner";
    KnownRunners["zendeskManualOutboundSmsRunner"] = "zendeskManualOutboundSmsRunner";
    KnownRunners["zendeskManualInboundSmsRunner"] = "zendeskManualInboundSmsRunner";
    KnownRunners["zendeskManualOutboundEmailThresholdRunner"] = "zendeskManualOutboundEmailThresholdRunner";
    KnownRunners["zendeskManualInboundEmailThresholdRunner"] = "zendeskManualInboundEmailThresholdRunner";
    KnownRunners["zendeskManualInboundChatThresholdRunner"] = "zendeskManualInboundChatThresholdRunner";
    KnownRunners["zendeskManualInternalNoteThresholdRunner"] = "zendeskManualInternalNoteThresholdRunner";
    KnownRunners["zendeskCommentErrorDaemonThresholdRunner"] = "zendeskCommentErrorDaemonThresholdRunner";
    KnownRunners["zendeskAutomatedOutboundSmsBackfillThresholdRunner"] = "zendeskAutomatedOutboundSmsBackfillThresholdRunner";
    KnownRunners["automatedOutboundCommunicationCreatesZendeskCommentRunner"] = "automatedOutboundCommunicationCreatesZendeskCommentRunner";
    KnownRunners["requestedCallbackIsEnqueuedRunner"] = "requestedCallbackIsEnqueuedRunner";
    KnownRunners["staleCommunicationRunner"] = "staleCommunicationRunner";
    KnownRunners["commsSentWithinLegalHoursRunner"] = "commsSentWithinLegalHoursRunner";
    KnownRunners["commsMissingChunksRunner"] = "commsMissingChunksRunner";
    // Mobile app runners
    KnownRunners["pushNotificationRegistrationRunner"] = "pushNotificationRegistrationRunner";
    KnownRunners["pushNotificationPublishRunner"] = "pushNotificationPublishRunner";
    KnownRunners["avenMyLoginRunner"] = "avenMyLoginRunner";
    // Account remediation runners
    KnownRunners["simpleInterestPlanRemediationRunner"] = "simpleInterestPlanRemediationRunner";
    // Data integrity runners
    KnownRunners["avenStatementRunner"] = "avenStatementRunner";
    KnownRunners["nextStatementDateRunner"] = "nextStatementDateRunner";
    KnownRunners["avenBalanceTransferAndCashOutRunner"] = "avenBalanceTransferAndCashOutRunner";
    KnownRunners["avenInstantBalanceTransferRunner"] = "avenInstantBalanceTransferRunner";
    KnownRunners["avenBalanceTransferAccountStatusRunner"] = "avenBalanceTransferAccountStatusRunner";
    KnownRunners["activeAccountClosureRequestRunner"] = "activeAccountClosureRequestRunner";
    KnownRunners["inactiveAccountClosureRequestRunner"] = "inactiveAccountClosureRequestRunner";
    KnownRunners["backdatedPaymentRevertsFfcRunner"] = "backdatedPaymentRevertsFfcRunner";
    KnownRunners["dqAccountChargeOffRunner"] = "dqAccountChargeOffRunner";
    KnownRunners["dqAccountShouldBeChargedOffButIsntThresholdRunner"] = "dqAccountShouldBeChargedOffButIsntThresholdRunner";
    KnownRunners["dqMetricsRecords"] = "dqMetricsRecords";
    KnownRunners["dqUnifiedDebtProductEntityRecords"] = "dqUnifiedDebtProductEntityRecords";
    KnownRunners["closedAccountBalanceRunner"] = "closedAccountBalanceRunner";
    KnownRunners["avenRewardsTransactionsMatchDimTransactionsAndRewardsRunner"] = "avenRewardsTransactionsMatchDimTransactionsAndRewardsRunner";
    KnownRunners["avenRewardsRedemptionsSucceedRunner"] = "avenRewardsRedemptionsSucceedRunner";
    KnownRunners["portfolioAllocationDataIntegrityRunner"] = "portfolioAllocationDataIntegrityRunner";
    KnownRunners["noMissingFixedPlanRunner"] = "noMissingFixedPlanRunner";
    KnownRunners["noWrongFixedPlanCoreCardPlanIdRunner"] = "noWrongFixedPlanCoreCardPlanIdRunner";
    KnownRunners["noDuplicateFixedPlanCoreCardPlanIdRunner"] = "noDuplicateFixedPlanCoreCardPlanIdRunner";
    KnownRunners["cardHolderTransactionsForUnActivatedCards"] = "cardHolderTransactionsForUnActivatedCards";
    KnownRunners["missingRewardsTransactionsRunner"] = "missingRewardsTransactionsRunner";
    KnownRunners["aciMamFileBankAccountRegistrationRunner"] = "aciMamFileBankAccountRegistrationRunner";
    KnownRunners["noLateOrMissingAciBankAccountMamFilesRunner"] = "noLateOrMissingAciBankAccountMamFilesRunner";
    // Legal doc runners
    KnownRunners["accountAgreementDocRunner"] = "accountAgreementDocRunner";
    KnownRunners["adverseActionNoticeContentRunner"] = "adverseActionNoticeContentRunner";
    KnownRunners["propertyValuationDocumentContentRunner"] = "propertyValuationDocumentContentRunner";
    KnownRunners["appraisalWaiverDocumentContentRunner"] = "appraisalWaiverDocumentContentRunner";
    KnownRunners["deedOfTrustContentRunner"] = "deedOfTrustContentRunner";
    KnownRunners["earlyHELOCDisclosureContentRunner"] = "earlyHELOCDisclosureContentRunner";
    // data feed runner
    KnownRunners["coreCardDataFeedDelayedRunner"] = "coreCardDataFeedDelayedRunner";
    KnownRunners["aciTransactionNoMissingBatchFilesRunner"] = "aciTransactionNoMissingBatchFilesRunner";
    // Capital markets runners
    KnownRunners["servicingDataTapeMissingStatementsRunner"] = "servicingDataTapeMissingStatementsRunner";
    KnownRunners["servicingDataTapeExtraStatementsRunner"] = "servicingDataTapeExtraStatementsRunner";
    KnownRunners["summaryDataTapeMissingAccountsRunner"] = "summaryDataTapeMissingAccountsRunner";
    KnownRunners["summaryDataTapeExtraAccountsRunner"] = "summaryDataTapeExtraAccountsRunner";
    KnownRunners["summaryDataTapeDuplicateAccountsRunner"] = "summaryDataTapeDuplicateAccountsRunner";
    KnownRunners["summaryDataTapeStaleAccountsRunner"] = "summaryDataTapeStaleAccountsRunner";
    KnownRunners["summaryDataTapeDataIntegrityRunner"] = "summaryDataTapeDataIntegrityRunner";
    // Blackstone forward flow runners
    KnownRunners["blackstoneForwardFlowScraRunner"] = "blackstoneForwardFlowScraRunner";
    KnownRunners["blackstoneForwardFlowLoanModificationsRunner"] = "blackstoneForwardFlowLoanModificationsRunner";
    KnownRunners["blackstoneForwardFlowDefaultsRunner"] = "blackstoneForwardFlowDefaultsRunner";
    KnownRunners["blackstoneForwardFlowUnderwritingRunner"] = "blackstoneForwardFlowUnderwritingRunner";
    KnownRunners["blackstoneForwardFlowStaleAvmRunner"] = "blackstoneForwardFlowStaleAvmRunner";
    KnownRunners["blackstoneForwardFlowAccountAgreementRunner"] = "blackstoneForwardFlowAccountAgreementRunner";
    KnownRunners["blackstoneForwardFlowDeedOfTrustRunner"] = "blackstoneForwardFlowDeedOfTrustRunner";
    KnownRunners["blackstoneForwardFlowHomeOwnerInsuranceRunner"] = "blackstoneForwardFlowHomeOwnerInsuranceRunner";
    KnownRunners["blackstoneForwardFlowRecordedLienRunner"] = "blackstoneForwardFlowRecordedLienRunner";
    KnownRunners["blackstoneForwardFlowInvoluntaryLienRunner"] = "blackstoneForwardFlowInvoluntaryLienRunner";
    KnownRunners["blackstoneForwardFlowOverlimitRunner"] = "blackstoneForwardFlowOverlimitRunner";
    KnownRunners["blackstoneForwardFlowFloodInsuranceRunner"] = "blackstoneForwardFlowFloodInsuranceRunner";
    KnownRunners["blackstoneForwardFlowResidenceTypeRunner"] = "blackstoneForwardFlowResidenceTypeRunner";
    KnownRunners["blackstoneForwardFlowPropertyTypeRunner"] = "blackstoneForwardFlowPropertyTypeRunner";
    // max APR runners
    KnownRunners["fixedTermLoanAprHcmLimitCheckRunner"] = "fixedTermLoanAprHcmLimitCheckRunner";
    KnownRunners["aprExceedGlobalLegalLimitRunner"] = "aprExceedGlobalLegalLimitRunner";
    // fee runner (balance transfer, cash out, origination)
    KnownRunners["feeCheckBetweenOfferTermsAndFinalUwSpecRunner"] = "feeCheckBetweenOfferTermsAndFinalUwSpecRunner";
    // UI runners
    KnownRunners["originationLandingPageRunner"] = "landingPageRunner";
    KnownRunners["originationPersonalInfoPageRunner"] = "personalInfoPageRunner";
    KnownRunners["originationFlowFootnotesRunner"] = "originationFlowFootnotesRunner";
    KnownRunners["avenMyAccountDetailsFootnotesRunner"] = "avenMyAccountDetailsFootnotesRunner";
    // Loan application runners
    KnownRunners["finalUnderwritingSpecIdRunner"] = "finalUnderwritingSpecIdRunner";
    // Marketing Emails runners
    KnownRunners["marketingEmailUnsubscribeRunner"] = "marketingEmailUnsubscribeRunner";
    // Logs runners
    KnownRunners["cloudwatchAllNewSessionsRunner"] = "cloudwatchAllNewSessionsRunner";
    KnownRunners["illinoisAntiPredatoryContingencyRunner"] = "illinoisAntiPredatoryContingencyRunner";
    // HELOC refi runners
    KnownRunners["lienAttestationRunner"] = "lienAttestationRunner";
    KnownRunners["helocRefiAccountStatusRunner"] = "helocRefiAccountStatusRunner";
    KnownRunners["helocRefiWirePaymentRunner"] = "helocRefiWirePaymentRunner";
    KnownRunners["helocRefiUniquenessRunner"] = "helocRefiUniquenessRunner";
    KnownRunners["helocRefiLineSizeRunner"] = "helocRefiLineSizeRunner";
    KnownRunners["helocRefiWireAmountRunner"] = "helocRefiWireAmountRunner";
    // Bucket runners
    KnownRunners["noDelayedAvenPaymentAllocationRequestRunner"] = "noDelayedAvenPaymentAllocationRequestRunner";
    KnownRunners["noDelayedAvenPaymentAllocationPortionRunner"] = "noDelayedAvenPaymentAllocationPortionRunner";
    KnownRunners["avenPaymentAllocationRequestRequestedRunner"] = "avenPaymentAllocationRequestRequestedRunner";
    KnownRunners["avenPaymentAllocationRequestFulfilledRunner"] = "avenPaymentAllocationRequestFulfilledRunner";
    KnownRunners["avenPaymentAllocationRequestBailRequestedRunner"] = "avenPaymentAllocationRequestBailRequestedRunner";
    KnownRunners["avenPaymentAllocationRequestBailingRunner"] = "avenPaymentAllocationRequestBailingRunner";
    KnownRunners["avenPaymentAllocationRequestBailedRunner"] = "avenPaymentAllocationRequestBailedRunner";
    KnownRunners["avenPaymentAllocationPortionTranCodeRunner"] = "avenPaymentAllocationPortionTranCodeRunner";
    KnownRunners["noIncorrectAllocationFromLeapfrogsRunner"] = "noIncorrectAllocationFromLeapfrogsRunner";
    KnownRunners["npsGiftCardRunner"] = "npsGiftCardRunner";
    // irs runners
    KnownRunners["irsConsentRunner"] = "irsConsentRunner";
    // Churn retention runners
    KnownRunners["churnRetentionTreatmentAppliedSuccessfullyRunner"] = "churnRetentionTreatmentAppliedSuccessfullyRunner";
    KnownRunners["humanInvestigateStatusLoanAppCountPerDayThresholdRunner"] = "humanInvestigateStatusLoanAppCountPerDayThresholdRunner";
    KnownRunners["instantUnlockSuccessRunner"] = "instantUnlockSuccessRunner";
    KnownRunners["unitEconomicsTableStaleRunner"] = "unitEconomicsTableStaleRunner";
    // UCC Runners
    KnownRunners["uccValidStatusRunner"] = "uccValidStatusRunner";
    // Pay it forward runners
    KnownRunners["payItForwardPaymentsRunner"] = "payItForwardPaymentsRunner";
    // Advisor runners
    KnownRunners["advisorTermsOfServiceRunner"] = "advisorTermsOfServiceRunner";
    KnownRunners["advisorExperianSeparationRunner"] = "advisorExperianSeparationRunner";
})(KnownRunners = exports.KnownRunners || (exports.KnownRunners = {}));
