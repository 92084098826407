"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenBoostRewardsName = void 0;
// If we add a new 'allMerchant' boost type we should also update getDisplayCashBackRatioFromOfferAndUwMetadata in aven_shared
/* @TranslateToPython @TranslateToEnum */
var AvenBoostRewardsName;
(function (AvenBoostRewardsName) {
    AvenBoostRewardsName["homedepotCashBack5pct"] = "homedepotCashBack5pct";
    AvenBoostRewardsName["homedepotCashBack5pctAutoPayEnabled"] = "homedepotCashBack5pctAutoPayEnabled";
    AvenBoostRewardsName["allMerchant3pctAutoPayEnabled"] = "allMerchant3pctAutoPayEnabled";
    AvenBoostRewardsName["allMerchant5pctAutoPayEnabled"] = "allMerchant5pctAutoPayEnabled";
    AvenBoostRewardsName["allMerchant5pct5DaysAutoPayEnabled"] = "allMerchant5pct5DaysAutoPayEnabled";
    AvenBoostRewardsName["allMerchant3pctAutoPayEnabledAnnualLimit"] = "allMerchant3pctAutoPayEnabledAnnualLimit";
    /** @deprecated Replaced by 7% rewards. Note 5% cash back never went live to non-employee cardholders. */
    AvenBoostRewardsName["ascendaHotelsCashBack5pct"] = "ascendaHotelsCashBack5pct";
    // All hotel purchases using the Aven card made through the Ascenda rewards portal each 7% cash back
    AvenBoostRewardsName["ascendaHotelsCashBack7pct"] = "ascendaHotelsCashBack7pct";
    AvenBoostRewardsName["ascendaFlightsCashBack5pct"] = "ascendaFlightsCashBack5pct";
    /** @deprecated Replaced 3 boosts (gasStations7pctMcc5541, gasStations7pctMcc5542, groceriesAndSupermarkets7pct) with one boost (gasAndGroceries7pct). Note the 3 boosts went live but a data update will convert all UW specs with them to gasAndGroceries7pct. */
    AvenBoostRewardsName["gasStations7pctMcc5541"] = "gasStations7pctMcc5541";
    /** @deprecated Replaced 3 boosts (gasStations7pctMcc5541, gasStations7pctMcc5542, groceriesAndSupermarkets7pct) with one boost (gasAndGroceries7pct). Note the 3 boosts went live but a data update will convert all UW specs with them to gasAndGroceries7pct. */
    AvenBoostRewardsName["gasStations7pctMcc5542"] = "gasStations7pctMcc5542";
    /** @deprecated Replaced 3 boosts (gasStations7pctMcc5541, gasStations7pctMcc5542, groceriesAndSupermarkets7pct) with one boost (gasAndGroceries7pct). Note the 3 boosts went live but a data update will convert all UW specs with them to gasAndGroceries7pct. */
    AvenBoostRewardsName["groceriesAndSupermarkets7pct"] = "groceriesAndSupermarkets7pct";
    AvenBoostRewardsName["gasAndGroceries7pct"] = "gasAndGroceries7pct";
    AvenBoostRewardsName["gasAndGroceries7pctWith10kLimit"] = "gasAndGroceries7pctWith10kLimit";
    AvenBoostRewardsName["restaurants7pct"] = "restaurants7pct";
    AvenBoostRewardsName["starbucksDunkin5pct"] = "starbucksDunkin5pct";
    /** 3% cashback at all merchants if AutoPay is on up to 100k annual limit */
    AvenBoostRewardsName["allMerchant3pctWith100kAnnualLimit"] = "allMerchant3pctWith100kAnnualLimit";
    AvenBoostRewardsName["_test"] = "test";
})(AvenBoostRewardsName = exports.AvenBoostRewardsName || (exports.AvenBoostRewardsName = {}));
