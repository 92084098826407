<template>
    <!--
        Any HTML+CSS that gets loaded into shadowHost will not leak out into the app -
        this is useful for loading in legal documents, which may have styles that
        break the app if they leak in.
    -->
    <div ref="shadowHost" />
</template>

<script>
    export default {
        props: {
            htmlContent: {
                type: String,
                required: true,
            },
        },
        mounted() {
            const shadowRoot = this.$refs.shadowHost.attachShadow({ mode: 'open' })
            shadowRoot.innerHTML = this.htmlContent

            // disable pointer events on all inputs within the shadow dom
            const inputs = shadowRoot.querySelectorAll('input')
            inputs.forEach((input) => {
                input.style.pointerEvents = 'none'
            })
        },
    }
</script>
