/**
 * Returns a capitalized version of the string ex. hello world ->  Hello World
 * @param string
 */
export const capitalizeString = (string: string): string => {
    const words = string.split(' ') // Split on spaces
    const capitalizedWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.substring(1).toLowerCase() // hello -> Hello; hELlO -> Hello
    })

    return capitalizedWords.join(' ') // Reconstructs the string
}

// snippet from mdn: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#examples
const getCircularReplacer = () => {
    const ancestors: unknown[] = []
    return function (key: string, value: any) {
        if (typeof value !== 'object' || value === null) {
            return value
        }
        // @ts-ignore `this` is the object that value is contained in, i.e., its direct parent.
        while (ancestors.length > 0 && ancestors.at(-1) !== this) {
            ancestors.pop()
        }
        if (ancestors.includes(value)) {
            return '[Circular]'
        }
        ancestors.push(value)
        return value
    }
}

export const safeJsonStringify = (obj: any): string => {
    return JSON.stringify(obj || null, getCircularReplacer())
}
