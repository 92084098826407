"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmploymentType = exports.MortgageUniformResidentialLoanApplicationStatus = exports.MortgageLender = exports.StatedRefinanceCashOutType = void 0;
var StatedRefinanceCashOutType;
(function (StatedRefinanceCashOutType) {
    StatedRefinanceCashOutType["RATE_AND_TERM_REFINANCE"] = "RATE_AND_TERM_REFINANCE";
    StatedRefinanceCashOutType["CASH_OUT_REFINANCE"] = "CASH_OUT_REFINANCE";
})(StatedRefinanceCashOutType = exports.StatedRefinanceCashOutType || (exports.StatedRefinanceCashOutType = {}));
/** @TranslateToPython @TranslateToEnum */
var MortgageLender;
(function (MortgageLender) {
    MortgageLender["rocketMoney"] = "rocketMoney";
    MortgageLender["carrington"] = "carrington";
})(MortgageLender = exports.MortgageLender || (exports.MortgageLender = {}));
var MortgageUniformResidentialLoanApplicationStatus;
(function (MortgageUniformResidentialLoanApplicationStatus) {
    MortgageUniformResidentialLoanApplicationStatus["addressHistory"] = "addressHistory";
    MortgageUniformResidentialLoanApplicationStatus["dependents"] = "dependents";
    MortgageUniformResidentialLoanApplicationStatus["subjectProperty"] = "subjectProperty";
    MortgageUniformResidentialLoanApplicationStatus["otherProperties"] = "otherProperties";
    MortgageUniformResidentialLoanApplicationStatus["otherAssets"] = "otherAssets";
    MortgageUniformResidentialLoanApplicationStatus["declarationQuestions"] = "declarationQuestions";
    MortgageUniformResidentialLoanApplicationStatus["hmda"] = "hmda";
    MortgageUniformResidentialLoanApplicationStatus["employmentHistory"] = "employmentHistory";
    MortgageUniformResidentialLoanApplicationStatus["otherIncome"] = "otherIncome";
    MortgageUniformResidentialLoanApplicationStatus["confirmation"] = "confirmation";
    MortgageUniformResidentialLoanApplicationStatus["submitted"] = "submitted";
})(MortgageUniformResidentialLoanApplicationStatus = exports.MortgageUniformResidentialLoanApplicationStatus || (exports.MortgageUniformResidentialLoanApplicationStatus = {}));
var EmploymentType;
(function (EmploymentType) {
    EmploymentType["employed"] = "employed";
    EmploymentType["selfEmployed"] = "selfEmployed";
    EmploymentType["retired"] = "retired";
    EmploymentType["unemployed"] = "unemployed";
    EmploymentType["other"] = "other";
})(EmploymentType = exports.EmploymentType || (exports.EmploymentType = {}));
