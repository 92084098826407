import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '@/localization/en'

Vue.use(VueI18n)

const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
}

const locale = 'en'
// if (navigator.userLanguage){
//   locale = navigator.userLanguage.includes("es") ? "es" : "en"
// } else if (navigator.language) {
//   locale = navigator.language.includes("es") ? "es" : "en";
// } else {
//   logger.info('Unable to determine user langauge from javascript, defaulting to "en"')
// }

const i18n = new VueI18n({
    locale,
    messages: TRANSLATIONS,
    fallbackLocale: 'en',
    warnHtmlInMessage: 'off',
})

export { i18n }
