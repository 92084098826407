"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConferenceParticipantStatus = exports.ParticipantRole = exports.ConferenceRoomStatus = void 0;
var ConferenceRoomStatus;
(function (ConferenceRoomStatus) {
    ConferenceRoomStatus["created"] = "created";
    ConferenceRoomStatus["available"] = "available";
    ConferenceRoomStatus["closed"] = "closed";
})(ConferenceRoomStatus = exports.ConferenceRoomStatus || (exports.ConferenceRoomStatus = {}));
var ParticipantRole;
(function (ParticipantRole) {
    ParticipantRole["host"] = "host";
    ParticipantRole["participantChatOnly"] = "participantChatOnly";
    /** Participant can only observe the host (i.e. watch and listen) */
    ParticipantRole["participantObserveOnly"] = "participantObserveOnly";
    /** Participant gets both audio & video permission to fully participate */
    ParticipantRole["participantAvPermission"] = "participantAvPermission";
    /** Participant gets audio permission to speak, but no one can see their video */
    ParticipantRole["participantAudioPermission"] = "participantAudioPermission";
})(ParticipantRole = exports.ParticipantRole || (exports.ParticipantRole = {}));
var ConferenceParticipantStatus;
(function (ConferenceParticipantStatus) {
    ConferenceParticipantStatus["waitingToJoin"] = "waitingToJoin";
    ConferenceParticipantStatus["neverJoined"] = "neverJoined";
    ConferenceParticipantStatus["inSession"] = "inSession";
    ConferenceParticipantStatus["leftSession"] = "leftSession";
})(ConferenceParticipantStatus = exports.ConferenceParticipantStatus || (exports.ConferenceParticipantStatus = {}));
