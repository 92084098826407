"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUSPICIOUS_PAYMENT_FUNDING_TYPES = exports.WIRE_TRANSFER_FUNDING_TYPES = exports.AciPaymentFundingType = exports.AciPaymentFrontendError = exports.PaymentErrorSource = void 0;
var PaymentErrorSource;
(function (PaymentErrorSource) {
    PaymentErrorSource["ACI"] = "ACI";
    PaymentErrorSource["CoreCard"] = "CoreCard";
    PaymentErrorSource["UserPaymentMoreThanBalance"] = "UserPaymentMoreThanBalance";
    PaymentErrorSource["TimingOfPayment"] = "TimingOfPayment";
})(PaymentErrorSource = exports.PaymentErrorSource || (exports.PaymentErrorSource = {}));
var AciPaymentFrontendError;
(function (AciPaymentFrontendError) {
    AciPaymentFrontendError["RETRY_DEBIT"] = "RETRY_DEBIT";
    AciPaymentFrontendError["SCHEDULE_CALL"] = "SCHEDULE_CALL";
    AciPaymentFrontendError["DUPLICATE_PAYMENT"] = "DUPLICATE_PAYMENT";
    AciPaymentFrontendError["RETRY_PAYMENT_SAME_PAYMENT_METHOD"] = "RETRY_PAYMENT_SAME_PAYMENT_METHOD";
    AciPaymentFrontendError["RETRY_PAYMENT_WITH_NEW_PAYMENT_SOURCE"] = "RETRY_PAYMENT_WITH_NEW_PAYMENT_SOURCE";
    AciPaymentFrontendError["DEBIT_CARD_DECLINED"] = "DEBIT_CARD_DECLINED";
    AciPaymentFrontendError["DEBIT_CARD_INVALID_CARD_NUMBER"] = "DEBIT_CARD_INVALID_CARD_NUMBER";
    AciPaymentFrontendError["DEBIT_CARD_NSF"] = "DEBIT_CARD_NSF";
    AciPaymentFrontendError["DEBIT_CARD_WITHDRAWAL_LIMIT"] = "DEBIT_CARD_WITHDRAWAL_LIMIT";
    AciPaymentFrontendError["DEBIT_CARD_INVALID_AMOUNT"] = "DEBIT_CARD_INVALID_AMOUNT";
})(AciPaymentFrontendError = exports.AciPaymentFrontendError || (exports.AciPaymentFrontendError = {}));
var AciPaymentFundingType;
(function (AciPaymentFundingType) {
    AciPaymentFundingType["ACH"] = "ACH";
    AciPaymentFundingType["DEBIT_CARD"] = "DC";
    // From real world testing, debit cards are showing up as 'CC' now. Support it now
    AciPaymentFundingType["CREDIT_CARD"] = "CC";
    AciPaymentFundingType["ELOCKBOX"] = "ELOCKBOX";
    AciPaymentFundingType["BALLOON_INTERNAL_PAYOFF"] = "BALLOON_INTERNAL_PAYOFF";
    AciPaymentFundingType["CRYPTO_LIQUIDATION"] = "CRYPTO_LIQUIDATION";
    AciPaymentFundingType["WIRE_TRANSFER"] = "WIRE_TRANSFER";
    AciPaymentFundingType["WIRE_TRANSFER_PAYOFF"] = "WIRE_TRANSFER_PAYOFF";
    AciPaymentFundingType["CHECK"] = "CHECK";
    AciPaymentFundingType["CHECK_PAYOFF"] = "CHECK_PAYOFF";
    AciPaymentFundingType["CERTIFIED_CHECK"] = "CERTIFIED_CHECK";
    AciPaymentFundingType["CERTIFIED_CHECK_PAYOFF"] = "CERTIFIED_CHECK_PAYOFF";
})(AciPaymentFundingType = exports.AciPaymentFundingType || (exports.AciPaymentFundingType = {}));
exports.WIRE_TRANSFER_FUNDING_TYPES = [AciPaymentFundingType.WIRE_TRANSFER, AciPaymentFundingType.WIRE_TRANSFER_PAYOFF];
// https://docs.google.com/document/d/15juRE7XKbh2T3MsocRbciCWlZ-eiaOwDSGidxe_5VHE/edit?tab=t.0
exports.SUSPICIOUS_PAYMENT_FUNDING_TYPES = Object.values(AciPaymentFundingType).filter((type) => !exports.WIRE_TRANSFER_FUNDING_TYPES.includes(type) && type !== AciPaymentFundingType.BALLOON_INTERNAL_PAYOFF);
