import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { appLocalStorage, appSessionStorage, localStorageKey } from '@/utils/storage'
import { inspect, logger } from '@/utils/logger'
import router from '@/routes/router'
import store from '@/store'
import { getAppInstallId } from '@/nativeWebInteraction/nativeWebInteraction'
import { logout, LogoutReason } from '@/utils/logoutHelper'
import OpenReplayTracker from '@openreplay/tracker'
import OpenReplayAxiosTracker from '@openreplay/tracker-axios'
import { appRoutePaths } from '@/routes/appRoutes'

const timeout = process.env.VUE_APP_NODE_ENV === 'production' ? 40000 : Number(appLocalStorage.getItem(localStorageKey.httpTimeout) ?? 40000) // Some browsers have 10 sec timeouts, simulate this in the dev env with potential override
console.log(`HTTP timeout set to ${timeout}`)
const config = {
    baseURL: process.env.VUE_APP_API_BASE_URL,
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
    timeout,
}

console.log(process.env.VUE_APP_API_BASE_URL)

const axiosInstance = axios.create(config)
const loggingAxiosInstance = axios.create(config)
const analyticsAxiosInstance = axios.create(config)

const authInterceptor = (request: any) => {
    /** add auth token */
    let accessToken
    // prefer to use persisted store for jwtTokens
    const jwtTokenJson = store.state.persistedStore.jwtTokens
    try {
        if (jwtTokenJson && typeof jwtTokenJson === 'string') {
            accessToken = JSON.parse(jwtTokenJson).accessJWT
        } else if (jwtTokenJson && jwtTokenJson.accessJWT) {
            accessToken = jwtTokenJson.accessJWT
        }
    } catch (error) {
        console.error(`authInterceptor request: ${request.url} jwt json: ${jwtTokenJson}`, error)
    }

    if (accessToken) {
        request.headers.Authorization = `Bearer ${accessToken}`
    }
    // Custom header for SessionJWT Authentication strategy
    const sessionAccessToken = store.state.persistedStore.sessionAccessJWT || appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
    if (sessionAccessToken) {
        request.headers.SessionAuthorization = `Bearer ${sessionAccessToken}`
    }

    return request
}

/** Adding the request interceptors */
axiosInstance.interceptors.request.use(authInterceptor)
loggingAxiosInstance.interceptors.request.use(authInterceptor)
analyticsAxiosInstance.interceptors.request.use(authInterceptor)

axiosInstance.interceptors.response.use(
    // Just pass through the response; we only need special handling for errors
    (response) => response,
    (error) => {
        // 401 errors indicate that the token the app is using is no longer valid (e.g. it might be expired).
        // When we encounter that type of error from the API, we'll consider the user logged out.
        if (error.response?.status === 401) {
            logger.log(`Aven Advisor received a 401 response from the server. This means the access token is invalid, so we're logging the user out if they are logged in`)
            if (Object.values(appRoutePaths).includes(router.currentRoute.path)) {
                logger.info(`Aven Advisor is logging out the user because they are logged in and received a 401 response from the server`)
                logout(LogoutReason.response401, false, error.response.data.message)
            } else {
                logger.info(`Aven Advisor is not logging out the user because they are not logged in`)
            }
        }

        logger.log(`Aven Advisor received a non-401 error response from the server. Passing through promise chain (Error: ${error.message})`)
        return Promise.reject(error)
    }
)

const windowSizeInMsec = 1000 * 10
let windowStartTime = new Date()
let erroredCallsDuringWindow = 0
let totalCallsDuringWindow = 0

const printAndResetNetworkStats = () => {
    if (totalCallsDuringWindow > 0) {
        // .getTime() returns msec from epoch
        if (new Date().getTime() - windowStartTime.getTime() > windowSizeInMsec) {
            logger.log(`Network stats over last ${windowSizeInMsec} msec: Attempted network calls: ${totalCallsDuringWindow} / Errored network calls: ${erroredCallsDuringWindow}`)
            totalCallsDuringWindow = 0
            erroredCallsDuringWindow = 0
            windowStartTime = new Date()
        }
    }
}
setInterval(printAndResetNetworkStats, windowSizeInMsec)

const HttpClient = class {
    private readonly axiosInstance: AxiosInstance
    constructor(axiosInstance: AxiosInstance) {
        this.axiosInstance = axiosInstance
    }
    get = async (path: string, config?: any): Promise<AxiosResponse> => {
        return await this._send(false, path, null, config)
    }

    post = async (path: string, data?: any, config?: any): Promise<AxiosResponse> => {
        return await this._send(true, path, data, config)
    }

    _send = async (isPost: boolean, path: string, data?: any, config?: any): Promise<AxiosResponse> => {
        try {
            totalCallsDuringWindow++
            if (isPost) {
                return await this.axiosInstance.post(path, data, config)
            } else {
                return await this.axiosInstance.get(path, config)
            }
        } catch (e) {
            // HTTP 423 === 'Locked', we use this as an expected status for waiting on things to complete
            // Don't count that against out errored calls count
            // @ts-ignore - TODO: better typing
            if (e?.response?.status !== 423) {
                erroredCallsDuringWindow++
            }
            throw e
        }
    }

    initializeOpenReplayRequestTracker = (openReplayTracker: OpenReplayTracker) => {
        try {
            logger.info(`Adding request watcher for openreplay`)
            openReplayTracker.use(OpenReplayAxiosTracker({ instance: this.axiosInstance, ignoreHeaders: ['sessionauthorization', 'authorization'] }))
        } catch (error) {
            logger.error(`Could not initialize openReplay axios tracker`, error)
        }
    }
}

const httpClient = new HttpClient(axiosInstance)
const loggingHttpClient = new HttpClient(loggingAxiosInstance) // no special intercepts for logging axios instance
const analyticsHttpClient = new HttpClient(analyticsAxiosInstance) // no special intercepts for analytics axios instance

const RETRY_INTERVAL_MSEC = 6000

const runWithRetryLogic = async <T>(requestFunc: () => Promise<T>, maxRetryCount: number, customRetryInterval?: number, errHandler?: (exception: any) => {}): Promise<T> =>
    new Promise((resolve, reject) => {
        const retryInterval = customRetryInterval || RETRY_INTERVAL_MSEC

        let attemptNumber = 0
        const retryAndLog = async () => {
            try {
                const ret = await requestFunc()
                return resolve(ret)
            } catch (error: any) {
                // TODO: better error typings
                const allowRetry = error.response?.status === undefined || error.response?.status === 0 || error.code === 'ECONNABORTED' || error.code === 'ECONNRESET'
                if (!allowRetry) {
                    return reject(error)
                }

                logger.log(`Retry attempt ${attemptNumber}/${maxRetryCount} failed due to error: ${inspect(error)}`)
                attemptNumber++
                if (attemptNumber > maxRetryCount) {
                    return reject(new Error(`Max retry limit of ${maxRetryCount} exceeded with error: ${inspect(error)}`))
                }
                logger.log(`Next retry attempt in ${retryInterval} ms`)

                try {
                    if (errHandler) {
                        logger.log(`Calling error handler`)
                        await errHandler(error)
                    }
                } catch (e) {
                    logger.fatal(`Error handler failed with exception: ${e}`)
                }

                setTimeout(retryAndLog, retryInterval)
            }
        }
        retryAndLog()
    })

export { httpClient, loggingHttpClient, analyticsHttpClient, runWithRetryLogic }

export const logEvent = function (eventName: string, properties?: object) {
    const appInstallId = getAppInstallId()
    const body = {
        eventName,
        properties: { ...properties, appInstallId, currentPath: window.location.pathname, previousPath: window.previousPath, codeHash: process.env.VUE_APP_RELEASE_HASH },
    }

    runWithRetryLogic(async () => await analyticsHttpClient.post('/avenAdvisor/analyticsEvent', body), 2).catch((e) => {
        logger.fatal(`analytics event failed! Note that this does not impact the users experience. Error was ${e}`)
    })
}

window.logEvent = logEvent
