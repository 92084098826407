"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HmdaMaritalStatus = exports.HmdaDenialReason = exports.HMDACreditNameAndVersion = exports.HmdaCreditScore = exports.HmdaHoepaStatus = exports.HmdaVisualBasis = exports.HmdaSex = exports.HmdaRace = exports.HmdaEthnicity = exports.HmdaOccupancyType = exports.HmdaLoanPurpose = exports.HmdaAction = void 0;
/** @TranslateToPython @TranslateToEnum */
var HmdaAction;
(function (HmdaAction) {
    HmdaAction["loanOriginated"] = "1";
    HmdaAction["applicationApprovedButNotAccepted"] = "2";
    HmdaAction["applicationDenied"] = "3";
    HmdaAction["applicationWithdrawnByApplicant"] = "4";
    HmdaAction["closedForIncompleteness"] = "5";
    HmdaAction["purchasedLoan"] = "6";
    HmdaAction["preApprovalRequestDenied"] = "7";
    HmdaAction["preApprovalRequestApprovedButNotAccepted"] = "8";
})(HmdaAction = exports.HmdaAction || (exports.HmdaAction = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaLoanPurpose;
(function (HmdaLoanPurpose) {
    HmdaLoanPurpose["homePurchase"] = "1";
    HmdaLoanPurpose["homeImprovement"] = "2";
    HmdaLoanPurpose["refinancing"] = "31";
    HmdaLoanPurpose["cashOutRefinancing"] = "32";
    HmdaLoanPurpose["other"] = "4";
    HmdaLoanPurpose["notApplicable"] = "5";
})(HmdaLoanPurpose = exports.HmdaLoanPurpose || (exports.HmdaLoanPurpose = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaOccupancyType;
(function (HmdaOccupancyType) {
    HmdaOccupancyType["principalResidence"] = "1";
    HmdaOccupancyType["secondResidence"] = "2";
    HmdaOccupancyType["investmentProperty"] = "3";
})(HmdaOccupancyType = exports.HmdaOccupancyType || (exports.HmdaOccupancyType = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaEthnicity;
(function (HmdaEthnicity) {
    HmdaEthnicity["hispanic"] = "1";
    HmdaEthnicity["mexican"] = "11";
    HmdaEthnicity["puertoRican"] = "12";
    HmdaEthnicity["cuban"] = "13";
    HmdaEthnicity["hispanicOther"] = "14";
    HmdaEthnicity["notHispanic"] = "2";
    HmdaEthnicity["notProvided"] = "3";
    HmdaEthnicity["notApplicable"] = "4";
    HmdaEthnicity["noCoApplicant"] = "5";
})(HmdaEthnicity = exports.HmdaEthnicity || (exports.HmdaEthnicity = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaRace;
(function (HmdaRace) {
    HmdaRace["americanIndian"] = "1";
    HmdaRace["asian"] = "2";
    HmdaRace["asianIndian"] = "21";
    HmdaRace["chinese"] = "22";
    HmdaRace["filipino"] = "23";
    HmdaRace["japanese"] = "24";
    HmdaRace["korean"] = "25";
    HmdaRace["vietnamese"] = "26";
    HmdaRace["asianOther"] = "27";
    HmdaRace["black"] = "3";
    HmdaRace["hawaiian"] = "4";
    HmdaRace["nativeHawaiian"] = "41";
    HmdaRace["guamanian"] = "42";
    HmdaRace["samoan"] = "43";
    HmdaRace["hawaiianOther"] = "44";
    HmdaRace["white"] = "5";
    HmdaRace["notProvided"] = "6";
    HmdaRace["notApplicable"] = "7";
    HmdaRace["noCoApplicant"] = "8";
})(HmdaRace = exports.HmdaRace || (exports.HmdaRace = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaSex;
(function (HmdaSex) {
    HmdaSex["male"] = "1";
    HmdaSex["female"] = "2";
    HmdaSex["notProvided"] = "3";
    HmdaSex["notApplicable"] = "4";
    HmdaSex["noCoApplicant"] = "5";
    HmdaSex["bothMaleAndFemale"] = "6";
})(HmdaSex = exports.HmdaSex || (exports.HmdaSex = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaVisualBasis;
(function (HmdaVisualBasis) {
    HmdaVisualBasis["collectedOnBasisOfVisualObservationOrSurname"] = "1";
    HmdaVisualBasis["notCollectedOnBasisOfVisualObservationOrSurname"] = "2";
    HmdaVisualBasis["notApplicable"] = "3";
    HmdaVisualBasis["noCoApplicant"] = "4";
})(HmdaVisualBasis = exports.HmdaVisualBasis || (exports.HmdaVisualBasis = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaHoepaStatus;
(function (HmdaHoepaStatus) {
    HmdaHoepaStatus["highCostMortgage"] = "1";
    HmdaHoepaStatus["notHighCostMortgage"] = "2";
    HmdaHoepaStatus["notApplicable"] = "3";
})(HmdaHoepaStatus = exports.HmdaHoepaStatus || (exports.HmdaHoepaStatus = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaCreditScore;
(function (HmdaCreditScore) {
    HmdaCreditScore["exempt"] = "1111";
    HmdaCreditScore["notANumber"] = "7777";
    HmdaCreditScore["notApplicable"] = "8888";
    HmdaCreditScore["noCoApplicant"] = "9999";
})(HmdaCreditScore = exports.HmdaCreditScore || (exports.HmdaCreditScore = {}));
/** @TranslateToPython @TranslateToEnum */
var HMDACreditNameAndVersion;
(function (HMDACreditNameAndVersion) {
    HMDACreditNameAndVersion["equifaxFICOv5"] = "1";
    HMDACreditNameAndVersion["experianFICOv2"] = "2";
    HMDACreditNameAndVersion["transUnionFICOv4"] = "3";
    HMDACreditNameAndVersion["transUnionFICOv98"] = "4";
    HMDACreditNameAndVersion["vantageScore2"] = "5";
    HMDACreditNameAndVersion["vantageScore3"] = "6";
    HMDACreditNameAndVersion["moreThanOne"] = "7";
    HMDACreditNameAndVersion["other"] = "8";
    HMDACreditNameAndVersion["notApplicable"] = "9";
    HMDACreditNameAndVersion["noCoApplicant"] = "10";
    HMDACreditNameAndVersion["ficoScore9"] = "11";
    HMDACreditNameAndVersion["ficoScore8"] = "12";
    HMDACreditNameAndVersion["ficoScore10"] = "13";
    HMDACreditNameAndVersion["ficoScore10T"] = "14";
    HMDACreditNameAndVersion["vantageScore4"] = "15";
    HMDACreditNameAndVersion["exempt"] = "1111";
})(HMDACreditNameAndVersion = exports.HMDACreditNameAndVersion || (exports.HMDACreditNameAndVersion = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaDenialReason;
(function (HmdaDenialReason) {
    HmdaDenialReason["debtToIncomeRatio"] = "1";
    HmdaDenialReason["employmentHistory"] = "2";
    HmdaDenialReason["creditHistory"] = "3";
    HmdaDenialReason["collateral"] = "4";
    HmdaDenialReason["insufficientCash"] = "5";
    HmdaDenialReason["unverifiableInformation"] = "6";
    HmdaDenialReason["incompleteApplication"] = "7";
    HmdaDenialReason["mortgageInsuranceDenied"] = "8";
    HmdaDenialReason["other"] = "9";
    HmdaDenialReason["notApplicable"] = "10";
})(HmdaDenialReason = exports.HmdaDenialReason || (exports.HmdaDenialReason = {}));
/** @TranslateToPython @TranslateToEnum */
var HmdaMaritalStatus;
(function (HmdaMaritalStatus) {
    HmdaMaritalStatus["married"] = "1";
    HmdaMaritalStatus["unmarried"] = "2";
    HmdaMaritalStatus["joint"] = "3";
    HmdaMaritalStatus["blank"] = "";
})(HmdaMaritalStatus = exports.HmdaMaritalStatus || (exports.HmdaMaritalStatus = {}));
