"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ServiceLinkNotarizationStatus = void 0;
var ServiceLinkNotarizationStatus;
(function (ServiceLinkNotarizationStatus) {
    ServiceLinkNotarizationStatus["CREATED"] = "CREATED";
    ServiceLinkNotarizationStatus["CONFIRMED"] = "CONFIRMED";
    ServiceLinkNotarizationStatus["SCHEDULE_REQUESTED"] = "SCHEDULE_REQUESTED";
    ServiceLinkNotarizationStatus["SCHEDULE_CONFIRMED"] = "SCHEDULE_CONFIRMED";
    ServiceLinkNotarizationStatus["CANCELLED"] = "CANCELLED";
    ServiceLinkNotarizationStatus["COMPLETED"] = "COMPLETED";
})(ServiceLinkNotarizationStatus = exports.ServiceLinkNotarizationStatus || (exports.ServiceLinkNotarizationStatus = {}));
