<template>
    <!--
        The purpose of this component is to create a simple mechanism for acknowledging docs. In addition
        you can drop any footnotes/disclosures into the additionalAcknowledgements slot, and they will get
        logged as metadata on the document_ack which is useful for internal tracking.
    -->
    <div class="w-full">
        <div
            v-if="props.additionalAcknowledgementsPosition === 'above'"
            class="mb-2"
        >
            <slot name="additionalAcknowledgements" />
        </div>

        <button
            @click="handleClick"
            :class="props.buttonClasses"
            :disabled="props.disabled"
            :data-testid="props.dataTestid"
        >
            <span
                v-if="props.submitting || ackSubmitting"
                class="spinner-border spinner-border-sm"
            />
            <slot
                name="cta"
                v-else
            />
        </button>

        <div
            ref="additionalAckRef"
            v-if="props.additionalAcknowledgementsPosition === 'below' || !props.additionalAcknowledgementsPosition"
            class="mt-2"
        >
            <slot name="additionalAcknowledgements" />
        </div>
    </div>
</template>

<script setup lang="ts">
    import { computed, provide, ref, useSlots, VNode } from 'vue'
    import { getDocumentAckMetadataPayload } from '../utils/document'
    import { useRouter } from 'vue-router/composables'
    import { DocumentSignatureType } from 'aven_types'
    import store from '../store'
    import { logEvent } from '../utils/http-client'
    import { logger } from '../utils/logger'
    import { AdditionalAcknowledgementsPosition } from '../utils/legal.types'

    const emit =
        defineEmits<{
            (e: 'click'): void
            (e: 'error', error: string): void
        }>()

    const props =
        defineProps<{
            docs: {
                docType: string
                signature: string
                signatureType: DocumentSignatureType
            }[]
            disabled?: boolean
            dataTestid?: string
            submitting?: boolean
            buttonClasses?: string
            eventName?: string
            additionalAcknowledgementsPosition?: AdditionalAcknowledgementsPosition
        }>()

    const slots = useSlots()
    const router = useRouter()

    const ackSubmitting = ref(false)
    const errorText = ref('')
    const additionalAckRef = ref<HTMLDivElement | undefined>(undefined)

    const getTextFromVNode = (nodes: VNode[]): string => {
        return nodes
            .map((node: VNode) => {
                return node.text ? node.text : getTextFromVNode(node.children ?? [])
            })
            .join('')
            .trim()
    }

    const ctaText = computed(() => {
        const ctaSlot = slots.cta?.()
        if (ctaSlot) {
            return getTextFromVNode(ctaSlot)
        }
        logger.warn('No cta text found for AckTermsButton')
        return ''
    })
    provide('ctaText', ctaText)

    const handleClick = async (evt: Event) => {
        const docAckMetadata = getDocumentAckMetadataPayload(evt, router.currentRoute.fullPath)

        if (props.eventName) {
            logEvent(props.eventName, docAckMetadata)
        }

        ackSubmitting.value = true

        let additionalAckText = undefined
        if (additionalAckRef.value) {
            additionalAckText = additionalAckRef.value.textContent
        }

        await Promise.all(
            props.docs.map(async (doc) => {
                // get ack metadata
                const ackMetadata = {
                    ...docAckMetadata,
                    signature: doc.signature,
                    signatureType: doc.signatureType,
                }

                const { success, error } = await store.dispatch('ackDocument', { docType: doc.docType, ackMetadata, additionalAcknowledgements: additionalAckText })
                if (!success) {
                    errorText.value = error
                    emit('error', error)
                }
            })
        )

        emit('click')
        ackSubmitting.value = false
    }
</script>
