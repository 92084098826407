"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ClaimCardholderToCardholderReturnCodes = exports.PifRewardTypeSpecForEmployees = exports.PifRewardTypeSpec = exports.pifRewardTypesWithFixedTipAmountArray = exports.pifRewardTypesThatPostStatementCredits = exports.PifRewardType = void 0;
// reward type is meant to support different variations of the Pay It Forward concept
// a `null` in  this column means that the PIF is a classicPif implementation
/** @TranslateToEnum */
var PifRewardType;
(function (PifRewardType) {
    PifRewardType["classicPif"] = "classicPif";
    PifRewardType["travelRewardsV0"] = "travelRewardsV0";
    PifRewardType["travelTicketsBahamas"] = "travelTicketsBahamas";
    /** groupCardholderRewards500USDToPrime gives both the source and the target a $500 statement credit (for suggested spend at Amazon Prime) */
    PifRewardType["groupCardholderRewards500USDToPrime"] = "groupCardholderRewards500USDToPrime";
    /** Referral for new UCC cardholders. Each party gets $100. */
    PifRewardType["ucc100UsdReferral"] = "ucc100UsdReferral";
})(PifRewardType = exports.PifRewardType || (exports.PifRewardType = {}));
/** For the payItForwardProcessingDaemonController, we need to filter by reward type
 * that pays a statement credit when paying them out */
exports.pifRewardTypesThatPostStatementCredits = [PifRewardType.classicPif, PifRewardType.groupCardholderRewards500USDToPrime, PifRewardType.ucc100UsdReferral];
/** For certain PIFs there is no concept of 'tipping'. Instead, both the source and target get a set amount, so
 * the tip amount and credit amount gets set by us in PifRewardTypeSpec below. Examples are group cardholder
 * Amazon Prime rewards (each party gets $500) or UCC referrals (each party gets $100).
 * If more reward types are added that have the same concept, they should be added here.
 */
exports.pifRewardTypesWithFixedTipAmountArray = [PifRewardType.groupCardholderRewards500USDToPrime, PifRewardType.ucc100UsdReferral];
// Todo We've now got what amounts to per-PifRewardType config spread across 3 structures:
//  - pifRewardTypesThatPostStatementCredits
//  - pifRewardTypesWithFixedTipAmountArray
//  - PifRewardTypeSpec
//  We should consolidate all to PifRewardTypeSpec so if new PifRewardTypes are added, it's clear
//  where all the config is defined for that type.
exports.PifRewardTypeSpec = {
    /** groupCardholderRewards500USDToPrime gives both the source and the target a $500 statement credit */
    [PifRewardType.groupCardholderRewards500USDToPrime]: { creditAmountDollars: 1000, tipAmountDollars: 500 },
    /** Both the source and target get $100 for UCC referrals */
    [PifRewardType.ucc100UsdReferral]: { creditAmountDollars: 200, tipAmountDollars: 100 },
};
/** Employees are handled separately in the backend where the same rewardType gives a smaller reward */
exports.PifRewardTypeSpecForEmployees = {
    /** groupCardholderRewards500USDToPrime gives both the source and the target a $5 statement credit */
    [PifRewardType.groupCardholderRewards500USDToPrime]: { creditAmountDollars: 10, tipAmountDollars: 5 },
    /** Both the source and target get $10 for UCC employee referrals*/
    [PifRewardType.ucc100UsdReferral]: { creditAmountDollars: 20, tipAmountDollars: 10 },
};
/** @TranslateToEnum */
var ClaimCardholderToCardholderReturnCodes;
(function (ClaimCardholderToCardholderReturnCodes) {
    /** Non Aven credit cards cannot claim pif invites (ie Auto Products) */
    ClaimCardholderToCardholderReturnCodes["unsupportedProduct"] = "unsupportedProduct";
    /** Invite code was not found in the Mail table or Mail record was not valid */
    ClaimCardholderToCardholderReturnCodes["invalidInvite"] = "invalidInvite";
    /** A LA Id cannot claim their own invite */
    ClaimCardholderToCardholderReturnCodes["selfInvitationError"] = "selfInvitationError";
    /** LA Id has already claimed this PIF invite code OR has claimed another invite code by the same rewardType */
    ClaimCardholderToCardholderReturnCodes["alreadyClaimedByThisCardholder"] = "alreadyClaimedByThisCardholder";
    /** Another LA Id has already claimed this PIF */
    ClaimCardholderToCardholderReturnCodes["alreadyClaimedByAnotherCardholder"] = "alreadyClaimedByAnotherCardholder";
    /** Successful PIF claim */
    ClaimCardholderToCardholderReturnCodes["success"] = "success";
})(ClaimCardholderToCardholderReturnCodes = exports.ClaimCardholderToCardholderReturnCodes || (exports.ClaimCardholderToCardholderReturnCodes = {}));
