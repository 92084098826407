"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullCreditLineContingencyType = exports.OfferContingencyStatus = exports.OfferContingency = exports.PythonFullReUnderwritingOfferType = exports.PRIME_V3_PRODUCT_CATEGORIES = exports.AUTOPAY_DISABLED_CASHBACK_ELIGIBLE_PRODUCT_CATEGORY = exports.ProductCategory = exports.PolicyVariantType = void 0;
var PolicyVariantType;
(function (PolicyVariantType) {
    PolicyVariantType["NooVariant"] = "NooVariant";
    PolicyVariantType["IgnoreStatedUsageVariant"] = "IgnoreStatedUsageVariant";
    PolicyVariantType["IgnoreStatedUsage400KVariant"] = "IgnoreStatedUsage400KVariant";
    PolicyVariantType["TaxFreeVariant"] = "TaxFreeVariant";
    PolicyVariantType["PrimeV2Variant"] = "PrimeV2Variant";
    PolicyVariantType["PrimeV3Variant"] = "PrimeV3Variant";
    PolicyVariantType["IncreasedBtCoVariant"] = "IncreasedBtCoVariant";
    PolicyVariantType["ManualOfferVariant"] = "ManualOfferVariant";
    PolicyVariantType["FixedTerm30YearVariant"] = "FixedTerm30YearVariant";
    PolicyVariantType["FixedTerm20YearVariant"] = "FixedTerm20YearVariant";
    PolicyVariantType["FixedTerm15YearVariant"] = "FixedTerm15YearVariant";
    PolicyVariantType["FixedTerm10YearVariant"] = "FixedTerm10YearVariant";
    PolicyVariantType["FixedTerm5YearVariant"] = "FixedTerm5YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantFixedTerm30YearVariant"] = "IgnoreStatedUsageVariantFixedTerm30YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantFixedTerm15YearVariant"] = "IgnoreStatedUsageVariantFixedTerm15YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantFixedTerm10YearVariant"] = "IgnoreStatedUsageVariantFixedTerm10YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantFixedTerm5YearVariant"] = "IgnoreStatedUsageVariantFixedTerm5YearVariant";
    PolicyVariantType["NooVariantFixedTerm30YearVariant"] = "NooVariantFixedTerm30YearVariant";
    PolicyVariantType["NooVariantFixedTerm15YearVariant"] = "NooVariantFixedTerm15YearVariant";
    PolicyVariantType["NooVariantFixedTerm10YearVariant"] = "NooVariantFixedTerm10YearVariant";
    PolicyVariantType["NooVariantFixedTerm5YearVariant"] = "NooVariantFixedTerm5YearVariant";
    PolicyVariantType["ConduitFixedTerm30YearVariant"] = "ConduitFixedTerm30YearVariant";
    PolicyVariantType["ConduitFixedTerm15YearVariant"] = "ConduitFixedTerm15YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantConduitFixedTerm30YearVariant"] = "IgnoreStatedUsageVariantConduitFixedTerm30YearVariant";
    PolicyVariantType["IgnoreStatedUsageVariantConduitFixedTerm15YearVariant"] = "IgnoreStatedUsageVariantConduitFixedTerm15YearVariant";
    PolicyVariantType["TaxFreeVariantPrimeV2Variant"] = "TaxFreeVariantPrimeV2Variant";
    PolicyVariantType["TaxFreeVariantPrimeV3Variant"] = "TaxFreeVariantPrimeV3Variant";
    PolicyVariantType["TermExtensionVariant"] = "TermExtensionVariant";
})(PolicyVariantType = exports.PolicyVariantType || (exports.PolicyVariantType = {}));
// Must match corresponding enum in aven_python/src/avenTypes/productCategory.py
/* @TranslateToPython @TranslateToEnum */
var ProductCategory;
(function (ProductCategory) {
    ProductCategory["basic"] = "basic";
    ProductCategory["prime"] = "prime";
    ProductCategory["primeV2"] = "primeV2";
    ProductCategory["primeV3"] = "primeV3";
    ProductCategory["autoPayOptInCashBackPromo"] = "autoPayOptInCashBackPromo";
    ProductCategory["autoPayDisabledCashBack"] = "autoPayDisabledCashBack";
    ProductCategory["primeV2AutoPayDisabledCashBack"] = "primeV2AutoPayDisabledCashBack";
    ProductCategory["primeV3AutoPayDisabledCashBack"] = "primeV3AutoPayDisabledCashBack";
    ProductCategory["fixedTerm"] = "fixedTerm";
    ProductCategory["fixedTermAutoPayDisabledCashBack"] = "fixedTermAutoPayDisabledCashBack";
})(ProductCategory = exports.ProductCategory || (exports.ProductCategory = {}));
/* @TranslateToPython @TranslateToEnum */
exports.AUTOPAY_DISABLED_CASHBACK_ELIGIBLE_PRODUCT_CATEGORY = [
    ProductCategory.autoPayDisabledCashBack,
    ProductCategory.primeV2AutoPayDisabledCashBack,
    ProductCategory.primeV3AutoPayDisabledCashBack,
    ProductCategory.fixedTermAutoPayDisabledCashBack,
];
exports.PRIME_V3_PRODUCT_CATEGORIES = [ProductCategory.primeV3, ProductCategory.primeV3AutoPayDisabledCashBack];
var PythonFullReUnderwritingOfferType;
(function (PythonFullReUnderwritingOfferType) {
    PythonFullReUnderwritingOfferType["CREDIT_LINE_INCREASE"] = "CREDIT_LINE_INCREASE";
    PythonFullReUnderwritingOfferType["APR_REDUCTION"] = "APR_REDUCTION";
    PythonFullReUnderwritingOfferType["FULL_BALANCE_SWEEP"] = "FULL_BALANCE_SWEEP";
})(PythonFullReUnderwritingOfferType = exports.PythonFullReUnderwritingOfferType || (exports.PythonFullReUnderwritingOfferType = {}));
/* @TranslateToPython @TranslateToEnum */
var OfferContingency;
(function (OfferContingency) {
    OfferContingency["titleInsurance"] = "titleInsurance";
    OfferContingency["employmentVerification"] = "employmentVerification";
    OfferContingency["employmentDurationVerification"] = "employmentDurationVerification";
    OfferContingency["hazardInsurance"] = "hazardInsurance";
    OfferContingency["illinoisAntiPredatory"] = "illinoisAntiPredatory";
    OfferContingency["mlsListing"] = "mlsListing";
    OfferContingency["deceasedCoOwner"] = "deceasedCoOwner";
    OfferContingency["removedCoOwner"] = "removedCoOwner";
    OfferContingency["removedSeparatedSpouse"] = "removedSeparatedSpouse";
    OfferContingency["removedMarriedSpouse"] = "removedMarriedSpouse";
    OfferContingency["poaApplicantNameReview"] = "poaApplicantNameReview";
    OfferContingency["priorLienRelease"] = "priorLienRelease";
    OfferContingency["lienPositionVerification"] = "lienPositionVerification";
    OfferContingency["userProvidedFirstLienVerification"] = "userProvidedFirstLienVerification";
    OfferContingency["userProvidedAllLienInfoVerification"] = "userProvidedAllLienInfoVerification";
    OfferContingency["floodInsuranceIncompleteChecks"] = "floodInsuranceIncompleteChecks";
    OfferContingency["uccFlowComplete"] = "uccFlowComplete";
    OfferContingency["uccIdentityChecks"] = "uccIdentityChecks";
    OfferContingency["appraisalContingency"] = "appraisalContingency";
    OfferContingency["trustVerification"] = "trustVerification";
})(OfferContingency = exports.OfferContingency || (exports.OfferContingency = {}));
var OfferContingencyStatus;
(function (OfferContingencyStatus) {
    OfferContingencyStatus["notApplicable"] = "notApplicable";
    OfferContingencyStatus["pending"] = "pending";
    OfferContingencyStatus["succeeded"] = "succeeded";
    OfferContingencyStatus["failed"] = "failed";
    OfferContingencyStatus["manualReview"] = "manualReview";
    OfferContingencyStatus["pendingCustomerAction"] = "pendingCustomerAction";
})(OfferContingencyStatus = exports.OfferContingencyStatus || (exports.OfferContingencyStatus = {}));
/* @TranslateToPython @TranslateToEnum */
var FullCreditLineContingencyType;
(function (FullCreditLineContingencyType) {
    FullCreditLineContingencyType["passAvenLienPositionHardCut"] = "passAvenLienPositionHardCut";
    FullCreditLineContingencyType["passPaymentPerformanceHardCut"] = "passPaymentPerformanceHardCut";
})(FullCreditLineContingencyType = exports.FullCreditLineContingencyType || (exports.FullCreditLineContingencyType = {}));
