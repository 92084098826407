import { AVEN_ADVISOR_UTM_SOURCE } from 'aven_types'

export const buildTaggedAutoApplicationUrl = (autoApplicationUrl: string, avenAdvisorLeadId: number | null): string => {
    const tags: { utm_source: string; utm_content: string; source_id?: number } = { utm_source: AVEN_ADVISOR_UTM_SOURCE, utm_content: 'today_tab' }
    if (avenAdvisorLeadId) {
        tags.source_id = avenAdvisorLeadId
    }
    return buildTaggedApplicationUrl(autoApplicationUrl, tags)
}

export const buildTaggedApplicationUrl = (url: string, tags: object): string => {
    const queryParams = Object.entries(tags)
        .map(([key, value]) => `${key}=${value}`)
        .join('&')
    return `${url}${url.includes('?') ? '&' : '?'}${queryParams}`
}
