import { isRunningInNativeApp, saveAccessTokenOnNative, saveSessionInfoOnNative } from '@/nativeWebInteraction/nativeWebInteraction'
import store from '@/store'
import { logger } from '@/utils/logger'
import { sharedRoutePaths } from '@/routes/sharedRoutes'
import { logEvent } from '@/utils/http-client'
import { appSessionStorage, localStorageKey } from './storage'

export enum LogoutReason {
    clickButton = 'clickButton',
    response401 = 'response401',
    blockedAccount = 'blockedAccount',
    deleteAccount = 'deleteAccount',
}

export const logout = (logoutReason: LogoutReason, blockAccount = false, authFailureReason?: string) => {
    logger.log(`Clearing Aven Advisor data upon logout`)

    store.commit('updateSessionInfo', {
        sessionAccessJWT: '',
        sessionId: '',
    })

    appSessionStorage.setItem(localStorageKey.sessionId, '')
    appSessionStorage.setItem(localStorageKey.sessionAccessJWT, '')
    appSessionStorage.setItem(localStorageKey.sessionRecordingInitialized, '')
    appSessionStorage.setItem(localStorageKey.sessionRecordingUrl, '')

    window.accessToken = ''
    window.sessionAccessJWT = ''

    if (isRunningInNativeApp()) {
        logger.log(`Aven Advisor is running in a native shell, so trying to clear the access token from the native shell as part of logout`)
        saveSessionInfoOnNative(null, null)
        saveAccessTokenOnNative(null)
    }
    store.commit('logout')

    const destinationUrl = blockAccount ? `${sharedRoutePaths.ROOT}?isAccountBlocked=true` : sharedRoutePaths.ROOT
    window.location.href = destinationUrl

    logEvent('event_advisor_logout', { logoutReason, authFailureReason })
}
