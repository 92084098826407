<template>
    <!--
        A developer can put <ack-terms-cta /> in the additional acknowledgments to print the text from the cta slot.
        This is useful in situations where you have a button that says "X" and in the footnotes you want
        to say "By clicking X, you agree to..."
    -->
    <span>{{ ctaText }}</span>
</template>

<script setup lang="ts">
    import { inject } from 'vue'

    // Inject the CTA text from the parent component
    const ctaText = inject('ctaText', '')

    if (!ctaText) {
        throw new Error('AckTermsCta must be used within an AckTermsButton')
    }
</script>
