"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvenAccountStanding = void 0;
var AvenAccountStanding;
(function (AvenAccountStanding) {
    AvenAccountStanding["current"] = "current";
    AvenAccountStanding["pastDue"] = "pastDue";
    AvenAccountStanding["delinquent"] = "delinquent";
    AvenAccountStanding["overLimit"] = "overLimit";
    AvenAccountStanding["fraud"] = "fraud";
    AvenAccountStanding["blocked"] = "blocked";
    AvenAccountStanding["closed"] = "closed";
    AvenAccountStanding["pendingClose"] = "pendingClose";
    AvenAccountStanding["pendingPayoffThenLimitedMode"] = "pendingPayoffThenLimitedMode";
    AvenAccountStanding["limitedMode"] = "limitedMode";
    AvenAccountStanding["homeReAttachInProgress"] = "homeReAttachInProgress";
    AvenAccountStanding["homeReAttachInRescission"] = "homeReAttachInRescission";
    AvenAccountStanding["expiringFloodInsurance"] = "expiringFloodInsurance";
    AvenAccountStanding["expiredFloodInsurance"] = "expiredFloodInsurance";
    AvenAccountStanding["paymentProcessingAfterPastDue"] = "paymentProcessingAfterPastDue";
})(AvenAccountStanding = exports.AvenAccountStanding || (exports.AvenAccountStanding = {}));
