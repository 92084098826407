var render = function render(){var _vm=this,_c=_vm._self._c;return _c('onboarding-layout',{attrs:{"error-text":_vm.errorText,"has-back":false}},[_c('div',{staticClass:"d-flex flex-column align-items-center"},[_c('img',{staticClass:"w-50",attrs:{"src":require("@/assets/images/advisor-logo.svg"),"alt":"Aven Logo"}}),_c('img',{staticClass:"w-100",attrs:{"src":require("@/assets/images/landing-promo-image.png"),"alt":"Aven promo image"}}),_c('h5',{staticClass:"fw-light text-center"},[_vm._v(" Enter your mobile number to get your financial profile ")])]),_c('form-container',{ref:"signInFormRef",attrs:{"id":"SignInForm"}},[_c('form-field-phone',{ref:"phoneField",staticClass:"mt-2",attrs:{"id":"phoneNumber","label":"Mobile Number","placeholder":"Mobile Number","name":"phoneNumber","input-type":"number","data-testid":"phone-number","autocomplete":"off","focus-event":"event_aven_advisor_phone_number_field_focus"},on:{"input":_vm.onPhoneNumberInput},model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),(_vm.shouldShowPaidReferralsOption)?_c('form-field',{staticClass:"mt-2",attrs:{"id":"inviteCode","name":"inviteCode","placeholder":"Invite Code (Optional)","label":"Invite Code (Optional)","data-testid":"invite-code"},model:{value:(_vm.inviteCode),callback:function ($$v) {_vm.inviteCode=$$v},expression:"inviteCode"}}):_vm._e(),_c('ack-terms-button',{attrs:{"button-classes":"mt-2 btn btn-primary tw-w-full","disabled":_vm.phoneNumberCannotBeSubmitted,"submitting":_vm.submitting,"docs":[
                {
                    docType: _vm.DocumentType.termsOfService,
                    signature: _vm.phoneNumber,
                    signatureType: _vm.DocumentSignatureType.text,
                },
            ],"data-testid":"continue-button"},on:{"click":_vm.handleOnSubmit,"error":_vm.handleAckTermsError},scopedSlots:_vm._u([{key:"cta",fn:function(){return [_vm._v(" Continue ")]},proxy:true},{key:"additionalAcknowledgements",fn:function(){return [_c('div',{staticClass:"small text-muted mt-2 mb-1"},[_vm._v(" By providing your number and clicking \""),_c('ack-terms-cta'),_vm._v("\", you consent to automated or manual marketing messages & to the "),_c('document-render-link',{attrs:{"doc-type":_vm.DocumentType.termsOfService,"title":"Terms of Service"}},[_vm._v(" Terms of Service ")]),_vm._v(". Consent to marketing messages is not required for any purchase. Text 'stop' to (415) 610-8313 to opt out. ")],1)]},proxy:true}])})],1),_c('a',{staticClass:"btn btn-link gray-link small fw-normal p-2 w-100",attrs:{"data-testid":"login-with-email-button"},on:{"click":_vm.handleLoginWithEmailClick,"keydown":_vm.handleLoginWithEmailClick}},[_vm._v(" Phone number changed? Login with email ")]),(!!_vm.visibleDisclosure)?_c('modal',{attrs:{"title":_vm.visibleDisclosure.title,"show":!!_vm.visibleDisclosure},on:{"close":_vm.toggleVisibleDisclosure}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.visibleDisclosure.body)}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }