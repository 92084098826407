<template>
    <!--
        View for rendering a document. Gated by an SSN input box if the document is SSN-protected.
    -->
    <div>
        <modal
            class="ssn-modal"
            :show="ssnModalOpen"
            @on-modal-close-click="onClose"
            v-if="ssnProtected && shouldShow"
        >
            <p>For your security, please enter your Full Social Security Number</p>
            <form-container ref="form">
                <validation-observer :ref="ssnObserverRef">
                    <form-field-ssn
                        @input="onSsnInput"
                        class="mb-2"
                        name="ssn"
                        :last-four-only="false"
                    />
                </validation-observer>
                <form-button
                    class="mt-2"
                    label="Continue"
                    :submitting="submitting"
                    @click="onSubmit"
                    type="submit"
                />
            </form-container>
        </modal>
        <document-render-modal
            :show="documentRenderModalOpen"
            :doc-type="docType"
            :title="documentTitle"
            :ssn="ssn || undefined"
            @close="onClose"
        />
    </div>
</template>

<script setup lang="ts">
    import FormButton from '@/components/base/FormButton.vue'
    import DocumentRenderModal from '@/components/DocumentRenderModal.vue'
    import { ValidationObserver } from 'vee-validate'
    import FormContainer from '@/components/base/FormContainer.vue'
    import Modal from '@/components/Modal.vue'
    import { computed, ref, watch } from 'vue'
    import FormFieldSsn from '@/components/base/FormFieldSsn.vue'
    import { logger } from '@/utils/logger'

    const props =
        defineProps<{
            docType: string
            documentTitle: string
            ssnProtected: boolean
            show: boolean
        }>()

    const emit =
        defineEmits<{
            (e: 'close'): void
        }>()

    const ssnModalOpen = ref(false)
    const documentRenderModalOpen = ref(false)
    const ssnObserverRef = ref<InstanceType<typeof ValidationObserver>>()
    const ssn = ref<string>('')
    const shouldShow = computed(() => {
        logger.info(`shouldShow: ${props.show}`)
        return props.show
    })
    const submitting = ref(false)

    watch(shouldShow, (newVal) => {
        if (newVal) {
            if (props.ssnProtected) {
                ssnModalOpen.value = true
            } else {
                documentRenderModalOpen.value = true
            }
        } else {
            ssnModalOpen.value = false
            documentRenderModalOpen.value = false
        }
    })

    const onSubmit = async () => {
        ssnModalOpen.value = false
        documentRenderModalOpen.value = true
    }

    const onClose = () => {
        emit('close')
    }

    const onSsnInput = (value: string) => {
        ssn.value = value
        console.log('ssn', ssn.value)
    }

    watch(ssn, (newVal) => {
        console.log('ssn', newVal)
    })
</script>

<style lang="scss">
    .modal {
        &.ssn-modal {
            .modal-dialog {
                margin: 0 auto;
                justify-content: center;
                display: flex;
            }
        }
    }
</style>
