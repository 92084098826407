"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AVEN_ADVISOR_UTM_SOURCE = exports.AvenAdvisorUpgradeStatus = exports.AvenAdvisorUpgradeJobStatus = exports.AvenAdvisorUpgradePath = void 0;
var AvenAdvisorUpgradePath;
(function (AvenAdvisorUpgradePath) {
    AvenAdvisorUpgradePath["UCC"] = "UCC";
    /**
     * Gets potential Aven upgrades based on the user's credit report.
     */
    AvenAdvisorUpgradePath["AVEN_CASH"] = "AVEN_CASH";
    AvenAdvisorUpgradePath["MOAP"] = "MOAP";
    AvenAdvisorUpgradePath["MOAP_WITH_DRYRUN"] = "MOAP_WITH_DRYRUN";
})(AvenAdvisorUpgradePath = exports.AvenAdvisorUpgradePath || (exports.AvenAdvisorUpgradePath = {}));
var AvenAdvisorUpgradeJobStatus;
(function (AvenAdvisorUpgradeJobStatus) {
    AvenAdvisorUpgradeJobStatus["Success"] = "Success";
    AvenAdvisorUpgradeJobStatus["Pending"] = "Pending";
    AvenAdvisorUpgradeJobStatus["Failure"] = "Failure";
})(AvenAdvisorUpgradeJobStatus = exports.AvenAdvisorUpgradeJobStatus || (exports.AvenAdvisorUpgradeJobStatus = {}));
var AvenAdvisorUpgradeStatus;
(function (AvenAdvisorUpgradeStatus) {
    AvenAdvisorUpgradeStatus["noAction"] = "noAction";
    AvenAdvisorUpgradeStatus["pending"] = "pending";
    AvenAdvisorUpgradeStatus["startDryrunPrequal"] = "startDryrunPrequal";
    AvenAdvisorUpgradeStatus["dryrunPrequalified"] = "dryrunPrequalified";
    AvenAdvisorUpgradeStatus["dryrunDenied"] = "dryrunDenied";
    AvenAdvisorUpgradeStatus["realLoanApplicationCreated"] = "realLoanApplicationCreated";
    AvenAdvisorUpgradeStatus["avenHomeUpgradeDetermined"] = "avenHomeUpgradeDetermined";
})(AvenAdvisorUpgradeStatus = exports.AvenAdvisorUpgradeStatus || (exports.AvenAdvisorUpgradeStatus = {}));
exports.AVEN_ADVISOR_UTM_SOURCE = 'advisor';
