<template>
    <div>
        <validation-provider
            mode="eager"
            v-slot="{ errors }"
            :vid="name"
            :rules="ssnValidation"
        >
            <div class="input form-floating">
                <p
                    v-if="prompt"
                    class="text-start fw-bold"
                >
                    {{ prompt }}
                </p>
                <input
                    type="tel"
                    inputmode="decimal"
                    class="form-control has-lock-icon"
                    :id="name"
                    v-mask="mask"
                    :placeholder="ssnLabel"
                    :class="{ 'is-invalid': errors[0] }"
                    :name="name"
                    :data-testid="dataTestid"
                    v-model="ssnValue"
                    @blur="onBlur($event.target)"
                    @focus="onFocus($event.target)"
                >
                <label :for="name">
                    {{ ssnLabel }}
                </label>
                <div class="invalid-feedback text-start">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'
    import { i18n } from '@/utils/i18n'
    import { logger } from '@/utils/logger'

    export default {
        name: 'FormSsnInput',
        mixins: [formInputMixin],
        props: {
            lastFourOnly: { type: Boolean, default: false },
            prompt: { type: String, default: '' },
            customLabel: { type: String, required: false },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        data() {
            return {
                // You must set 'mask' to be a regular variable (not a function)
                // Otherwise you will cause an infinite loop with vee-validate
                // See FormFieldCurrency.vue for more details
                // https://github.com/probil/v-mask/issues/511
                mask: this.lastFourOnly ? 'XXX-XX-####' : '###-##-####',
                ssnValue: 'a', // Intentionally set to a character to force the mask to be displayed by default, while keeping the actual value empty due to the validation in watcher
                ssnValidation: this.lastFourOnly ? 'ssnLast4' : 'ssnFull9',
            }
        },
        computed: {
            ssnLabel() {
                if (this.customLabel) {
                    return this.customLabel
                } else if (this.lastFourOnly) {
                    return i18n.t('components.formFieldSsn.placeholderLast4')
                }
                return i18n.t('components.formFieldSsn.placeholder')
            },
        },
        watch: {
            ssnValue: function (newValue, oldValue) {
                logger.log(`ssnValue: ${oldValue} -> ${newValue}`)
                const filteredValue = newValue?.replace(/[-X]/gi, '')
                this.$emit('input', filteredValue)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import 'node_modules/aven_shared/src/styles/components/base/formField';
    .has-lock-icon {
        background: url(~@/assets/images/global/lock_16_gray300.png) no-repeat scroll right $spacer center !important;
        background-size: 16px 16px !important;
        padding-right: 30px;
    }
</style>
