"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EmbeddedAdAction = void 0;
var EmbeddedAdAction;
(function (EmbeddedAdAction) {
    EmbeddedAdAction["uccContinueUpgradeFlow"] = "uccContinueUpgradeFlow";
    EmbeddedAdAction["uccSkipUpgradeFlow"] = "uccSkipUpgradeFlow";
    EmbeddedAdAction["uccGoBackUpgradeFlow"] = "uccGoBackUpgradeFlow";
    EmbeddedAdAction["contentHeightChanged"] = "contentHeightChanged";
    EmbeddedAdAction["displayContentInModal"] = "displayContentInModal";
    EmbeddedAdAction["openUrl"] = "openUrl";
})(EmbeddedAdAction = exports.EmbeddedAdAction || (exports.EmbeddedAdAction = {}));
