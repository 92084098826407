<template>
    <modal
        class="w-full"
        :show="show"
        :title="title"
        :loading="loading"
        :show-custom-button="true"
        @close="onModalCloseClick"
        full-screen
    >
        <loading-indicator v-if="loading" />
        <div
            class="d-flex justify-content-center w-100"
            v-show="!loading"
        >
            <!-- Doc data will be injected inside the div -->
            <div
                class="rendered-document-html"
                title="Document"
                v-if="renderedDocumentHtml"
            >
                <shadow-dom :html-content="renderedDocumentHtml" />
            </div>
        </div>
    </modal>
</template>

<script setup lang="ts">
    import { computed, ref, watch } from 'vue'
    import { logger } from '@/utils/logger'
    import Modal from '@/components/Modal.vue'
    import { getLegalDocumentHtml } from '@/services/api'
    import LoadingIndicator from '@/components/LoadingIndicator.vue'
    import ShadowDom from '@/components/ShadowDom.vue'

    const props =
        defineProps<{
            docType: string
            title: string
            show: boolean
            ssn?: string
        }>()

    const emit =
        defineEmits<{
            (e: 'close'): void
        }>()

    const onModalCloseClick = () => {
        emit('close')
    }

    const shouldShowModal = computed(() => props.show)
    const loading = ref(false)
    const renderedDocumentHtml = ref<string | null>(null)

    const downloadHtml = async () => {
        try {
            loading.value = true
            const pdfLegalDocumentResponse = await getLegalDocumentHtml(props.docType, props.ssn)
            renderedDocumentHtml.value = pdfLegalDocumentResponse?.data
        } catch (e) {
            logger.error(`Could not download legal document ${props.docType}`, e)
            return false
        }
        loading.value = false
        return true
    }

    watch(shouldShowModal, (newVal) => {
        logger.info(`showRef: ${newVal}`)
        if (newVal) {
            downloadHtml()
        }
    })
</script>

<style lang="scss">
    .document-container {
        width: 100%;
        height: 500px;
    }

    .modal {
        .modal-content {
            padding: 8px 8px;
            border-radius: 8px;
            width: 100vw !important;
        }
    }
</style>
