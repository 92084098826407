"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SessionType = void 0;
var SessionType;
(function (SessionType) {
    SessionType["RIN"] = "RIN";
    SessionType["RON"] = "RON";
    SessionType["Mobile"] = "Mobile";
    SessionType["Ipn"] = "Ipn";
})(SessionType = exports.SessionType || (exports.SessionType = {}));
