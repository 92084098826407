import { RouteConfig } from 'vue-router'
import { sharedRoutePaths } from '@/routes/sharedRoutes'
import { appRoutePaths } from '@/routes/appRoutes'

const onboardingRoute = '/onboarding'

export const onboardingRoutePaths = {
    FULL_NAME: `${onboardingRoute}/fullName`,
    ADDRESS: `${onboardingRoute}/address`,
    PREFILLED_PERSONAL_INFO: `${onboardingRoute}/prefilledPersonalInfo`,
    STATED_INCOME: `${onboardingRoute}/statedIncome`,
    STATED_INCOME_IRS: `${onboardingRoute}/statedIncomeIrs`,
    CASH_AND_STOCKS: `${onboardingRoute}/cashAndStocks`,
    DATE_OF_BIRTH: `${onboardingRoute}/dateOfBirth`,
    SSN_LAST_4: `${onboardingRoute}/ssnLast4`,
    KNOWLEDGE_BASED_AUTHENTICATION: `${onboardingRoute}/knowledgeBasedAuthentication`,
    SET_PASSWORD: `${onboardingRoute}/setPassword`,
    SET_PASSWORD_AUTH: `${onboardingRoute}/setPasswordAuth`,
    ENABLE_NOTIFICATION: `${onboardingRoute}/enableNotifications`,
    OTP_VERIFICATION: `${onboardingRoute}/otpVerification`,
    SSN_VERIFICATION: `${onboardingRoute}/ssnVerification`,
    EXPERIAN_BOOST: `${onboardingRoute}/experianBoost`,
    STATED_GOAL: `${onboardingRoute}/statedGoal`,
    FREE_COFFEE_WAITLIST: `${onboardingRoute}/freeCoffeeWaitlist`,
} as const

export type OnboardingRoute = typeof onboardingRoutePaths[keyof typeof onboardingRoutePaths] | typeof sharedRoutePaths[keyof typeof sharedRoutePaths] | typeof appRoutePaths.TODAY

export const onboardingRoutes: RouteConfig[] = [
    {
        path: onboardingRoutePaths.FULL_NAME,
        component: () => import(/* webpackChunkName: "full-name-phone", webpackPrefetch: true */ '@/pages/onboarding/FullNamePhone.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.ADDRESS,
        component: () => import(/* webpackChunkName: "address", webpackPrefetch: true */ '@/pages/onboarding/Address.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.PREFILLED_PERSONAL_INFO,
        component: () => import(/* webpackChunkName: "prefilled-personal-info", webpackPrefetch: true */ '@/pages/onboarding/PrefilledPersonalInfo.vue'),
        meta: { public: false },
    },

    {
        path: onboardingRoutePaths.STATED_INCOME,
        component: () => import(/* webpackChunkName: "stated-income", webpackPrefetch: true */ '@/pages/onboarding/StatedIncome.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.CASH_AND_STOCKS,
        component: () => import(/* webpackChunkName: "cash-and-stocks", webpackPrefetch: true */ '@/pages/onboarding/CashAndStocks.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.DATE_OF_BIRTH,
        component: () => import(/* webpackChunkName: "date-of-birth", webpackPrefetch: true */ '@/pages/onboarding/DateOfBirth.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.SSN_LAST_4,
        component: () => import(/* webpackChunkName: "ssn-last-4", webpackPrefetch: true */ '@/pages/onboarding/SsnLast4.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.KNOWLEDGE_BASED_AUTHENTICATION,
        component: () => import(/* webpackChunkName: "knowledge-based-authentication", webpackPrefetch: true */ '@/pages/onboarding/KnowledgeBasedAuthentication.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.STATED_INCOME_IRS,
        component: () => import(/* webpackChunkName: "stated-income-irs", webpackPrefetch: true */ '@/pages/onboarding/StatedIncomeIrs.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: onboardingRoutePaths.OTP_VERIFICATION,
        component: () => import(/* webpackChunkName: "irs-verification", webpackPrefetch: true */ '@/pages/OtpVerification.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: onboardingRoutePaths.SET_PASSWORD,
        component: () => import(/* webpackChunkName: "password", webpackPrefetch: true */ '@/pages/onboarding/Password.vue'),
        meta: { public: false },
    },
    {
        path: onboardingRoutePaths.SET_PASSWORD_AUTH,
        component: () => import(/* webpackChunkName: "password-auth", webpackPrefetch: true */ '@/pages/onboarding/Password.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: onboardingRoutePaths.SSN_VERIFICATION,
        component: () => import(/* webpackChunkName: "ssn-verification", webpackPrefetch: true */ '@/pages/onboarding/SsnVerification.vue'),
        meta: { public: false, requiresAuth: true },
        props: { nextRoutePath: onboardingRoutePaths.EXPERIAN_BOOST },
    },
    {
        path: onboardingRoutePaths.EXPERIAN_BOOST,
        component: () => import(/* webpackChunkName: "experian-boost-data-sharing-consent", webpackPrefetch: true */ '@/pages/ExperianBoostDataSharingConsent.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: onboardingRoutePaths.STATED_GOAL,
        component: () => import(/* webpackChunkName: "stated-goal", webpackPrefetch: true */ '@/pages/onboarding/StatedGoal.vue'),
        meta: { public: false, requiresAuth: true },
    },
    {
        path: onboardingRoutePaths.FREE_COFFEE_WAITLIST,
        component: () => import(/* webpackChunkName: "free-coffee-waitlist", webpackPrefetch: true */ '@/pages/onboarding/FreeCoffeeWaitlist.vue'),
        meta: { public: false, requiresAuth: false },
    },
]
