"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.HumanReadableLocationForPromotion = exports.PromotionStatus = exports.PromotionName = void 0;
/* @TranslateToPython @TranslateToEnum */
var PromotionName;
(function (PromotionName) {
    PromotionName["freeAmazonPrime"] = "freeAmazonPrime";
    PromotionName["amazonTenDollarCredit"] = "amazonTenDollarCredit";
    PromotionName["amazonTwentyFiveDollarCredit"] = "amazonTwentyFiveDollarCredit";
    PromotionName["taxPaymentCredit"] = "taxPaymentCredit";
    PromotionName["costcoFreeMembership"] = "costcoFreeMembership";
    PromotionName["restaurants7Percent"] = "restaurants7Percent";
    PromotionName["amazonPrime"] = "amazonPrime";
    PromotionName["gasAndGroceries7Percent"] = "gasAndGroceries7Percent";
})(PromotionName = exports.PromotionName || (exports.PromotionName = {}));
var PromotionStatus;
(function (PromotionStatus) {
    PromotionStatus["notEnrolled"] = "notEnrolled";
    /** For upgrade, a UCC user can be put in this status and will move to "previousActivated"
     * if they click on an upgrade ad associated with this promotion.  */
    PromotionStatus["previousEnrolled"] = "previousEnrolled";
    /** For a home upgrade user to get enrolled in a promotion, its previousLoanApplicationId must be found in the table at this status.
     * This would mean that the user's pre-upgrade UCC account "activated" the promotion, so that the home account can get enrolled in the promotion once originated. */
    PromotionStatus["previousActivated"] = "previousActivated";
    PromotionStatus["isEnrolled"] = "isEnrolled";
    PromotionStatus["conditionsFulfilled"] = "conditionsFulfilled";
    PromotionStatus["activatedByCustomer"] = "activatedByCustomer";
    PromotionStatus["promotionPaid"] = "promotionPaid";
})(PromotionStatus = exports.PromotionStatus || (exports.PromotionStatus = {}));
var HumanReadableLocationForPromotion;
(function (HumanReadableLocationForPromotion) {
    HumanReadableLocationForPromotion["orangeCounty"] = "Orange County";
    HumanReadableLocationForPromotion["arizona"] = "Arizona";
    HumanReadableLocationForPromotion["phoenix"] = "Phoenix";
    HumanReadableLocationForPromotion["ohio"] = "Ohio";
    HumanReadableLocationForPromotion["cleveland"] = "Cleveland";
    HumanReadableLocationForPromotion["denver"] = "Denver";
})(HumanReadableLocationForPromotion = exports.HumanReadableLocationForPromotion || (exports.HumanReadableLocationForPromotion = {}));
