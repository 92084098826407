import { contact_info } from '@/utils/contact-info-dictionary'
import { avenAdvisorFreeCoffeePromoTermsAndConditionsPath } from 'aven_types'

export const ENGLISH_TRANSLATIONS = {
    pages: {
        marketing: {
            landing: {
                safeCreditScore: 'This will <span class="thick-underline">NOT</span> affect your credit score.',
            },
        },
        onboarding: {
            prefilledPersonalInfo: {
                disclosures: {
                    affiliateConsents:
                        '1. By clicking Continue, you consent to Credit Builder Inc. and its affiliates ' +
                        'obtaining your credit report from consumer reporting agencies to verify your information, help ' +
                        'you track your credit score, and inform you of products and services you might be eligible for. ' +
                        'You also agree to our <a target="_blank" href="https://www.aven.com/docs/ESIGNConsent.pdf">ESIGN Consent</a>, ' +
                        '<a target="_blank" href="https://www.aven.com/public/docs/TermsOfService">Terms of Service</a>, ' +
                        'and <a target="_blank" href="https://www.aven.com/docs/PrivacyPolicy.html">Privacy Policy</a>. ' +
                        'See also our <a href="https://www.aven.com/docs/ConsumerPrivacyPolicyNotice.pdf">Consumer Privacy Notice</a>.',
                    hausFootnote:
                        '2. By clicking Continue, you also receive a free lifetime membership to the Homeowners ' +
                        'Association of the United States (HAUS). HAUS is a nonprofit dedicated to promoting homeownership. You ' +
                        'may discontinue your HAUS membership at any time.',
                },
            },
        },
    },
    components: {
        formFieldAddress: {
            placeholder: {
                completeAddress: 'Street Address, City, State',
                street: 'Street Address',
                unit: 'Apt #',
                city: 'City',
                state: 'State',
                postalCode: 'Zip',
            },
            enterAddressManually: 'Enter address manually',
            validation: {
                addressNotFound: 'Address Not Found. Please check your input.',
                CAResidentOnly: 'Must be a resident of California',
            },
            googleError: 'Could not find valid location, please try again',
        },
        formSelectAutocomplete: {
            noOptions: 'No results found for',
            startSearching: 'Start typing to search',
        },
        formFieldSsn: {
            placeholder: 'Social Security Number',
            placeholderLast4: 'Last 4 of Social Security',
        },
        freeCoffeeCard: {
            starbucksInvitationAcceptanceAndTermsAndConditions: `By clicking above, you accept this Starbucks Coffee Promotion invitation and the Starbucks Coffee<a href="${avenAdvisorFreeCoffeePromoTermsAndConditionsPath}" target="_blank" class="tw-text-gray-300 hover:tw-text-gray-400 visited:tw-text-gray-300 tw-no-underline"> <span class="tw-underline">Terms and Conditions</span>. </a> Starbucks is not a sponsor of this promotion.`,
            dunkinInvitationAcceptanceAndTermsAndConditions: `By clicking above, you accept this Dunkin' Coffee Promotion invitation and the Dunkin' Coffee<a href="${avenAdvisorFreeCoffeePromoTermsAndConditionsPath}" target="_blank" class="tw-text-gray-300 hover:tw-text-gray-400 visited:tw-text-gray-300 tw-no-underline"> <span class="tw-underline">Terms and Conditions</span>. </a> Dunkin' is not a sponsor of this promotion.`,
            starbucksTermsAndConditionsAcceptance: `By participating, you accept the Starbucks Coffee <a href="${avenAdvisorFreeCoffeePromoTermsAndConditionsPath}" target="_blank" class="tw-text-gray-300 hover:tw-text-gray-400 visited:tw-text-gray-300 tw-no-underline"><span class="tw-underline">Terms and Conditions</span>. </a> Starbucks is not a sponsor of this promotion.`,
            dunkinTermsAndConditionsAcceptance: `By participating, you accept the Dunkin' Coffee <a href="${avenAdvisorFreeCoffeePromoTermsAndConditionsPath}" target="_blank" class="tw-text-gray-300 hover:tw-text-gray-400 visited:tw-text-gray-300 tw-no-underline"><span class="tw-underline">Terms and Conditions</span>. </a> Dunkin' is not a sponsor of this promotion.`,
            starbucksTrademarkDisclosure: 'The Starbucks word mark and Logo are trademarks of Starbucks Corporation.',
            dunkinTrademarkDisclosure: 'The Dunkin word mark and Logo are trademarks of Dunkin Brands Inc.',
        },
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        invalidDate: 'Invalid date. Please use MM/DD/YYYY format',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be greater than {min}',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
    },
    customValidationRules: {
        CAResidentOnly: 'Must be a resident of California',
        twilioCode: 'Code must be exactly 4 digits',
        ssnLast4: 'Last 4 SSN digits are required',
        ssnFull9: 'Full 9 digit SSN is required',
        incompleteAddress: 'Incomplete address',
        underAge: 'You must be over {minAgeYears} years old',
        zeroOrNegativeAge: 'Please make sure your date of birth is correct and try again',
        notValidPhoneNumber: 'Not a valid phone number',
        invalidHomeLocationDueToFloodZone: 'Sorry, we do not currently offer our card in special flood hazard areas',
        rinCounty: "Sorry, our card is not currently available in your county. We'll reach out when it's ready!",
        emailAlreadyExists: 'An account already exists for this email address',
        phoneNumberAlreadyExists: 'An account already exists for this phone number',
        invalidCurrency: 'Please enter a valid amount',
        currencyTooSmall: 'Minimum allowed amount is {minAmount}',
        currencyTooLarge: 'Maximum allowed amount is {maxAmount}',
        passwordRequirements: 'Password must be at least 8 characters, with at least 1 number and 1 special character.',
        passwordMismatch: `The passwords you entered don't match`,
    },
    contactInfo: contact_info,
}
