<template>
    <!--
        A quick link you can drop into text to open a document (potentially gated by SSN).
    -->
    <span>
        <a
            class="blue-link inline"
            @click="handleClick"
            @keydown.enter="handleClick"
        >
            <slot />
        </a>
        <document-render
            :class="showModal ? 'd-block' : 'd-none'"
            :doc-type="props.docType"
            :document-title="props.title"
            :ssn-protected="props.ssnProtected || false"
            :show="showModal"
            @close="showModal = false"
        />
    </span>
</template>

<script setup lang="ts">
    import DocumentRender from '@/components/DocumentRender.vue'
    import { ref } from 'vue'
    import { logger } from '../utils/logger'

    const props =
        defineProps<{
            docType: string
            title: string
            ssnProtected?: boolean
        }>()

    const showModal = ref(false)

    const handleClick = () => {
        logger.info('DocumentRenderLink clicked')
        showModal.value = true
    }
</script>
