import { safeJsonStringify } from '@/utils/stringUtil'
import { logger } from './logger'
import assert from 'assert'

export const getDocumentAckMetadataPayload = (event: Event, urlPath: string) => {
    const serializedEvent = safeJsonStringify(event)
    return {
        browserEvent: serializedEvent,
        localTimestamp: new Date().toString(),
        urlPath,
    } as const
}

export const getDocument = async (pdfBuffer: Buffer) => {
    const pdfDoc = pdfBuffer
    assert(pdfDoc, `Aven Advisor pdfDoc appears to be falsey, did it fail to generate or is it empty?`)
    const file = new Blob([pdfDoc], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)
    const link = document.createElement('a')
    // create a blobURI pointing to our Blob
    link.href = fileURL
    link.target = '_blank'
    logger.info(fileURL)
    // some browser needs the anchor to be in the doc
    document.body.append(link)
    link.click()
    logger.log(`Aven Advisor programmatically clicked ${fileURL}`)
    link.remove()
    // in case the Blob uses a lot of memory
    setTimeout(() => {
        logger.log(`Aven Advisor revoked ${fileURL} to save memory`)
        URL.revokeObjectURL(fileURL)
    }, 5000)
}
