"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_SEND_GRID_EMAIL_FOR_SUPPORT = exports.SendGridUnsubscribeGroupIds = exports.SendGridEmailTemplates = exports.TextMessageContentIdentifier = void 0;
var TextMessageContentIdentifier;
(function (TextMessageContentIdentifier) {
    TextMessageContentIdentifier["stringWithDeepLinkPlaceholder"] = "stringWithDeepLinkPlaceholder";
    TextMessageContentIdentifier["mediaUrl"] = "mediaUrl";
})(TextMessageContentIdentifier = exports.TextMessageContentIdentifier || (exports.TextMessageContentIdentifier = {}));
// See https://mc.sendgrid.com/dynamic-templates
// Todo There's currently a template ID stored in our env vars at process.env.SEND_GRID_EMAIL_TEMPLATE
//  This is the default if another template is not provided. It doesn't seem like it really needs to
//  be a secret, though ...
var SendGridEmailTemplates;
(function (SendGridEmailTemplates) {
    SendGridEmailTemplates["AVEN_ADVISOR_BASIC_TEMPLATE"] = "d-17ff9c9e1592495894dc77e624a76def";
    SendGridEmailTemplates["AVEN_ADVISOR_HELOC_AD_TEMPLATE"] = "d-43d34bc4113c4b91a32b1d0369eb3d85";
    SendGridEmailTemplates["AVEN_ADVISOR_EXPERIAN_BOOST_TEMPLATE"] = "d-5fce8ed6f4e641c6bbbe5da9d25b5b82";
})(SendGridEmailTemplates = exports.SendGridEmailTemplates || (exports.SendGridEmailTemplates = {}));
// See https://mc.sendgrid.com/unsubscribe-groups
var SendGridUnsubscribeGroupIds;
(function (SendGridUnsubscribeGroupIds) {
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["AVEN_PROMOTIONAL_EMAILS"] = 17710] = "AVEN_PROMOTIONAL_EMAILS";
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["AVEN_PRESCREENED_OFFER_EMAILS"] = 22640] = "AVEN_PRESCREENED_OFFER_EMAILS";
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["AVEN_ADVISOR_NOTIFICATIONS"] = 18956] = "AVEN_ADVISOR_NOTIFICATIONS";
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["AVEN_ADVISOR_CONTENT_NOTIFICATIONS"] = 19101] = "AVEN_ADVISOR_CONTENT_NOTIFICATIONS";
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["AVEN_ADVISOR_MARKETING_NOTIFICATIONS"] = 19528] = "AVEN_ADVISOR_MARKETING_NOTIFICATIONS";
    SendGridUnsubscribeGroupIds[SendGridUnsubscribeGroupIds["REG_F_COLLECTIONS_REMINDER"] = 22378] = "REG_F_COLLECTIONS_REMINDER";
})(SendGridUnsubscribeGroupIds = exports.SendGridUnsubscribeGroupIds || (exports.SendGridUnsubscribeGroupIds = {}));
exports.DEFAULT_SEND_GRID_EMAIL_FOR_SUPPORT = 'support@aven.com';
