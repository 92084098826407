import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import { getField, updateField } from 'vuex-map-fields'
import { PersistedState, persistedStore } from '@/store/persisted'
import {
    acceptCreditOfferDisclosure,
    acceptEsignConsent,
    AdvisorLiabilityAccountInformation,
    AlpacaEquityWrapper,
    AssetSnapshot,
    AvenAdvisorConfig,
    AvenAdvisorEnergyPrices,
    AvenAdvisorHomeValueResponse,
    AvenAdvisorLiabilitySnapshot,
    AvenAdvisorNetWorth,
    AvenAdvisorNetWorthResponse,
    editAccountInformation,
    generateLinkToAutoApplication,
    GetAvenAdvisorCreditScoresErrors,
    getAvenAdvisorLeadAccountInfo,
    getCashAndStock,
    getConfig,
    getConsentToShareDataWithExperianBoost,
    getCreditScoreHistory,
    getMethodFiCreditScoreHistory,
    getCreditScores,
    getCreditScoreTips,
    getEnergyPrices,
    getHeiEstimate,
    getHomeOwnershipStatus,
    GetHomeOwnershipStatusResponse,
    getHomeValue,
    getInviteCode,
    getIsEligibleForPaidReferrals,
    getIsPlaidRequiredForPaidReferral,
    getLiabilityAccounts,
    getNeighborhoodReport,
    getPrefillInformation,
    getReferralInfo,
    getStockBars,
    getSubscriptions,
    GetSubscriptionsResponse,
    getSweepstakesStatus,
    getTopMerchants,
    HelocHomeAdStatus,
    HomeValueSnapshot,
    initiateCreditOffer,
    LoanOffer,
    NeighborhoodDataResponse,
    requestTwoFactor,
    retrieveVehicleValue,
    SpecialOffer,
    spinTheWheel,
    VehicleInformation,
    PayoutHistoryItem,
    getPayoutHistory,
    getPayoutDeniedBankNames,
    getOwnwellSavingsEstimate,
    GetWeeklyTransactionsResponse,
    getWeeklyTransactions,
    AvenAdvisorInvestmentHoldingsResponse,
    getInvestmentHoldings,
    PersonaStatus,
    getPersonaStatus,
    getPersonaLink,
    getNeighborhoodRentPrices,
    AvenAdvisorRentalMarketData,
    getCrimeReport,
    consentToContactUpload,
    AvenAdvisorNotablePersonTrades,
    getNotableTrades,
    createVisitAndUpdateStreak,
    AuthFlow,
    DEFAULT_NUM_WEEKLY_TRANSACTIONS_TO_RETURN,
    getLienInfo,
    getSaleListings,
    getSpendingCardData,
    getHomeEquityInfo,
    getExpiredPlaidItems,
    getRentListings,
    getTransactionsBetweenDates,
    getNotificationDataWithPushId,
    getCraigslistListings,
    getPushNotifications,
    logPushNotificationClick,
    getAvenAdvisorCreditCardRecommendations,
    getLatestLienReport,
    intializeMethodFiCreditScoreData,
    getMethodFiCreditScoreData,
    maybeInitializeMethodFi,
    employeeRefreshMethodFiEntity,
    getSweepstakesV2,
    getIsPlaidAuthenticatedForSweepstakes,
    getFreeCoffeeInfo,
    getBorrowingCapacity,
    getAffiliateRewardsLedger,
    refreshVehicles,
    maybeStartDryrunPrequalForUpgradePath,
    getUpgradeActionForPath,
    pollForLoanApplicationPrequalOffer,
    getFreeCoffeeGiftCardHistory,
    getExperianPullWithTrendedTradelines,
    getIsPlaidConnected,
    ackLegalDocument,
    getEmbeddedAdDataFromAvenFinancial,
    getFreeCoffeeSelection,
} from '@/services/api'
import { inspect, logger } from '@/utils/logger'
import { appLocalStorage, appSessionStorage, localStorageKey } from '@/utils/storage'
import assert from 'assert'
import { logout, LogoutReason } from '@/utils/logoutHelper'
import { PlaidManager } from '@/utils/plaidManager'
import { BottomNavigationPages } from '@/components/BottomNavigationBar.types'
import { DocumentType, getPlaidLinkToken, isRunningInNativeApp, openExternalWebView, saveAccessTokenOnNative, savePlaidLinkToken } from '@/nativeWebInteraction/nativeWebInteraction'
import {
    featureFlagConfig,
    HomeEquityInfo,
    GetSpendingCardDataResponse,
    LienInfo,
    CreditScoreDataPoint,
    GetRecentTransactionsResponse,
    GetTopMerchantsResponse,
    AvenAdvisorCreditCardRecommendationData,
    AvenAdvisorLienReportData,
    FetchAvenAdvisorLienReportResponse,
    AvenAdvisorLienReportStatus,
    MethodFiCreditScoreData,
    MethodFiCreditScoreDataPoint,
    AvenAdvisorLeadAccountInfoPayload,
    MaritalStatus,
    SignUpGoal,
    AvenAdvisorPropertyListing,
    AvenAdvisorRentalListingResponse,
    AvenAdvisorSaleListingResponse,
    HomeOwnershipStatus,
    AvenAdvisorExpiredPlaidItemsResponse,
    RequiredPlaidAction,
    GetSweepstakesStatusResponse,
    CraigslistListingItem,
    AvenAdvisorPushNotificationCenterItem,
    SweepstakesV2,
    FreeCoffeeInfoResponse,
    AvenAdvisorBorrowingCapacityData,
    AvenAffiliateRewardsPointsLedgerItem,
    AvenAdvisorUpgradePath,
    IssuedGiftCardInfo,
    AvenAdvisorUpgradeStatus,
    LoanTermsForFrontend,
    AvenAdvisorUpgradeAction,
    SignDocumentPayload,
    AvenFinancialEmbeddedAdData,
    FreeCoffeeType,
} from 'aven_types'
import { buildTaggedAutoApplicationUrl } from '@/utils/urlUtil'
import { NeighborhoodCrimeData, StreakResponse, DEFAULT_LIMIT_ON_CRIME_REPORTS } from '@/services/api.types'
import { getSweepstakesRenderType, SweepstakesRenderType } from '@/utils/sweepstakesUtil'
import { appRoutePaths } from '@/routes/appRoutes'
import { AddressComponents } from '@/utils/address.types'
import { openReplay } from '@/services/marketing'
import { AvenAdvisorTab } from '@/utils/tabPages.types'
import { fcraDisclosure, tcpaDisclosure } from 'aven_shared/src/legal/evenFinancialDisclosures'
import { logEvent } from '../utils/http-client'
import { SeonManager } from '../mixins/seonManager'

Vue.use(Vuex)

/**
 * Occasionally getting the signed in user's Aven Advisor lead ID fails. This is
 * the max number of times we'll try to retrieve it.
 */
const MAX_ATTEMPTS_TO_FETCH_AVEN_ADVISOR_ACCOUNT_INFO = 5

interface HomeEquityInvestmentEstimate {
    applicationUrl: string
    minEstimateOfferAmountDollars: number
    maxEstimateOfferAmountDollars: number
}

export interface DefaultState {
    ssnLast4: string
    dateOfBirth: Date | null
    firstName: string | null
    lastName: string | null
    phoneNumber: string | null
    email: string | null
    inviteCode: string
    otpSid: string
    otpCode: string
    addressStreet: string
    secondaryAddressDesignator: string | null
    secondaryAddressUnit: string | null
    addressCity: string
    addressState: string
    addressPostalCode: string | null
    homeOwnershipStatus: HomeOwnershipStatus | null
    numberOfBedrooms: string | null
    statedIncome: string
    statedIncomeHousehold: string
    maritalStatus: MaritalStatus | null
    helocHomeAdStatus: HelocHomeAdStatus | null
    helocHomeAdUrl: string | null
    autoApplicationUrl: string | null
    idologyQueryId: string | null
    idologySsnQueryId: string | null
    config: AvenAdvisorConfig | null
    avenAdvisorLeadId: number | null
    isAvenEmployee: boolean | null
    numAttemptsToFetchAvenAdvisorLeadAccountInfo: number
    didFetchCreditScoreData: boolean
    isFetchingCreditScoreData: boolean
    didFetchMethodFiCreditScoreData: boolean
    isFetchingMethodFiCreditScoreData: boolean
    methodfiCreditScoreData: MethodFiCreditScoreData | null
    ficoScore: number | null
    vantageScore: number | null
    dateOfScores: Date | null
    dateOfNextScores: Date | null
    needFull9ssn: boolean
    isExperianFrozen: boolean
    didFetchReferralInfo: boolean
    pendingReferralPayoutDollars: number | null
    payoutsEnabled: boolean | null
    connectedStripeAccountRemediationMessage: boolean | null
    spinToWinEnabled: boolean | null
    availableSpins: number | null
    lastSuccessfulSpinAmount: number | null
    // true if the app attempted to initiate offers, whether successful or not. If
    // this is true, we won't attempt to init offers again.
    didTryInitiateEvenOffers: boolean
    // true if the app was successful in initiating offer generation. This tells the
    // app that it can & should try to fetch offers.
    didInitiateEvenOffersSuccessfully: boolean
    didFetchCreditAndSpecialOffers: boolean
    creditOffers: LoanOffer[] | null
    specialOffers: SpecialOffer[] | null
    canDisplayAvenHelocCardAd: boolean | null
    isUserAlreadyHelocCardApplicant: boolean | null
    creditScoreHistory: CreditScoreDataPoint[] | null
    methodFiCreditScoreHistory: MethodFiCreditScoreDataPoint[] | null
    persistedStore: PersistedState
    cashAndStockInfo:
        | {
              institutionId: string | null
              institutionName: string | null
              requiredPlaidAction: RequiredPlaidAction | null
              cashAndStockAccountBalances: { assets: AvenAdvisorNetWorth[] } | null
              cashAndStockLastUpdatedDate: Date | null
              cashAndStockTotalAssets: number | null
              nextCashAndStockUpdateAvailableDate: Date | null
              lowBalanceAlertThreshold: number | null
              history: AssetSnapshot[] | null
          }[]
        | null
    cashAndStockAggregatedHistory: { value: number; date: Date }[] | null
    cashAndStockErrorMessage: Record<string, string | null> | null
    cashAndStockRefreshingInstitutionNames: string[]
    currentBottomNavigationBarPage: BottomNavigationPages | null
    crimeReport: NeighborhoodCrimeData | null
    homeValue: number | null
    homeValueLastUpdatedDate: Date | null
    homeValueNextUpdateAvailableDate: Date | null
    homeValueHistory: HomeValueSnapshot[] | null
    homeEquityInvestmentEstimate: HomeEquityInvestmentEstimate | null
    methodFiLiabilityAccounts: AdvisorLiabilityAccountInformation[] | null
    liabilityAccountsHistory: AvenAdvisorLiabilitySnapshot[] | null
    medianSalePriceInNeighborhood: number | null
    neighborhoodReportLastUpdateDate: Date | null
    neighborhoodReportHomeValueHistory:
        | {
              medianSalePrice: number
              date: Date
          }[]
        | null
    plaidLinkToken: string | null
    vehicleInformation: VehicleInformation | null
    canDisplayAutoAd: boolean | null
    isPlaidRequiredForPaidReferral: boolean | null
    energyPriceData: AvenAdvisorEnergyPrices | null
    stockBars: Record<string, AlpacaEquityWrapper> | null
    topMerchantsInfo: GetTopMerchantsResponse | null
    subscriptionsInfo: GetSubscriptionsResponse | null
    weeklyTransactionsInfo: GetWeeklyTransactionsResponse | null
    recentTransactions: GetRecentTransactionsResponse | null
    spendingCardData: GetSpendingCardDataResponse | null
    notificationDataFromDeeplink: AvenAdvisorPushNotificationCenterItem | null
    skippedConnectPlaidInterstitial: boolean | null
    sweepstakesStatus: GetSweepstakesStatusResponse | null
    sweepstakesV2: SweepstakesV2 | null
    sweepstakesRenderType: SweepstakesRenderType | null
    creditScoreTips: { tipTitle: string; tipBody: string }[] | null
    shouldShowPaidReferrals: boolean | null
    signUpGoal: SignUpGoal[] | null
    payoutHistory: PayoutHistoryItem[] | null
    payoutDeniedBankNames: string[] | null
    giftCardHistory: IssuedGiftCardInfo[] | null
    didFetchOwnwellSavingsEstimate: boolean
    ownwellSavingsEstimate: number | null
    investmentHoldings: AvenAdvisorInvestmentHoldingsResponse | null
    personaStatus: PersonaStatus | null
    personaLink: string | null
    neighborhoodRentData: AvenAdvisorRentalMarketData | null
    didFetchNeighborhoodRentData: boolean
    notablePeopleTrades: AvenAdvisorNotablePersonTrades[] | null
    streakData: StreakResponse | null
    expiredPlaidItems: AvenAdvisorExpiredPlaidItemsResponse | null
    authFlow: AuthFlow | null
    currentTab: AvenAdvisorTab | null
    lienInfo: LienInfo | null
    saleListings: AvenAdvisorPropertyListing[] | null
    soldProperties: AvenAdvisorPropertyListing[] | null
    rentalListings: AvenAdvisorPropertyListing[] | null
    homeEquityInfo: HomeEquityInfo | null
    didFetchHomeEquityInfo: boolean
    homeAddressLatitude: number | null
    homeAddressLongitude: number | null
    isPollingForPlaidTransactions: boolean
    allListings: CraigslistListingItem[] | null
    notifications: AvenAdvisorPushNotificationCenterItem[] | null
    myLienReportData: AvenAdvisorLienReportData | null
    myLienReportDataLastUpdated: Date | null
    myLienReportDataExists: boolean
    avenAdvisorCreditCardRecommendations: AvenAdvisorCreditCardRecommendationData[] | null
    myLienReportStatus: AvenAdvisorLienReportStatus | null
    didInitializeMethodFi: boolean
    isInitializingMethodFi: boolean
    isPlaidAuthenticatedForSweepstakes: boolean
    isGettingPlaidAuthenticatedForSweepstakes: boolean
    isWebOG: boolean
    freeCoffeeInfo: FreeCoffeeInfoResponse | null
    freeCoffeeSelection: FreeCoffeeType | null | undefined // null indicates that coffee selection has been fetched and that the user has not signed up for any promo.
    borrowingCapacity: AvenAdvisorBorrowingCapacityData | null
    isFetchingMethodFiDependentData: boolean
    isFetchingMethodFiLiabilityAccounts: boolean
    affiliateRewardsLedger: AvenAffiliateRewardsPointsLedgerItem[] | null
    affiliatePointsBalance: number | null
    stripeAccountCanReceivePayouts: boolean | null
    refreshedVehicles: boolean | null
    isRefreshingVehicles: boolean | null
    isFetchingUpgradeOffers: {
        [key in AvenAdvisorUpgradePath]: boolean
    }
    upgradeOffers:
        | {
              [key in AvenAdvisorUpgradePath]?: LoanTermsForFrontend[]
          }
        | null
    upgradeActions: { [key in AvenAdvisorUpgradePath]: AvenAdvisorUpgradeAction | undefined }
    didPullTrendedTradelineInfo: boolean
    seonManager: SeonManager
    isPlaidConnected: boolean | null

    // We have a concept of "pending acknowledgements" because the user may not be authenticated yet
    // (or the advisor lead may not exist) but we still want to record that certain docs were acked.
    // We flush these (aka send the acks to the server) when the user logs in.
    pendingAcknowledgements: {
        docType: DocumentType
        ackMetadata: SignDocumentPayload
        additionalAcknowledgements?: string
    }[]
    fetchedAvenFinancialEmbeddedAdData: boolean | null
    avenFinancialEmbeddedAdData: AvenFinancialEmbeddedAdData[] | null
}

const getDefaultState = (): Omit<DefaultState, 'persistedStore'> => {
    return {
        // The 5 fields below come from the React native frontend while we test
        // whether moving the first 2 onboarding screens to native results in
        // higher conversion.
        phoneNumber: window.onboardingPhoneNumber || '',
        firstName: window.onboardingFirstName || '',
        lastName: window.onboardingLastName || '',
        email: window.onboardingEmail || '',
        otpSid: window.onboardingOtpSid || '',
        ssnLast4: '',
        dateOfBirth: null,
        inviteCode: '',
        otpCode: '',
        addressStreet: '',
        secondaryAddressDesignator: null,
        secondaryAddressUnit: null,
        addressCity: '',
        addressState: '',
        addressPostalCode: '',
        homeOwnershipStatus: window.homeOwnershipStatus || null,
        statedIncome: '',
        statedIncomeHousehold: '',
        idologyQueryId: null,
        idologySsnQueryId: null,
        config: null,
        avenAdvisorLeadId: null,
        isAvenEmployee: null,
        numAttemptsToFetchAvenAdvisorLeadAccountInfo: 0,
        didFetchCreditScoreData: false,
        isFetchingCreditScoreData: false,
        ficoScore: null,
        vantageScore: null,
        dateOfScores: null,
        dateOfNextScores: null,
        needFull9ssn: false,
        isExperianFrozen: false,
        didFetchReferralInfo: false,
        pendingReferralPayoutDollars: null,
        payoutsEnabled: null,
        connectedStripeAccountRemediationMessage: null,
        spinToWinEnabled: null,
        availableSpins: null,
        lastSuccessfulSpinAmount: null,
        didTryInitiateEvenOffers: false,
        didInitiateEvenOffersSuccessfully: false,
        didFetchCreditAndSpecialOffers: false,
        creditOffers: null,
        specialOffers: null,
        canDisplayAvenHelocCardAd: null,
        isUserAlreadyHelocCardApplicant: null,
        creditScoreHistory: null,
        methodFiCreditScoreHistory: null,
        cashAndStockInfo: null,
        cashAndStockAggregatedHistory: null,
        cashAndStockErrorMessage: null,
        cashAndStockRefreshingInstitutionNames: [],
        currentBottomNavigationBarPage: null,
        homeValue: null,
        homeValueLastUpdatedDate: null,
        homeValueNextUpdateAvailableDate: null,
        homeValueHistory: null,
        homeEquityInvestmentEstimate: null,
        methodFiLiabilityAccounts: null,
        liabilityAccountsHistory: null,
        medianSalePriceInNeighborhood: null,
        neighborhoodReportLastUpdateDate: null,
        neighborhoodReportHomeValueHistory: null,
        plaidLinkToken: null,
        vehicleInformation: null,
        canDisplayAutoAd: null,
        energyPriceData: null,
        stockBars: null,
        isPlaidRequiredForPaidReferral: null,
        maritalStatus: null,
        topMerchantsInfo: null,
        subscriptionsInfo: null,
        helocHomeAdStatus: null,
        helocHomeAdUrl: null,
        autoApplicationUrl: null,
        skippedConnectPlaidInterstitial: false,
        sweepstakesStatus: null,
        sweepstakesV2: null,
        sweepstakesRenderType: null,
        creditScoreTips: null,
        shouldShowPaidReferrals: null,
        signUpGoal: null,
        payoutHistory: null,
        payoutDeniedBankNames: null,
        giftCardHistory: null,
        didFetchOwnwellSavingsEstimate: false,
        ownwellSavingsEstimate: null,
        weeklyTransactionsInfo: null,
        recentTransactions: null,
        expiredPlaidItems: null,
        spendingCardData: null,
        notificationDataFromDeeplink: null,
        investmentHoldings: null,
        personaStatus: null,
        personaLink: null,
        numberOfBedrooms: null,
        neighborhoodRentData: null,
        didFetchNeighborhoodRentData: false,
        notablePeopleTrades: null,
        crimeReport: null,
        streakData: null,
        authFlow: null,
        currentTab: null,
        lienInfo: null,
        saleListings: null,
        soldProperties: null,
        homeEquityInfo: null,
        didFetchHomeEquityInfo: false,
        homeAddressLatitude: null,
        homeAddressLongitude: null,
        isPollingForPlaidTransactions: false,
        rentalListings: null,
        allListings: null,
        notifications: null,
        myLienReportData: null,
        myLienReportDataLastUpdated: null,
        myLienReportDataExists: false,
        avenAdvisorCreditCardRecommendations: null,
        myLienReportStatus: null,
        didFetchMethodFiCreditScoreData: false,
        isFetchingMethodFiCreditScoreData: false,
        methodfiCreditScoreData: null,
        didInitializeMethodFi: false,
        isInitializingMethodFi: false,
        isPlaidAuthenticatedForSweepstakes: false,
        isGettingPlaidAuthenticatedForSweepstakes: false,
        isWebOG: false,
        freeCoffeeInfo: null,
        freeCoffeeSelection: undefined,
        borrowingCapacity: null,
        isFetchingMethodFiDependentData: false,
        isFetchingMethodFiLiabilityAccounts: false,
        affiliateRewardsLedger: null,
        affiliatePointsBalance: null,
        stripeAccountCanReceivePayouts: null,
        refreshedVehicles: null,
        isRefreshingVehicles: null,
        upgradeOffers: null,
        isFetchingUpgradeOffers: {
            [AvenAdvisorUpgradePath.UCC]: false,
            [AvenAdvisorUpgradePath.AVEN_CASH]: false,
            [AvenAdvisorUpgradePath.MOAP]: false,
            [AvenAdvisorUpgradePath.MOAP_WITH_DRYRUN]: false,
        },
        upgradeActions: {
            [AvenAdvisorUpgradePath.UCC]: undefined,
            [AvenAdvisorUpgradePath.AVEN_CASH]: undefined,
            [AvenAdvisorUpgradePath.MOAP]: undefined,
            [AvenAdvisorUpgradePath.MOAP_WITH_DRYRUN]: undefined,
        },
        didPullTrendedTradelineInfo: false,
        seonManager: new SeonManager(
            (msg?: string) => {
                logger.info(`getDefaultState -> SeonManager onSuccess: ${msg}`)
            },
            (msg?: string) => {
                logger.info(`getDefaultState -> SeonManager onError: ${msg}`)
            }
        ),
        isPlaidConnected: null,
        pendingAcknowledgements: [],
        fetchedAvenFinancialEmbeddedAdData: false,
        avenFinancialEmbeddedAdData: null,
    }
}

const clearInMemoryState = (state: DefaultState) => {
    state.ssnLast4 = ''
    state.dateOfBirth = null
    state.firstName = ''
    state.lastName = ''
    state.phoneNumber = ''
    state.email = ''
    state.inviteCode = ''
    state.otpSid = ''
    state.otpCode = ''
    state.addressStreet = ''
    state.secondaryAddressDesignator = null
    state.secondaryAddressUnit = null
    state.addressCity = ''
    state.addressState = ''
    state.addressPostalCode = ''
    state.homeOwnershipStatus = null
    state.statedIncome = ''
    state.statedIncomeHousehold = ''
    state.config = null
    state.avenAdvisorLeadId = null
    state.isAvenEmployee = null
    state.numAttemptsToFetchAvenAdvisorLeadAccountInfo = 0
    state.idologyQueryId = null
    state.idologySsnQueryId = null
    state.didFetchCreditScoreData = false
    state.isFetchingCreditScoreData = false
    state.ficoScore = null
    state.vantageScore = null
    state.dateOfScores = null
    state.dateOfNextScores = null
    state.needFull9ssn = false
    state.isExperianFrozen = false
    state.didFetchReferralInfo = false
    state.pendingReferralPayoutDollars = null
    state.payoutsEnabled = null
    state.connectedStripeAccountRemediationMessage = null
    state.spinToWinEnabled = null
    state.availableSpins = null
    state.lastSuccessfulSpinAmount = null
    state.didTryInitiateEvenOffers = false
    state.didInitiateEvenOffersSuccessfully = false
    state.didFetchCreditAndSpecialOffers = false
    state.creditOffers = null
    state.specialOffers = null
    state.canDisplayAvenHelocCardAd = null
    state.isUserAlreadyHelocCardApplicant = null
    state.creditScoreHistory = null
    state.methodFiCreditScoreHistory = null
    state.cashAndStockInfo = null
    state.cashAndStockAggregatedHistory = null
    state.cashAndStockErrorMessage = null
    state.cashAndStockRefreshingInstitutionNames = []
    state.currentBottomNavigationBarPage = null
    state.homeValue = null
    state.homeValueLastUpdatedDate = null
    state.homeValueNextUpdateAvailableDate = null
    state.homeValueHistory = null
    state.homeEquityInvestmentEstimate = null
    state.methodFiLiabilityAccounts = null
    state.liabilityAccountsHistory = null
    state.medianSalePriceInNeighborhood = null
    state.neighborhoodReportLastUpdateDate = null
    state.neighborhoodReportHomeValueHistory = null
    state.plaidLinkToken = null
    state.vehicleInformation = null
    state.canDisplayAutoAd = null
    state.energyPriceData = null
    state.stockBars = null
    state.isPlaidRequiredForPaidReferral = null
    state.topMerchantsInfo = null
    state.subscriptionsInfo = null
    state.helocHomeAdStatus = null
    state.helocHomeAdUrl = null
    state.skippedConnectPlaidInterstitial = false
    state.sweepstakesStatus = null
    state.sweepstakesRenderType = null
    state.creditScoreTips = null
    state.shouldShowPaidReferrals = null
    state.signUpGoal = null
    state.payoutHistory = null
    state.payoutDeniedBankNames = null
    state.giftCardHistory = null
    state.didFetchOwnwellSavingsEstimate = false
    state.ownwellSavingsEstimate = null
    state.investmentHoldings = null
    state.expiredPlaidItems = null
    state.personaStatus = null
    state.personaLink = null
    state.numberOfBedrooms = null
    state.neighborhoodRentData = null
    state.didFetchNeighborhoodRentData = false
    state.notablePeopleTrades = null
    state.crimeReport = null
    state.spendingCardData = null
    state.notificationDataFromDeeplink = null
    state.streakData = null
    state.authFlow = null
    state.currentTab = null
    state.lienInfo = null
    state.saleListings = null
    state.soldProperties = null
    state.homeEquityInfo = null
    state.didFetchHomeEquityInfo = false
    state.homeAddressLatitude = null
    state.homeAddressLongitude = null
    state.isPollingForPlaidTransactions = false
    state.rentalListings = null
    state.allListings = null
    state.notifications = null
    state.myLienReportData = null
    state.myLienReportDataLastUpdated = null
    state.myLienReportDataExists = false
    state.avenAdvisorCreditCardRecommendations = null
    state.myLienReportStatus = null
    state.didFetchMethodFiCreditScoreData = false
    state.isFetchingMethodFiCreditScoreData = false
    state.methodfiCreditScoreData = null
    state.didInitializeMethodFi = false
    state.isInitializingMethodFi = false
    state.isPlaidAuthenticatedForSweepstakes = false
    state.isGettingPlaidAuthenticatedForSweepstakes = false
    state.isWebOG = false
    state.freeCoffeeInfo = null
    state.freeCoffeeSelection = undefined
    state.isFetchingMethodFiDependentData = false
    state.isFetchingMethodFiLiabilityAccounts = false
    state.affiliateRewardsLedger = null
    state.affiliatePointsBalance = null
    state.stripeAccountCanReceivePayouts = null
    state.refreshedVehicles = null
    state.isRefreshingVehicles = null
    state.didPullTrendedTradelineInfo = false
    state.seonManager = new SeonManager(
        (msg?: string) => {
            logger.info(`clearInMemoryState -> SeonManager onSuccess: ${msg}`)
        },
        (msg?: string) => {
            logger.info(`clearInMemoryState -> SeonManager onError: ${msg}`)
        }
    )
    state.isPlaidConnected = null
    state.pendingAcknowledgements = []
    state.fetchedAvenFinancialEmbeddedAdData = false
    state.avenFinancialEmbeddedAdData = null
}

interface UpdateAddressDataPayload {
    addressCity: string
    addressPostalCode: string
    addressState: string
    addressStreet: string
    addressUnitDesignator: string | null
    addressUnit: string | null
}

interface UpdatePiiDataPayload {
    dateOfBirth: Date
    firstName: string
    lastName: string
    addressData: UpdateAddressDataPayload
}

interface UpdateOtpSidPayload {
    otpSid: string
}

const persistedData = createPersistedState({
    paths: ['persistedStore'],
    storage: window.sessionStorage,
})

export default new Vuex.Store({
    state: getDefaultState() as DefaultState,
    modules: { persistedStore },
    getters: {
        getField,
        isUserAvenEmployee(state): boolean {
            return !!state.isAvenEmployee
        },
        // Do not cache the result, return a function
        experimentsOverrides() {
            return () => JSON.parse(appSessionStorage.getItem(localStorageKey.experimentsOverrides) ?? `{}`)
        },
        inProvePreFillTest(state, getters) {
            const experimentsOverrides = getters.experimentsOverrides()
            return !!(experimentsOverrides['advisorApp'] && experimentsOverrides['advisorApp'].includes('provePreFill'))
        },
        helocCardInfo() {
            return JSON.parse(appSessionStorage.getItem(localStorageKey.helocCardInfo) ?? `{}`)
        },
        autoCardInfo() {
            return JSON.parse(appSessionStorage.getItem(localStorageKey.autoCardInfo) ?? `{}`)
        },
        minHelocCardApr(state, getters) {
            const minApr = getters.helocCardInfo.minApr
            assert(minApr, `minApr doesn't exist in appSessionStorage`)
            return minApr
        },
        maxHelocCardApr(state, getters) {
            const maxApr = getters.helocCardInfo.maxApr
            assert(maxApr, `maxApr doesn't exist in appSessionStorage`)
            return maxApr
        },
        globalMaxHelocCardApr(state, getters) {
            const globalMaxApr = getters.helocCardInfo.globalMaxApr
            assert(globalMaxApr, `globalMaxApr doesn't exist in appSessionStorage`)
            return globalMaxApr
        },
        maxHelocCardLineSize(state, getters) {
            const maxLineSize = getters.helocCardInfo.maxLineSize
            assert(maxLineSize, `maxLineSize doesn't exist in appSessionStorage`)
            return maxLineSize
        },
        helocCardCashbackPercentage(state, getters) {
            const cashbackPercent = getters.helocCardInfo.cashbackPercent
            assert(cashbackPercent, `cashbackPercent doesn't exist in appSessionStorage`)
            return cashbackPercent
        },
        helocCardRateIndexFullName(state, getters) {
            const rateIndexFullName = getters.helocCardInfo.rateIndexFullName
            assert(rateIndexFullName, `rateIndexFullName doesn't exist in appSessionStorage`)
            return rateIndexFullName
        },
        helocCardRateIndexShortName(state, getters) {
            const rateIndexShortName = getters.helocCardInfo.rateIndexShortName
            assert(rateIndexShortName, `rateIndexShortName doesn't exist in appSessionStorage`)
            return rateIndexShortName
        },
        helocCardRateIndexEffectiveDate(state, getters) {
            const rateIndexEffectiveDate = getters.helocCardInfo.rateIndexEffectiveDate
            assert(rateIndexEffectiveDate, `rateIndexEffectiveDate doesn't exist in appSessionStorage`)
            return rateIndexEffectiveDate
        },
        helocCardRateValuePercentage(state, getters) {
            const rateValuePercentage = getters.helocCardInfo.rateValuePercentage
            assert(rateValuePercentage, `rateValuePercentage doesn't exist in appSessionStorage`)
            return rateValuePercentage
        },
        helocCardCashOutFee(state, getters) {
            const cashOutFee = getters.helocCardInfo.cashOutFee
            assert(cashOutFee, `cashOutFee doesn't exist in appSessionStorage`)
            return cashOutFee
        },
        shouldDisplayHomeEquityInvestment(state, getters) {
            return state.homeEquityInvestmentEstimate?.applicationUrl && state.homeEquityInvestmentEstimate?.maxEstimateOfferAmountDollars && !getters.shouldDisplayHelocCardAd
        },
        homeEquityInvestmentApplicationUrl(state) {
            return state.homeEquityInvestmentEstimate?.applicationUrl
        },
        homeEquityInvestmentMaxEstimateOfferAmountDollars(state) {
            return state.homeEquityInvestmentEstimate?.maxEstimateOfferAmountDollars
        },
        hasOpenedAppAtLeastOnceBefore(state) {
            // Todo If we can't get config for some reason, we'll assume this is *not* the user's
            //  first time in the app. We can let this decision simmer and change this later on
            //  if needed.
            return state.config?.hasOpenedAppAtLeastOnceBefore ?? true
        },
        didAcceptMethodFiTos(state) {
            return !!state.config?.didAcceptMethodFiTos
        },
        didAcceptCreditOfferDisclosure(state) {
            return !!state.config?.didAcceptCreditOfferDisclosure
        },
        shouldDisplayHelocCardAd(state) {
            return state.canDisplayAvenHelocCardAd
        },
        shouldShowPaidReferralsOption(state) {
            return state.shouldShowPaidReferrals
        },
        shouldShowReferralBalancePayoutBanner(state) {
            return state.pendingReferralPayoutDollars
        },
        shouldShowSpinToWin(state) {
            return state.spinToWinEnabled && featureFlagConfig.paidReferrals
        },
        getVehicleInformation(state) {
            return state.vehicleInformation
        },
        didAcceptEsignConsent(state) {
            return !!state.config?.didAcceptEsignConsent
        },
        getTopMerchantsInfo(state) {
            return state.topMerchantsInfo
        },
        getSkippedConnectPlaidInterstitial(state) {
            if (appLocalStorage.getItem(localStorageKey.skippedConnectPlaidInterstitial) === 'true') return true
            return state.skippedConnectPlaidInterstitial
        },
        getInviteCode(state) {
            return state.inviteCode
        },
        getSweepstakesStatus(state) {
            return state.sweepstakesStatus
        },
        getSweepstakesV2(state) {
            return state.sweepstakesV2
        },
    },
    mutations: {
        updateField,
        updateAddressData(state, payload: UpdateAddressDataPayload) {
            state.addressStreet = payload.addressStreet
            state.secondaryAddressDesignator = payload.addressUnitDesignator
            state.secondaryAddressUnit = payload.addressUnit
            state.addressCity = payload.addressCity
            state.addressState = payload.addressState
            state.addressPostalCode = payload.addressPostalCode
        },
        updatePiiInformation(state, payload: UpdatePiiDataPayload) {
            state.dateOfBirth = payload.dateOfBirth
            state.firstName = payload.firstName
            state.lastName = payload.lastName
            state.addressStreet = payload.addressData.addressStreet
            state.addressCity = payload.addressData.addressCity
            state.addressState = payload.addressData.addressState
            state.addressPostalCode = payload.addressData.addressPostalCode
        },
        updateOtpSid(state, payload: UpdateOtpSidPayload) {
            state.otpSid = payload.otpSid
        },
        updateConfig(state, payload: AvenAdvisorConfig) {
            state.config = payload
        },
        updateAvenAdvisorLeadAccountInfo(state, payload: AvenAdvisorLeadAccountInfoPayload) {
            state.avenAdvisorLeadId = payload.avenAdvisorLeadId
            state.isAvenEmployee = payload.isAvenEmployee
            state.firstName = payload.firstName
            state.lastName = payload.lastName
            state.phoneNumber = payload.phoneNumber
            state.maritalStatus = payload.maritalStatus
            state.email = payload.email
            state.signUpGoal = payload.signUpGoal
            state.addressPostalCode = payload.addressPostalCode
            state.addressStreet = payload.addressStreet ?? ''
            state.secondaryAddressUnit = payload.secondaryAddressUnit
            state.secondaryAddressDesignator = payload.secondaryAddressDesignator
            state.addressCity = payload.addressCity ?? ''
            state.addressState = payload.addressState ?? ''
            state.numberOfBedrooms = payload.numberOfBedrooms
            state.statedIncome = payload.statedIncome ? String(payload.statedIncome) : ''
            state.homeAddressLatitude = payload.homeAddressLatitude
            state.homeAddressLongitude = payload.homeAddressLongitude
            state.stripeAccountCanReceivePayouts = payload.stripeAccountCanReceivePayouts ?? false
        },
        incrementNumAttemptsToFetchAvenAdvisorLeadAccountInfo(state) {
            state.numAttemptsToFetchAvenAdvisorLeadAccountInfo = state.numAttemptsToFetchAvenAdvisorLeadAccountInfo + 1
        },
        clearPhoneNumber(state) {
            state.phoneNumber = null
        },
        logout(state) {
            saveAccessTokenOnNative(null)
            state.persistedStore.sessionId = null
            state.persistedStore.jwtTokens = null
            state.persistedStore.consentToCreditOfferDisclosuresHash = null
            state.persistedStore.consentToMethodFiTosGitHash = null
            state.persistedStore.consentToExperianBoostDataSharingHash = null
            clearInMemoryState(state)
        },
        updateMethodFiCreditScoreData(state, payload: { creditScoreData: MethodFiCreditScoreData }) {
            state.methodfiCreditScoreData = payload.creditScoreData
            state.isFetchingMethodFiCreditScoreData = false
            state.didFetchMethodFiCreditScoreData = true
        },
        updateCreditScoreDataForToday(
            state: DefaultState,
            payload: {
                ficoScore: number | undefined
                vantageScore: number | undefined
                dateOfScores: Date | undefined
                dateOfNextScores: Date | undefined
                needFull9ssn: boolean | undefined
                isExperianFrozen: boolean | undefined
            }
        ) {
            state.ficoScore = payload.ficoScore ?? state.ficoScore
            state.vantageScore = payload.vantageScore ?? state.vantageScore
            state.dateOfScores = payload.dateOfScores ?? state.dateOfScores
            state.dateOfNextScores = payload.dateOfNextScores ?? state.dateOfNextScores
            state.needFull9ssn = payload.needFull9ssn ?? state.needFull9ssn
            state.isExperianFrozen = payload.isExperianFrozen ?? state.isExperianFrozen
        },
        updateCreditScoreHistoryForToday(
            state: DefaultState,
            payload: {
                history: CreditScoreDataPoint[] | undefined
            }
        ) {
            state.creditScoreHistory = payload.history ?? state.creditScoreHistory
        },
        updateMethodFiCreditScoreHistoryForToday(
            state: DefaultState,
            payload: {
                history: MethodFiCreditScoreDataPoint[] | undefined
            }
        ) {
            state.methodFiCreditScoreHistory = payload.history ?? state.methodFiCreditScoreHistory
        },
        setMethodFiCreditScoreDataLoading(state: DefaultState, payload: { isLoading: boolean }) {
            state.isFetchingMethodFiCreditScoreData = payload.isLoading
        },
        setMethodFiCreditScoreDataLoaded(state: DefaultState) {
            state.isFetchingMethodFiCreditScoreData = false
        },
        setIsFetchingMethodFiDependentData(state: DefaultState, payload: { isFetching: boolean }) {
            state.isFetchingMethodFiDependentData = payload.isFetching
        },
        setCreditScoreDataLoaded(state: DefaultState) {
            state.didFetchCreditScoreData = true
        },
        setCreditScoreDataLoading(state: DefaultState, payload: { isLoading: boolean }) {
            state.isFetchingCreditScoreData = payload.isLoading
        },
        setReferralInfo(
            state: DefaultState,
            payload: {
                pendingReferralPayoutDollars: number | null
                payoutsEnabled: boolean | null
                connectedStripeAccountRemediationMessage: boolean | null
                spinToWinEnabled: boolean | null
                availableSpins: number | null
                lastSuccessfulSpinAmount: number | null
            }
        ) {
            state.didFetchReferralInfo = true
            state.pendingReferralPayoutDollars = payload.pendingReferralPayoutDollars
            state.payoutsEnabled = payload.payoutsEnabled
            state.connectedStripeAccountRemediationMessage = payload.connectedStripeAccountRemediationMessage
            state.spinToWinEnabled = payload.spinToWinEnabled
            state.availableSpins = payload.availableSpins
            state.lastSuccessfulSpinAmount = payload.lastSuccessfulSpinAmount
        },
        handleSpinTheWheelStarted(state: DefaultState) {
            state.lastSuccessfulSpinAmount = null
        },
        handleSuccessfulSpin(state: DefaultState, payload: { dollarAmount: number }) {
            state.availableSpins = state.availableSpins ? state.availableSpins - 1 : 0
            state.lastSuccessfulSpinAmount = payload.dollarAmount
        },
        setEvenOfferInitState(state: DefaultState, payload: { didInitiateEvenOffersSuccessfully: boolean }) {
            state.didTryInitiateEvenOffers = true
            state.didInitiateEvenOffersSuccessfully = payload.didInitiateEvenOffersSuccessfully
        },
        setEvenOffers(state: DefaultState, payload: { creditOffers: LoanOffer[]; specialOffers: SpecialOffer[] }) {
            state.didFetchCreditAndSpecialOffers = true
            state.creditOffers = payload.creditOffers
            state.specialOffers = payload.specialOffers
        },
        setAvenAdsDisplayInfo(
            state: DefaultState,
            payload: { canDisplayAvenHelocCardAd: boolean; isUserAlreadyHelocCardApplicant: boolean; canDisplayAutoAd: boolean; linkToHelocCardPreQualOffer: string | null }
        ) {
            state.canDisplayAvenHelocCardAd = payload.canDisplayAvenHelocCardAd
            state.isUserAlreadyHelocCardApplicant = payload.isUserAlreadyHelocCardApplicant
            state.canDisplayAutoAd = payload.canDisplayAutoAd
            state.helocHomeAdUrl = payload.linkToHelocCardPreQualOffer
        },
        setCashAndStock(state: DefaultState, payload: AvenAdvisorNetWorthResponse) {
            state.cashAndStockInfo = payload.institutions.map((item) => ({
                institutionId: item.institutionId,
                institutionName: item.institutionName,
                requiredPlaidAction: item.requiredPlaidAction,
                cashAndStockLastUpdatedDate: item.lastUpdatedDate,
                cashAndStockTotalAssets: item.totalAssets,
                cashAndStockAccountBalances: item.accounts,
                nextCashAndStockUpdateAvailableDate: item.nextUpdateAvailableDate,
                lowBalanceAlertThreshold: item.lowBalanceAlertThreshold,
                recentInflows: item.recentInflows,
                history: item.history ?? null,
            }))
            state.cashAndStockAggregatedHistory = payload.aggregatedHistory
        },
        setInviteCode(state: DefaultState, payload: { inviteCode: string }) {
            state.inviteCode = payload.inviteCode
        },
        setInvestmentHoldings(state: DefaultState, payload: AvenAdvisorInvestmentHoldingsResponse) {
            state.investmentHoldings = payload
        },
        setCashAndStockRefreshingInstitutionNames(state: DefaultState, payload: { institutionName: string; cashAndStockIsRefreshing: boolean }) {
            if (payload.cashAndStockIsRefreshing) {
                state.cashAndStockRefreshingInstitutionNames.push(payload.institutionName)
            } else {
                state.cashAndStockRefreshingInstitutionNames = state.cashAndStockRefreshingInstitutionNames.filter((institution) => institution !== payload.institutionName)
            }
        },
        setCashAndStockErrorMessage(state: DefaultState, payload: { institutionName: string; cashAndStockErrorMessage: string | null }) {
            if (!state.cashAndStockErrorMessage) {
                state.cashAndStockErrorMessage = {}
            }

            state.cashAndStockErrorMessage[payload.institutionName] = payload.cashAndStockErrorMessage
        },
        setBottomNavigationPage(state: DefaultState, payload: { currentBottomNavigationBarPage: BottomNavigationPages }) {
            state.currentBottomNavigationBarPage = payload.currentBottomNavigationBarPage
        },
        setHomeValue(state: DefaultState, payload: AvenAdvisorHomeValueResponse | null) {
            if (payload) {
                state.homeValue = payload.homeValue
                state.homeValueLastUpdatedDate = payload.lastUpdatedDate
                state.homeValueNextUpdateAvailableDate = payload.nextUpdateAvailableDate
                state.homeValueHistory = payload.history
            } else {
                state.homeValue = null
                state.homeValueLastUpdatedDate = null
                state.homeValueNextUpdateAvailableDate = null
                state.homeValueHistory = null
            }
        },
        setPhoneNumber(state: DefaultState, phoneNumber: string) {
            state.phoneNumber = phoneNumber
        },
        setVehicleInformation(state: DefaultState, payload: VehicleInformation) {
            state.vehicleInformation = payload
        },
        setHomeOwnershipStatus(state: DefaultState, payload: GetHomeOwnershipStatusResponse) {
            state.homeOwnershipStatus = payload.homeOwnershipStatus
        },
        setHomeEquityInvestmentEstimate(state: DefaultState, payload: HomeEquityInvestmentEstimate) {
            state.homeEquityInvestmentEstimate = payload
        },
        setLiabilityAccounts(state: DefaultState, payload: { liabilityAccounts: AdvisorLiabilityAccountInformation[]; liabilityAccountsHistory: AvenAdvisorLiabilitySnapshot[] }) {
            state.methodFiLiabilityAccounts = payload.liabilityAccounts
            state.liabilityAccountsHistory = payload.liabilityAccountsHistory
        },
        setDidAcceptMethodfiTos(state: DefaultState, payload: { didAccept: true }) {
            if (state.config) {
                state.config.didAcceptMethodFiTos = payload.didAccept
            } else {
                logger.fatal(`Attempting to set methodfi tos accept to true but config does not exist!`)
            }
        },
        setDidAcceptCreditOfferDisclosure(state: DefaultState, payload: { didAccept: true }) {
            if (state.config) {
                state.config.didAcceptCreditOfferDisclosure = payload.didAccept
            } else {
                logger.fatal(`Attempting to set methodfi tos accept to true but config does not exist!`)
            }
        },
        setDidAcceptEsign(state: DefaultState, payload: { didAccept: true }) {
            if (state.config) {
                state.config.didAcceptEsignConsent = payload.didAccept
            } else {
                logger.fatal(`Attempting to set didAcceptEsignConsent true but config does not exist!`)
            }
        },
        setDidAcceptContactUpload(state: DefaultState, payload: { didAccept: true }) {
            if (state.config) {
                state.config.didConsentToContactUpload = payload.didAccept
            } else {
                logger.fatal(`Attempting to set didConsentToContactUpload true but config does not exist!`)
            }
        },
        setNeighborhoodData(state: DefaultState, payload: NeighborhoodDataResponse) {
            state.medianSalePriceInNeighborhood = payload.medianSalePrice
            state.neighborhoodReportLastUpdateDate = payload.lastUpdatedAt
            state.neighborhoodReportHomeValueHistory = payload.history
        },
        setPlaidLinkToken(state: DefaultState, linkToken: string | null) {
            state.plaidLinkToken = linkToken
        },
        setEnergyPrices(state: DefaultState, payload: AvenAdvisorEnergyPrices) {
            state.energyPriceData = payload
        },
        setStockBars(state: DefaultState, payload: Record<string, AlpacaEquityWrapper>) {
            state.stockBars = payload
        },
        setIsPlaidRequiredForPaidReferral(state: DefaultState, payload: { isPlaidRequiredForPaidReferral: boolean }) {
            state.isPlaidRequiredForPaidReferral = payload.isPlaidRequiredForPaidReferral
        },
        setTopMerchantsInfo(state: DefaultState, payload: GetTopMerchantsResponse | null) {
            state.topMerchantsInfo = payload
        },
        setSubscriptionsInfo(state: DefaultState, payload: GetSubscriptionsResponse | null) {
            state.subscriptionsInfo = payload
        },
        setWeeklyTransactionsInfo(state: DefaultState, payload: GetWeeklyTransactionsResponse | null) {
            state.weeklyTransactionsInfo = payload
        },
        setRecentTransactions(state: DefaultState, payload: GetRecentTransactionsResponse | null) {
            state.recentTransactions = payload
        },
        appendRecentTransactions(state: DefaultState, payload: GetRecentTransactionsResponse | null) {
            if (state.recentTransactions && payload && state.recentTransactions.transactions[0].transactionId !== payload.transactions[0].transactionId) {
                // It's possible that multiple callers tried the same fetch, and waiting for the same network call to finish, then append multiple times with the same data. Therefore we need to check if the data is the same before appending.
                state.recentTransactions.transactions = state.recentTransactions.transactions.concat(payload.transactions)
            } else if (payload) {
                state.recentTransactions = payload
            }
        },
        setSpendingCardData(state: DefaultState, payload: GetSpendingCardDataResponse | null) {
            state.spendingCardData = payload
        },
        setNotificationDataFromDeeplink(state: DefaultState, payload: AvenAdvisorPushNotificationCenterItem | null) {
            state.notificationDataFromDeeplink = payload
        },
        setMaritalStatus(state: DefaultState, payload: { maritalStatus: MaritalStatus }) {
            state.maritalStatus = payload.maritalStatus
        },
        setPersonaStatus(state: DefaultState, payload: { personaStatus: PersonaStatus }) {
            state.personaStatus = payload.personaStatus
        },
        setPersonaLink(state: DefaultState, payload: { personaLink: string }) {
            state.personaLink = payload.personaLink
        },
        setHelocHomeAdStatus(state: DefaultState, payload: { helocHomeAdStatus: HelocHomeAdStatus }) {
            state.helocHomeAdStatus = payload.helocHomeAdStatus
        },
        setHelocHomeAdUrl(state: DefaultState, payload: { helocHomeAdUrl: string }) {
            state.helocHomeAdUrl = payload.helocHomeAdUrl
        },
        setAutoApplicationUrl(state: DefaultState, payload: { autoApplicationUrl: string }) {
            state.autoApplicationUrl = payload.autoApplicationUrl
        },
        setSkippedConnectPlaidInterstitial(state: DefaultState) {
            state.skippedConnectPlaidInterstitial = true
            appLocalStorage.setItem(localStorageKey.skippedConnectPlaidInterstitial, 'true')
        },
        setSweepstakeStatus(state: DefaultState, payload: GetSweepstakesStatusResponse) {
            state.sweepstakesStatus = payload
        },
        setSweepstakeV2(state: DefaultState, payload: SweepstakesV2) {
            state.sweepstakesV2 = payload
        },
        setTweetButtonClicked(state: DefaultState, payload: { tweetButtonClicked: boolean }) {
            if (state.sweepstakesStatus && 'tweetButtonClicked' in state.sweepstakesStatus) {
                state.sweepstakesStatus.tweetButtonClicked = payload.tweetButtonClicked
            }
        },
        setSweepstakesRenderType(state: DefaultState, type: SweepstakesRenderType) {
            state.sweepstakesRenderType = type
        },
        setPayoutHistory(state: DefaultState, payload: { payouts: PayoutHistoryItem[] }) {
            state.payoutHistory = payload.payouts
        },
        setPayoutDeniedBankNames(state: DefaultState, payload: { payoutDeniedBankNames: string[] }) {
            state.payoutDeniedBankNames = payload.payoutDeniedBankNames
        },
        setGiftCardHistory(state: DefaultState, issuedGiftCardInfo: IssuedGiftCardInfo[]) {
            state.giftCardHistory = issuedGiftCardInfo
        },
        setCreditScoreTips(state: DefaultState, payload: { tipTitle: string; tipBody: string }[]) {
            state.creditScoreTips = payload
        },
        setShouldShowPaidReferrals(state: DefaultState, shouldShowPaidReferrals: boolean) {
            state.shouldShowPaidReferrals = shouldShowPaidReferrals
        },
        setSignUpGoal(state: DefaultState, signUpGoal: SignUpGoal[]) {
            state.signUpGoal = signUpGoal
        },
        setOwnwellSavingsEstimate(state: DefaultState, payload: { savingsEstimate: number }) {
            state.didFetchOwnwellSavingsEstimate = true
            state.ownwellSavingsEstimate = payload.savingsEstimate
        },
        setNeighborhoodRentData(state: DefaultState, payload: AvenAdvisorRentalMarketData) {
            state.neighborhoodRentData = payload
            state.didFetchNeighborhoodRentData = true
        },
        setNotableTrades(state: DefaultState, payload: { notableTrades: AvenAdvisorNotablePersonTrades[] }) {
            state.notablePeopleTrades = payload.notableTrades
        },
        setCrimeReport(state: DefaultState, payload: NeighborhoodCrimeData) {
            state.crimeReport = payload
        },
        setStreakData(state: DefaultState, payload: StreakResponse) {
            state.streakData = payload
        },
        setAuthFlow(state: DefaultState, payload: { authFlow: AuthFlow }) {
            state.authFlow = payload.authFlow
        },
        setLienInfo(state: DefaultState, payload: LienInfo) {
            state.lienInfo = payload
        },
        setSaleListings(state: DefaultState, payload: AvenAdvisorSaleListingResponse) {
            state.saleListings = payload.saleListings
            state.soldProperties = payload.soldProperties
        },
        setRentalListings(state: DefaultState, payload: AvenAdvisorRentalListingResponse) {
            state.rentalListings = payload.rentalListings
        },
        setHomeEquityInfo(state: DefaultState, payload: HomeEquityInfo) {
            state.homeEquityInfo = payload
            state.didFetchHomeEquityInfo = true
        },
        setExpiredPlaidItems(state: DefaultState, payload: AvenAdvisorExpiredPlaidItemsResponse) {
            state.expiredPlaidItems = payload
        },
        maybeRemoveInstitutionIdFromExpiredPlaidItems(state: DefaultState, payload: { institutionId: string }) {
            if (!state.expiredPlaidItems) {
                return
            }
            const expiredItems = state.expiredPlaidItems.expiredItems.filter((item) => item.institutionId !== payload.institutionId)
            state.expiredPlaidItems = { expiredItems }
        },
        setIsPollingForPlaidTransactions(state: DefaultState, payload: { isPolling: boolean }) {
            state.isPollingForPlaidTransactions = payload.isPolling
        },
        setCraigslistListings(state: DefaultState, payload: { allListings: CraigslistListingItem[] }) {
            state.allListings = payload.allListings
        },
        setNotifications(state: DefaultState, payload: { pushNotifications: AvenAdvisorPushNotificationCenterItem[] }) {
            state.notifications = payload.pushNotifications
        },
        markNotificationClicked(state: DefaultState, payload: { pushNotificationId: number }) {
            if (state.notifications) {
                const notificationIndex = state.notifications.findIndex((notification) => notification.id === payload.pushNotificationId)
                if (notificationIndex !== -1) {
                    state.notifications[notificationIndex].clickTime = new Date()
                }
            }
        },
        setMyLienReport(state: DefaultState, payload: FetchAvenAdvisorLienReportResponse) {
            state.myLienReportData = payload.lienReport
            state.myLienReportDataLastUpdated = new Date()
            state.myLienReportStatus = payload.status
        },
        setAvenAdvisorCreditCardRecommendations(state: DefaultState, payload: { recommendations: AvenAdvisorCreditCardRecommendationData[] }) {
            state.avenAdvisorCreditCardRecommendations = payload.recommendations
        },
        setIsInitializingMethodFi(state: DefaultState, payload: { isInitializing: boolean }) {
            state.isInitializingMethodFi = payload.isInitializing
        },
        setDidInitializeMethodFi(state: DefaultState, payload: { didInitialize: boolean }) {
            state.didInitializeMethodFi = payload.didInitialize
        },
        setIsGettingPlaidAuthenticatedForSweepstakes(state: DefaultState, payload: { isCompleting: boolean }) {
            state.isGettingPlaidAuthenticatedForSweepstakes = payload.isCompleting
        },
        setIsPlaidAuthenticatedForSweepstakes(state: DefaultState, payload: { isPlaidAuthenticatedForSweepstakes: boolean }) {
            state.isPlaidAuthenticatedForSweepstakes = payload.isPlaidAuthenticatedForSweepstakes
        },
        setIsWebOG(state: DefaultState, payload: { isWebOG: boolean }) {
            logger.info(`Setting isWebOG to ${payload.isWebOG}`)
            state.isWebOG = payload.isWebOG
        },
        setFreeCoffeeInfo(state: DefaultState, payload: FreeCoffeeInfoResponse) {
            state.freeCoffeeInfo = payload
        },
        setFreeCoffeeSelection(state: DefaultState, payload: { selectedFreeCoffeeType: FreeCoffeeType | null }) {
            state.freeCoffeeSelection = payload.selectedFreeCoffeeType
        },
        setBorrowingCapacity(state: DefaultState, payload: { borrowingCapacity: AvenAdvisorBorrowingCapacityData }) {
            state.borrowingCapacity = payload.borrowingCapacity
        },
        setAffiliateRewardsLedger(state: DefaultState, payload: { ledger: AvenAffiliateRewardsPointsLedgerItem[] }) {
            state.affiliateRewardsLedger = payload.ledger
        },
        setAffiliatePointsBalance(state: DefaultState, payload: { balance: number }) {
            state.affiliatePointsBalance = payload.balance
        },
        setIsRefreshingVehicles(state: DefaultState, payload: { isRefreshing: boolean }) {
            state.isRefreshingVehicles = payload.isRefreshing
        },
        setRefreshedVehicles(state: DefaultState, payload: { refreshedVehicles: boolean }) {
            state.refreshedVehicles = payload.refreshedVehicles
        },
        setUpgradeOffers(state: DefaultState, payload: { upgradeOffers: { [key in AvenAdvisorUpgradePath]?: LoanTermsForFrontend[] } }) {
            logger.info(`Setting upgrade offers: ${inspect(payload.upgradeOffers)}`)
            state.upgradeOffers = payload.upgradeOffers
        },
        setUpgradeOfferForPath(state: DefaultState, payload: { upgradeOffer: LoanTermsForFrontend[]; path: AvenAdvisorUpgradePath }) {
            logger.info(`Setting upgrade offer for path: ${payload.path}`)
            // clone the upgrade offers object
            const upgradeOffers = { ...state.upgradeOffers }
            upgradeOffers[payload.path] = payload.upgradeOffer
            state.upgradeOffers = upgradeOffers
        },
        setIsFetchingMethodFiLiabilityAccounts(state: DefaultState, payload: { isFetching: boolean }) {
            state.isFetchingMethodFiLiabilityAccounts = payload.isFetching
        },
        setIsFetchingUpgradeOffersForPath(state: DefaultState, payload: { isFetching: boolean; path: AvenAdvisorUpgradePath }) {
            logger.info(`Setting isFetchingUpgradeOffers for path: ${payload.path} to ${payload.isFetching}`)
            // clone the isFetchingUpgradeOffers object
            const isFetchingUpgradeOffers = { ...state.isFetchingUpgradeOffers }
            isFetchingUpgradeOffers[payload.path] = payload.isFetching
            state.isFetchingUpgradeOffers = isFetchingUpgradeOffers
        },
        setUpgradeActions(state: DefaultState, payload: { upgradeActions: { [key in AvenAdvisorUpgradePath]: AvenAdvisorUpgradeAction | undefined } }) {
            state.upgradeActions = payload.upgradeActions
        },
        setSeonManager(state: DefaultState, payload: { seonManager: SeonManager }) {
            state.seonManager = payload.seonManager
        },
        setIsPlaidConnected(state: DefaultState, payload: { isPlaidConnected: boolean }) {
            state.isPlaidConnected = payload.isPlaidConnected
        },
        addPendingAcknowledgement(state: DefaultState, payload: { docType: DocumentType; ackMetadata: SignDocumentPayload; additionalAcknowledgements?: string }) {
            state.pendingAcknowledgements = [...state.pendingAcknowledgements, payload]
        },
        clearPendingAcknowledgements(state: DefaultState) {
            state.pendingAcknowledgements = []
        },
        setFetchedAvenFinancialEmbeddedAdData(state: DefaultState, payload: { fetchedAvenFinancialEmbeddedAdData: boolean }) {
            state.fetchedAvenFinancialEmbeddedAdData = payload.fetchedAvenFinancialEmbeddedAdData
        },
        setAvenFinancialEmbeddedAdData(state: DefaultState, payload: { avenFinancialEmbeddedAdData: AvenFinancialEmbeddedAdData[] }) {
            state.avenFinancialEmbeddedAdData = payload.avenFinancialEmbeddedAdData
        },
    },
    actions: {
        async fetchDataForAppLoad({ dispatch, commit }) {
            // initialize seon
            const seonManager = new SeonManager(
                (msg?: string) => {
                    logger.info(`advisor_seon_success: ${msg}`)
                },
                (msg?: string) => {
                    logger.info(`advisor_seon_error: ${msg}`)
                }
            )
            commit('setSeonManager', { seonManager })

            await Promise.allSettled([
                dispatch('fetchMethodFiDependentData'),
                dispatch('maybeFetchConfig'),
                dispatch('maybeFetchAvenAdvisorAccountInfo'),
                dispatch('fetchPersonaStatus'),
                dispatch('getIsPlaidRequiredForPaidReferral'),
                dispatch('maybeFetchReferralInfo', { skipCache: false }),
                dispatch('maybeFetchInviteCode'),
                dispatch('fetchSweepstakesStatus'),
                dispatch('getShouldShowPaidReferrals'),
                dispatch('fetchStreakData'),
                dispatch('maybeFetchAffiliateRewardsLedger'),
                dispatch('maybeFetchIsPlaidConnected'),
            ])

            // Create third-party lead for Credit Builder affiliates
            await dispatch('fetchAvenFinancialEmbeddedAdData')
        },
        async login({ dispatch, state }) {
            logger.info('called login()')
            await dispatch('flushPendingAcknowledgements')

            // Upon login we'll clear all in-memory state just to make sure we're not
            // exposing anything we shouldn't be. We'll grab it again from the API if needed.
            clearInMemoryState(state)
            await dispatch('tryGetConsentToShareDataWithExperianBoost')
        },
        async fetchAvenFinancialEmbeddedAdData({ state, commit }) {
            if (state.fetchedAvenFinancialEmbeddedAdData) {
                logger.info('Already fetched Aven Financial embedded ad data, skipping')
                return
            }

            if (state.persistedStore.jwtTokens && !state.fetchedAvenFinancialEmbeddedAdData && state.isAvenEmployee) {
                logger.info('Fetching Aven Financial embedded ad data')

                commit('setFetchedAvenFinancialEmbeddedAdData', { fetchedAvenFinancialEmbeddedAdData: true })
                try {
                    const response = await getEmbeddedAdDataFromAvenFinancial()
                    logger.info(`Fetched Aven Financial embedded ad data: ${inspect(response.data.payload)}`)
                    commit('setAvenFinancialEmbeddedAdData', { avenFinancialEmbeddedAdData: response.data.payload.embeddedAdData })
                } catch (error) {
                    logger.error('Error fetching Aven Financial embedded ad data', error)
                }
            } else {
                logger.info('Not creating third party lead because user is not logged in or lead has already been created')
            }
        },
        async ackDocument({ state, commit }, { docType, ackMetadata, additionalAcknowledgements }): Promise<{ success: boolean; error?: string }> {
            logger.info(`Acking document: ${docType}`)
            if (state.persistedStore.jwtTokens) {
                logger.info(`User is logged in, sending ack to server`)
                const response = await ackLegalDocument(docType, ackMetadata, additionalAcknowledgements)
                if (!response.data.success) {
                    logger.fatal(`Error acknowledging document from Advisor frontend: ${docType}`, response.data.error)
                    return { success: false, error: response.data.error }
                }
                return { success: true }
            } else {
                logger.info(`User is not logged in, adding pending acknowledgement`)
                commit('addPendingAcknowledgement', { docType, ackMetadata, additionalAcknowledgements })
                return { success: true }
            }
        },
        async flushPendingAcknowledgements({ state, commit }) {
            const pendingAcknowledgements = [...state.pendingAcknowledgements]
            if (pendingAcknowledgements.length === 0) {
                logger.info(`No pending acknowledgements to send to server`)
                return
            }

            // clear first to minimize chance of race condition
            commit('clearPendingAcknowledgements')

            logger.info(`Flushing ${pendingAcknowledgements.length} pending acknowledgements`)

            // send each ack to the server
            await Promise.all(
                pendingAcknowledgements.map(async (pendingAcknowledgement) => {
                    logger.info(`Sending pending acknowledgement to server: ${inspect(pendingAcknowledgement)}`)
                    const response = await ackLegalDocument(pendingAcknowledgement.docType, pendingAcknowledgement.ackMetadata, pendingAcknowledgement.additionalAcknowledgements)
                    if (!response.data.success) {
                        logger.fatal(`Error acknowledging document from Advisor frontend: ${pendingAcknowledgement.docType}`, response.data.error)
                    }
                })
            )
        },
        async maybeFetchConfig({ state, dispatch }) {
            if (state.config) {
                logger.log(`Already have Aven Advisor config so not fetching again`)
                return
            }
            await dispatch('fetchConfig')
        },
        async fetchConfig({ commit }) {
            try {
                const response = await getConfig()
                if (response.data?.success && response.data?.payload?.config) {
                    commit('updateConfig', response.data.payload.config)
                } else {
                    logger.fatal(`Problem fetching Aven Advisor lead config`)
                }
            } catch (e) {
                logger.fatal('Error trying to fetch Aven Advisor lead config', e)
            }
        },
        async acceptMethodfiTos({ state, commit, dispatch }) {
            if (state.config) {
                commit('setDidAcceptMethodfiTos', { didAccept: true })
            } else {
                await dispatch('fetchConfig')
                await commit('setDidAcceptMethodfiTos', { didAccept: true })
            }
        },
        async fetchMethodFiDependentData({ state, commit, dispatch }) {
            logger.info('Maybe fetching MethodFi dependent data')

            if (state.isFetchingMethodFiDependentData) {
                logger.log(`Already fetching MethodFi dependent data so not fetching again`)
                return
            }

            commit('setIsFetchingMethodFiDependentData', { isFetching: true })
            await dispatch('maybeInitializeMethodFi')
            ;(async () => {
                await Promise.allSettled([dispatch('maybeFetchMethodfiLiabilityAccounts'), dispatch('maybeFetchMethodFiCreditScoreData')])
                commit('setIsFetchingMethodFiDependentData', { isFetching: false })
            })()
        },
        async acceptCreditOfferDisclosure({ state, commit, dispatch }) {
            await acceptCreditOfferDisclosure({ codeHash: process.env.VUE_APP_RELEASE_HASH as string })
            if (state.config) {
                commit('setDidAcceptCreditOfferDisclosure', { didAccept: true })
            } else {
                await dispatch('fetchConfig')
                commit('setDidAcceptCreditOfferDisclosure', { didAccept: true })
            }
        },
        async acceptEsignConsent({ state, commit, dispatch }) {
            await acceptEsignConsent({ codeHash: process.env.VUE_APP_RELEASE_HASH as string })
            if (state.config) {
                commit('setDidAcceptEsign', { didAccept: true })
            } else {
                await dispatch('fetchConfig')
            }
        },
        async consentToContactUpload({ state, commit, dispatch }) {
            await consentToContactUpload({ codeHash: process.env.VUE_APP_RELEASE_HASH as string })
            if (state.config) {
                commit('setDidAcceptContactUpload', { didAccept: true })
            } else {
                await dispatch('fetchConfig')
            }
        },
        async fetchAvenAdvisorAccountInfo({ state, commit, dispatch }) {
            try {
                const avenAdvisorLeadAccountInfoResponse = await getAvenAdvisorLeadAccountInfo()
                commit('incrementNumAttemptsToFetchAvenAdvisorLeadAccountInfo')
                if (!avenAdvisorLeadAccountInfoResponse.data?.success && state.numAttemptsToFetchAvenAdvisorLeadAccountInfo >= MAX_ATTEMPTS_TO_FETCH_AVEN_ADVISOR_ACCOUNT_INFO) {
                    logger.fatal(`Failed to fetch Aven Advisor account info after ${MAX_ATTEMPTS_TO_FETCH_AVEN_ADVISOR_ACCOUNT_INFO} attempts`)
                    return
                }
                if (!avenAdvisorLeadAccountInfoResponse.data || !avenAdvisorLeadAccountInfoResponse.data.success) {
                    // Retries in 4 secs, then 16, then 64, then 256. This should cover the rare occurrence
                    // of a server restart.
                    const retryInSecs = Math.pow(4, state.numAttemptsToFetchAvenAdvisorLeadAccountInfo)
                    logger.error(
                        `Aven Advisor web could not retrieve account info. Received status response status: ${avenAdvisorLeadAccountInfoResponse.status} ${avenAdvisorLeadAccountInfoResponse.statusText}. Retrying in ${retryInSecs} secs`
                    )
                    setTimeout(() => dispatch('maybeFetchAvenAdvisorAccountInfo'), retryInSecs * 1000)
                    return
                }
                const payload = avenAdvisorLeadAccountInfoResponse.data.payload
                commit('updateAvenAdvisorLeadAccountInfo', payload)
                openReplay.trySetMetadata({ avenAdvisorLeadId: `${avenAdvisorLeadAccountInfoResponse.data.payload.avenAdvisorLeadId}` })
                logger.log(`Retrieved and stored Aven Advisor lead ID from API: ${payload.avenAdvisorLeadId}`)
            } catch (e) {
                logger.fatal('Error trying to fetch Aven Advisor Lead ID', e)
            }
        },
        async maybeFetchUpgradeOffers({ state, commit }) {
            const upgradePaths = Object.values(AvenAdvisorUpgradePath)
            const upgradeActionsObj = state.upgradeActions

            for (const upgradePath of upgradePaths) {
                const response = await getUpgradeActionForPath(upgradePath)
                if (response.data.success && response.data.payload && response.data.payload.action) {
                    upgradeActionsObj[upgradePath] = response.data.payload.action
                    logger.info(`Fetched upgrade action for path: ${upgradePath}: ${inspect(response.data.payload.action)}`)
                } else {
                    logger.info(`Fetched no upgrade action for path: ${upgradePath}`)
                }
            }

            commit('setUpgradeActions', { upgradeActions: upgradeActionsObj })

            await Promise.allSettled(
                upgradePaths.map(async (upgradePath) => {
                    const upgradeAction = upgradeActionsObj[upgradePath]
                    if (!upgradeAction) {
                        logger.info(`Not fetching upgrade offers for path: ${upgradePath} because the upgrade action is not loaded`)
                        return
                    }
                    if (state.upgradeOffers && state.upgradeOffers[upgradePath]) {
                        logger.info(`Not fetching upgrade offer for path: ${upgradePath} because it is already loaded`)
                        return
                    }
                    if (state.isFetchingUpgradeOffers[upgradePath]) {
                        logger.info(`Not fetching upgrade offer for path: ${upgradePath} because it is already being fetched`)
                        return
                    }
                    // We do not want to fetch upgrade offers for an upgrade path if we have already done so
                    if (upgradeAction.status === AvenAdvisorUpgradeStatus.startDryrunPrequal && (state.upgradeOffers === null || state.upgradeOffers[upgradePath] === undefined)) {
                        logger.info(`Fetching upgrade offer for path: ${upgradePath} because the upgrade action is to start a dryrun prequal`)
                        try {
                            commit('setIsFetchingUpgradeOffersForPath', { isFetching: true, path: upgradePath })

                            // try to prequal
                            const prequalResponse = await maybeStartDryrunPrequalForUpgradePath(upgradePath)
                            const data = prequalResponse.data.payload
                            if (!data?.isEligible || !data?.jobId) {
                                logger.log(`Not fetching upgrade offer because the user is not eligible`)
                                commit('setUpgradeOffers', { upgradeOffers: {} })
                                return
                            }

                            // poll for the upgrade offer
                            const upgradeOffer = await pollForLoanApplicationPrequalOffer(data.jobId)
                            if (!upgradeOffer) {
                                logger.log(`Not fetching upgrade offer because the user is not eligible`)
                                commit('setUpgradeOfferForPath', { upgradeOffer: null, path: upgradePath })
                                return
                            }

                            // re-fetch action to get return token2
                            const upgradeActionResponse = await getUpgradeActionForPath(upgradePath)
                            if (upgradeActionResponse.data.success && upgradeActionResponse.data.payload) {
                                const payload = upgradeActionResponse.data.payload
                                assert(payload.action.status !== AvenAdvisorUpgradeStatus.avenHomeUpgradeDetermined)
                                if (payload.action.dryrunReturnToken2) {
                                    // fetch offers from return token2
                                    // const response = await getPreQualOffersFromReturnToken2(upgradeActionResponse.data.payload.action.dryrunReturnToken2)
                                    // if (response.data.success && response.data.payload) {
                                    //     commit('setUpgradeOfferForPath', { upgradeOffer: response.data.payload, path: upgradePath })
                                    // } else {
                                    //     logger.fatal(`Error fetching upgrade offer for path: ${upgradePath}`, response.data.error)
                                    // }
                                }
                            }
                        } catch (e) {
                            logger.fatal(`Error fetching upgrade offer for path: ${upgradePath}`, e)
                        } finally {
                            commit('setIsFetchingUpgradeOffersForPath', { isFetching: false, path: upgradePath })
                        }
                    } else if (upgradeAction.status === AvenAdvisorUpgradeStatus.dryrunPrequalified) {
                        // dryrun already PQed, so fetch offerrs from return token2
                        if (!upgradeAction.dryrunReturnToken2) {
                            logger.fatal(`Upgrade action for path: ${upgradePath} has dryrunPrequalified status but no dryrunReturnToken2`)
                            return
                        }
                        try {
                            // const response = await getPreQualOffersFromReturnToken2(upgradeAction.dryrunReturnToken2)
                            // if (response.data.success && response.data.payload) {
                            //     commit('setUpgradeOfferForPath', { upgradeOffer: response.data.payload, path: upgradePath })
                            // } else {
                            //     logger.fatal(`Error fetching upgrade offer for path: ${upgradePath}`, response.data.error)
                            // }
                        } catch (e) {
                            logger.fatal(`Error fetching upgrade offer for path: ${upgradePath}`, e)
                        }
                    } else {
                        logger.info(
                            `Not fetching upgrade offer for path: ${upgradePath} because the upgrade action is ${upgradeAction.status} and fetched upgrade offers are ${inspect(state.upgradeOffers)}`
                        )
                    }
                })
            )
        },
        async maybeFetchAffiliateRewardsLedger({ commit }) {
            // get from the last 30 days
            const startDate = new Date(new Date().getTime() - 30 * 24 * 60 * 60 * 1000)
            const endDate = new Date()
            const response = await getAffiliateRewardsLedger(startDate, endDate)
            if (response.data.success && response.data.payload) {
                commit('setAffiliateRewardsLedger', response.data.payload)
                // get the balance by looking at the latest item in the ledger (which is the most recent)
                const lastItem = response.data.payload.ledger[0]
                if (lastItem) {
                    commit('setAffiliatePointsBalance', { balance: lastItem.currentPoints })
                } else {
                    logger.log(`No last item in affiliate rewards ledger`)
                    commit('setAffiliatePointsBalance', { balance: 0 })
                }
            } else {
                logger.fatal(`Aven Advisor failed to load affiliate rewards ledger with error: ${response.data.error}`)
            }
        },
        async maybeFetchAvenAdvisorAccountInfo({ state, dispatch }) {
            logger.log(`Maybe fetching Aven Advisor account information from API`)
            if (state.avenAdvisorLeadId) {
                logger.log(`Not fetching Aven Advisor account info because advisorLeadId is already stored`)
                return
            }
            await dispatch('fetchAvenAdvisorAccountInfo')
        },
        async tryGetConsentToShareDataWithExperianBoost({ state, commit }) {
            try {
                if (state.persistedStore.consentToExperianBoostDataSharingHash) {
                    logger.log('Aven Advisor already has consent to Experian Boost data sharing hash')
                    return
                }
                logger.log('Aven Advisor is trying to get consent to Experian Boost data sharing hash')
                const consentResponse = await getConsentToShareDataWithExperianBoost()
                if (consentResponse.data.success) {
                    logger.log(`Aven Advisor successfully retrieved consent to Experian Boost data sharing hash: ${consentResponse.data.payload.hash}`)
                    commit('updateConsentToExperianBoostDataSharingDisclosuresHash', { hash: consentResponse.data.payload.hash })
                    return
                }
                logger.fatal('Aven Advisor did not successfully retrieve consent to Experian Boost data sharing hash')
            } catch (e) {
                logger.fatal('Error trying to get consent to share data with Experian Boost', e)
            }
        },
        async getIsPlaidRequiredForPaidReferral({ commit }) {
            try {
                logger.info(`Determining whether plaid is required for Aven Advisor paid referral`)
                const isPlaidRequiredForPaidReferralResponse = await getIsPlaidRequiredForPaidReferral()
                if (isPlaidRequiredForPaidReferralResponse.data.success) {
                    logger.info(`Successfully determined whether plaid is required for Aven Advisor paid referral: ${JSON.stringify(isPlaidRequiredForPaidReferralResponse.data.payload)}`)
                    commit('setIsPlaidRequiredForPaidReferral', { isPlaidRequiredForPaidReferral: isPlaidRequiredForPaidReferralResponse.data.payload.isPlaidRequiredForPaidReferral })
                }
            } catch (e) {
                logger.fatal(`Error loading isPlaidRequiredForPaidReferral`, e)
            }
        },
        async maybeRefreshVehicles({ state, commit, dispatch }) {
            if (state.refreshedVehicles || state.isRefreshingVehicles) {
                logger.log(`Not refreshing vehicles because it has already been refreshed`)
                return
            }

            commit('setIsRefreshingVehicles', { isRefreshing: true })

            const response = await refreshVehicles()
            if (response.data.success && response.data.payload) {
                commit('setRefreshedVehicles', { refreshedVehicles: true })
            }

            commit('setIsRefreshingVehicles', { isRefreshing: false })

            // once this is done, grab a valuation
            await dispatch('maybeFetchVehicleInformation')
        },
        async maybeFetchMethodFiCreditScoreData({ state, commit }) {
            if (!state.didInitializeMethodFi) {
                logger.log(`MethodFi not initialized, not fetching liability accounts`)
                return
            }
            if (state.didFetchMethodFiCreditScoreData) {
                logger.log(`Not fetching new MethodFi credit score data because it has already been loaded`)
                return
            } else if (state.isFetchingMethodFiCreditScoreData) {
                logger.log(`Not fetching new MethodFi credit score data because it is already being fetched`)
                return
            }
            try {
                commit('setMethodFiCreditScoreDataLoading', { isLoading: true })
                await intializeMethodFiCreditScoreData()
                logger.info(`Initialized MethodFi credit score data`)

                // from mike ossig at methodfi - P95 is 22 seconds, with an average of 14 seconds
                const MAX_METHODFI_CREDIT_SCORE_POLLING_MILLIS = 22000
                const METHODFI_CREDIT_SCORE_POLLING_INTERVAL_MILLIS = 1000
                const startedPollingDate = new Date()
                logger.info(`Started polling for MethodFi credit scores at ${startedPollingDate.toISOString()}`)
                while (!state.didFetchMethodFiCreditScoreData) {
                    const creditScoresResponse = await getMethodFiCreditScoreData()
                    if (creditScoresResponse.data.success) {
                        const creditScoreData = creditScoresResponse.data.payload
                        if (creditScoreData && !creditScoreData.isPending) {
                            logger.info(`MethodFi credit score polling returned a credit score data object. Now committing`)
                            commit('updateMethodFiCreditScoreData', {
                                creditScoreData,
                            })
                            commit('setMethodFiCreditScoreDataLoaded')
                            commit('setMethodFiCreditScoreDataLoading', { isLoading: false })
                            break
                        }
                    } else {
                        logger.fatal(`Failed to get a credit score and the error was unrecognized: ${creditScoresResponse.data.error}. See backend logs for more info.`)
                        break
                    }
                    if (new Date().getTime() - startedPollingDate.getTime() > MAX_METHODFI_CREDIT_SCORE_POLLING_MILLIS) {
                        logger.info(`MethodFi credit score polling timed out after ${MAX_METHODFI_CREDIT_SCORE_POLLING_MILLIS} ms`)
                        commit('setMethodFiCreditScoreDataLoaded')
                        commit('setMethodFiCreditScoreDataLoading', { isLoading: false })
                        break
                    }
                    await new Promise((resolve) => setTimeout(resolve, METHODFI_CREDIT_SCORE_POLLING_INTERVAL_MILLIS))
                }

                if (!state.methodfiCreditScoreData || state.methodfiCreditScoreData.isPending) {
                    logger.info(`MethodFi credit score polling did not return a credit score data object, try one more fetch allowing old data`)
                    const creditScoresResponse = await getMethodFiCreditScoreData(true)
                    if (creditScoresResponse.data.success) {
                        const creditScoreData = creditScoresResponse.data.payload
                        if (creditScoresResponse && creditScoreData && !creditScoreData.isPending) {
                            logger.info(`MethodFi credit score polling returned a credit score data object, with allowing old data. Now committing`)
                            commit('updateMethodFiCreditScoreData', {
                                creditScoreData,
                            })
                            commit('setMethodFiCreditScoreDataLoaded')
                            commit('setMethodFiCreditScoreDataLoading', { isLoading: false })
                        }
                    }
                }
            } catch (e) {
                logger.fatal(`Error loading MethodFi credit score data`, e)
            } finally {
                state.didFetchMethodFiCreditScoreData = true
                state.isFetchingMethodFiCreditScoreData = false
            }

            try {
                const response = await getMethodFiCreditScoreHistory()
                if (response.data.success) {
                    commit('updateMethodFiCreditScoreHistoryForToday', { history: response.data.payload.history })
                } else {
                    logger.fatal(`Aven Advisor failed to load MethodFi credit score history with error: ${inspect(response.data.error)}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor  MethodFicredit score history`, e)
            }
        },
        /** @deprecated use MethodFi */
        async maybeFetchCreditScoreData({ state, commit }) {
            if (state.didFetchCreditScoreData) {
                logger.log(`Not fetching new credit score data because it has already been loaded`)
                return
            } else if (state.isFetchingCreditScoreData) {
                logger.log(`Not fetching new credit score data because it is already being fetched`)
                return
            }
            commit('setCreditScoreDataLoading', { isLoading: true })
            let creditScoresResponse
            try {
                creditScoresResponse = await getCreditScores()
                if (creditScoresResponse.data.success) {
                    commit('updateCreditScoreDataForToday', {
                        ficoScore: creditScoresResponse.data.payload.ficoScore,
                        vantageScore: creditScoresResponse.data.payload.vantageScore,
                        dateOfScores: creditScoresResponse.data.payload.dateOfScores,
                        dateOfNextScores: creditScoresResponse.data.payload.dateOfNextScores,
                    })
                    // for backcompat
                    if (creditScoresResponse.data.payload.history) {
                        commit('updateCreditScoreHistoryForToday', { history: creditScoresResponse.data.payload.history })
                    }
                    commit('setCreditScoreDataLoaded')
                } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.NEED_FULL_9_SSN) {
                    logger.log(`Aven Advisor lead needs full SSN to get credit scores`)
                    commit('updateCreditScoreDataForToday', {
                        needFull9ssn: true,
                    })
                    commit('setCreditScoreDataLoaded')
                } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.EXPERIAN_FROZEN_ERROR) {
                    logger.log(`Experian file frozen for Aven Advisor lead`)
                    commit('updateCreditScoreDataForToday', {
                        isExperianFrozen: true,
                    })
                    commit('setCreditScoreDataLoaded')
                } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.NO_SCORE_AVAILABLE_ERROR) {
                    logger.log(`No credit scores available for Aven Advisor lead`)
                    commit('setCreditScoreDataLoaded')
                } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.USER_IS_A_MINOR) {
                    logger.log(`Experian indicates user is a minor`)
                    commit('setCreditScoreDataLoaded')
                    logout(LogoutReason.blockedAccount, true)
                } else if (creditScoresResponse.data.error === GetAvenAdvisorCreditScoresErrors.USER_IS_BLOCKED) {
                    logger.log(`Result of getting credit scores indicates user is blocked`)
                    commit('setCreditScoreDataLoaded')
                    logout(LogoutReason.blockedAccount, true)
                } else {
                    // Just warn here. We'll get a page from the backend if any other errors happen
                    logger.fatal(`Failed to get a credit score and the error was unrecognized: ${creditScoresResponse.data.error}. See backend logs for more info.`)
                }
            } catch (e) {
                logger.fatal(`Error loading Aven Advisor credit score data`, e)
            } finally {
                commit('setCreditScoreDataLoading', { isLoading: false })
            }

            try {
                const response = await getCreditScoreHistory()
                if (response.data.success) {
                    commit('updateCreditScoreHistoryForToday', { history: response.data.payload.history })
                } else {
                    logger.fatal(`Aven Advisor failed to load credit score history with error: ${inspect(response.data.error)}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor credit score history`, e)
            }
        },
        async maybeFetchInviteCode({ state, commit }) {
            if (state.inviteCode) {
                logger.log(`Aven Advisor already loaded invite code`)
                return
            }
            try {
                logger.log(`Loading invite code for Aven Advisor`)
                const response = await getInviteCode()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor invite code ${response.data.payload.inviteCode}`)
                    commit('setInviteCode', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load invite code with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead invite code`, e)
            }
        },
        async maybeFetchIsPlaidConnected({ state, commit }) {
            if (state.isPlaidConnected) {
                logger.log(`Aven Advisor already loaded plaid connection status`)
                return
            }
            try {
                const isPlaidConnected = await getIsPlaidConnected()
                commit('setIsPlaidConnected', { isPlaidConnected })
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead plaid connection status`, e)
            }
        },
        async maybeFetchInvestmentHoldings({ state, dispatch }) {
            if (state.investmentHoldings) {
                logger.log(`Aven Advisor already loaded investment holdings`)
                return
            }
            await dispatch('fetchInvestmentHoldings')
        },
        async fetchInvestmentHoldings({ commit }) {
            try {
                logger.log(`Loading investment holdings for Aven Advisor`)
                const response = await getInvestmentHoldings()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor investment holdings`)
                    commit('setInvestmentHoldings', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load investment holdings with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead investment holdings`, e)
            }
        },
        async maybeFetchOwnwellSavingsEstimate({ state, commit }) {
            if (state.didFetchOwnwellSavingsEstimate) {
                logger.log(`Aven Advisor already loaded savings estimate`)
                return
            }
            try {
                logger.log(`Loading ownwell savings estimate for Aven Advisor`)
                const response = await getOwnwellSavingsEstimate()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor ownwell savings estimate ${response.data.payload}`)
                    commit('setOwnwellSavingsEstimate', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to get ownwell savings estimate with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor ownwell savings estimate`, e)
            }
        },
        async fetchNeighborhoodReport({ commit }) {
            try {
                logger.log(`Loading neighborhood report for Aven Advisor`)
                const response = await getNeighborhoodReport()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor neighborhood report ${JSON.stringify(response.data.payload)}`)
                    commit('setNeighborhoodData', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load neighborhood report with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead neighborhood report`, e)
            }
        },
        async fetchPersonaStatus({ commit }) {
            try {
                logger.log(`Loading persona status for Aven Advisor`)
                const response = await getPersonaStatus()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor persona status ${JSON.stringify(response.data.payload)}`)
                    commit('setPersonaStatus', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load persona status with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead persona status`, e)
            }
        },
        async fetchPersonaLink({ commit }) {
            try {
                logger.log(`Loading persona link for Aven Advisor`)
                const response = await getPersonaLink()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor persona link ${JSON.stringify(response.data.payload)}`)
                    commit('setPersonaLink', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load persona link with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead persona link`, e)
            }
        },
        async maybeFetchNeighborhoodReport({ state, dispatch }) {
            if (state.medianSalePriceInNeighborhood) {
                logger.log(`Aven Advisor already loaded neighborhood report`)
                return
            }
            await dispatch('fetchNeighborhoodReport')
        },
        async maybeFetchNeighborhoodRentPrices({ state, dispatch }) {
            if (state.didFetchNeighborhoodRentData) {
                logger.log(`Aven Advisor already loaded neighborhood rent data`)
                return
            }
            await dispatch('fetchNeighborhoodRentPrices')
        },
        async fetchNeighborhoodRentPrices({ commit }) {
            try {
                logger.log(`Loading neighborhood rent data for Aven Advisor`)
                const response = await getNeighborhoodRentPrices()
                if (response.data.success && response.data.payload) {
                    logger.log(`Retrieved Aven Advisor rent data ${JSON.stringify(response.data.payload)}`)
                    commit('setNeighborhoodRentData', response.data.payload)
                } else {
                    logger.warn(`Aven Advisor failed to load rent data with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.warn(`Error fetching Aven Advisor lead rent data`, e)
            }
        },
        async maybeFetchCashAndStocks({ dispatch, state }) {
            if (state.cashAndStockInfo && state.cashAndStockInfo.length > 0) {
                logger.log(`Aven Advisor already loaded cash & stock`)
                return
            }
            await dispatch('fetchCashAndStocks')
        },
        async fetchCashAndStocks({ commit }) {
            try {
                logger.log(`Loading cash & stock for Aven Advisor`)
                const response = await getCashAndStock()
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded cash & stock`)
                    commit('setCashAndStock', response.data.payload)
                } else if (response.data.success) {
                    logger.log(`Aven Advisor loaded cash & stock, but cash & stock could not be calculated`)
                    commit('setCashAndStock', [])
                } else {
                    logger.fatal(`Aven Advisor failed to load cash & stock with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead cash & stock`, e)
            }
        },
        async maybeFetchMethodfiLiabilityAccounts({ commit, state }) {
            if (state.isFetchingMethodFiLiabilityAccounts) {
                logger.log(`Already fetching methodfi liability accounts`)
                return
            }
            if (!state.didInitializeMethodFi) {
                logger.log(`MethodFi not initialized, not fetching liability accounts`)
                return
            }
            if (state.methodFiLiabilityAccounts) {
                logger.log(`Aven Advisor already loaded methodfi liability accounts`)
                return
            }
            commit('setIsFetchingMethodFiLiabilityAccounts', { isFetching: true })
            try {
                logger.log(`Loading liability accounts for Aven Advisor`)
                const response = await getLiabilityAccounts({ codeHash: process.env.VUE_APP_RELEASE_HASH as string })
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded liability accounts: ${JSON.stringify(response.data.payload)}`)
                    commit('setLiabilityAccounts', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load liability accounts with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead liability accounts `, e)
            }
            commit('setIsFetchingMethodFiLiabilityAccounts', { isFetching: false })
        },
        async maybeFetchHomeOwnershipStatus({ commit, state }) {
            if (state.homeOwnershipStatus !== null) {
                logger.log(`Aven Advisor already loaded home ownership status`)
                return
            }
            try {
                logger.log(`Loading home ownership status for Aven Advisor`)
                const response = await getHomeOwnershipStatus()
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded home ownership status: ${JSON.stringify(response.data.payload)}`)
                    commit('setHomeOwnershipStatus', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load home ownership status with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead home ownership status`, e)
            }
        },
        async maybeInitializeMethodFi({ commit, state }) {
            if (state.didInitializeMethodFi) {
                logger.log(`MethodFi already initialized`)
                return
            }
            try {
                commit('setIsInitializingMethodFi', { isInitializing: true })
                logger.log(`Initializing MethodFi for Aven Advisor`)
                const response = await maybeInitializeMethodFi()
                if (response && response.data.success) {
                    logger.log(`MethodFi initialized successfully`)
                    commit('setDidInitializeMethodFi', { didInitialize: true })
                    commit('setIsInitializingMethodFi', { isInitializing: false })
                    logEvent('event_advisor_methodfi_init_success')
                } else {
                    logger.fatal(`MethodFi failed to initialize with error: ${response?.data.error || 'unknown'}`)
                    logEvent('event_advisor_methodfi_init_failure', { error: response?.data.error || 'unknown' })
                }
            } catch (e) {
                logger.fatal(`Error initializing MethodFi for Aven Advisor`, e)
            }
        },
        async editAccountInformation(
            { dispatch, commit },
            args: {
                firstName: string
                lastName: string
                email: string
                phoneNumber: string
                addressData: { addressComponents: AddressComponents }
                numberOfBedrooms?: string
            }
        ) {
            const response = await editAccountInformation({
                firstName: args.firstName,
                lastName: args.lastName,
                email: args.email,
                phoneNumber: args.phoneNumber,
                addressStreet: args.addressData.addressComponents.addressStreet,
                secondaryAddressUnit: args.addressData.addressComponents.addressUnit,
                addressCity: args.addressData.addressComponents.addressCity,
                addressState: args.addressData.addressComponents.addressState,
                addressPostalCode: args.addressData.addressComponents.addressPostalCode,
                numberOfBedrooms: args.numberOfBedrooms,
            })

            if (!response.data.success) {
                if ('errorCode' in response.data.payload) {
                    return { success: false, errorCode: response.data.payload }
                }

                return { success: false }
            }

            logger.log(`Successfully updated address, updating information`)
            if ('otpSid' in response.data?.payload && response.data.payload.otpSid) {
                logger.log(`Phone number changed, requesting 2fa code`)
                const twoFaResponse = await requestTwoFactor({
                    phoneNumber: args.phoneNumber,
                })
                commit('updateOtpSid', {
                    otpSid: twoFaResponse.data.payload.sid,
                })
                commit('setPhoneNumber', args.phoneNumber)
                return { success: true, nextPage: appRoutePaths.EDIT_ACCOUNT_INFORMATION_OTP }
            }
            await Promise.all([dispatch('fetchHomeValue'), dispatch('fetchNeighborhoodReport'), dispatch('getEnergyPrices'), dispatch('fetchNeighborhoodRentPrices')])
            return { success: true }
        },
        async fetchHomeValue({ commit }) {
            try {
                logger.log(`Loading home value for Aven Advisor`)
                const response = await getHomeValue()
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded home value: ${JSON.stringify(response.data.payload)}`)
                    commit('setHomeValue', response.data.payload)
                } else if (response.data.success) {
                    logger.log(`Aven Advisor loaded home value, but home value could not be calculated`)
                } else {
                    logger.fatal(`Aven Advisor failed to load home value with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead home value`, e)
            }
        },
        async maybeFetchHomeValue({ state, dispatch }) {
            if (state.homeValue !== null) {
                logger.log(`Aven Advisor already loaded home value`)
                return
            }
            await dispatch('fetchHomeValue')
        },
        async maybeFetchVehicleInformation({ commit, state }) {
            if (state.vehicleInformation !== null) {
                logger.log(`Aven Advisor already loaded vehicle information`)
                return
            }
            try {
                logger.log(`Loading vehicle information for Aven Advisor`)
                const response = await retrieveVehicleValue()
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded vehicle information: ${JSON.stringify(response.data.payload)}`)
                    commit('setVehicleInformation', response.data.payload)
                } else if (response.data.success) {
                    logger.log(`Aven Advisor loaded vehicle information, but vehicle information could not be deduced`)
                } else {
                    logger.fatal(`Aven Advisor failed to load vehicle information with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead vehicle information`, e)
            }
        },
        async maybeFetchReferralInfo({ state, commit }, args: { skipCache: boolean }) {
            if (!args.skipCache && state.didFetchReferralInfo) {
                logger.log(`Already fetched Aven Advisor referral info`)
                return
            }
            let referralInfo
            try {
                logger.log(`Fetching Aven Advisor pending referral info w/ args: ${JSON.stringify(args)}`)
                referralInfo = await getReferralInfo()
                if (referralInfo.data?.success) {
                    const payload = referralInfo.data.payload
                    logger.log(`Successfully fetched Aven Advisor referral info: ${JSON.stringify(payload)}`)
                    commit('setReferralInfo', {
                        pendingReferralPayoutDollars: payload.pendingReferralDollars,
                        payoutsEnabled: payload.payoutsEnabled,
                        connectedStripeAccountRemediationMessage: payload.remediationMessage,
                        spinToWinEnabled: payload.spinToWinEnabled,
                        availableSpins: payload.availableSpins,
                        lastSuccessfulSpinAmount: payload.lastSuccessfulSpinAmount,
                    })
                } else {
                    logger.fatal(`Unsuccessful retrieval of referral info. Payload received: ${inspect(referralInfo)}`)
                }
            } catch (e) {
                logger.fatal(`Error loading Aven Advisor referral info`, e)
            }
        },
        async maybeSpinTheWheel({ state, commit }) {
            if (!state.availableSpins) {
                logger.fatal(`Aven Advisor tried to spin the wheel, but no spins available`)
                return
            }

            try {
                commit('handleSpinTheWheelStarted')
                const spinTheWheelResponse = await spinTheWheel()
                if (spinTheWheelResponse.data?.success) {
                    logger.log(`Successfully fetched Aven Advisor referral info: ${JSON.stringify(spinTheWheelResponse.data.payload)}`)
                    commit('handleSuccessfulSpin', {
                        dollarAmount: spinTheWheelResponse.data.payload.dollarAmount,
                    })
                } else {
                    logger.error(`Unsuccessful attempt to spin the wheel on Aven Advisor`)
                }
            } catch (e) {
                logger.fatal(`Error attempting to spin the wheel on Aven Advisor`, e)
            }
        },
        async maybeInitiateCreditOffers({ state, commit }, payload: { fcraLanguage: string; tcpaLanguage: string }) {
            if (state.didTryInitiateEvenOffers) {
                logger.log(`Already tried initiating Even offers. Not trying again`)
                return
            }
            let didInitiateEvenOffersSuccessfully = false
            try {
                logger.log(`Initiating Aven Advisor credit offers with fcraDisclosure: ${payload.fcraLanguage}, tcpaDisclosure: ${payload.tcpaLanguage}`)
                const response = await initiateCreditOffer(payload.fcraLanguage, payload.tcpaLanguage)
                didInitiateEvenOffersSuccessfully = response.data.success
            } catch (e) {
                logger.fatal(`Error initiating credit offers for Aven Advisor`, e)
            } finally {
                logger.log(`${didInitiateEvenOffersSuccessfully ? 'Successfully initiated' : 'Unsuccessful initiating'} Aven Advisor Even offers`)
                commit('setEvenOfferInitState', { didInitiateEvenOffersSuccessfully })
            }
        },
        // TODO unpause when we can reenable even
        async maybeFetchCreditOffers() {
            /*if (!state.didInitiateEvenOffersSuccessfully) {
                logger.log(`Aven Advisor did not successfully initiate Even offers, so not trying to fetch them`)
                return
            }
            if (state.didFetchCreditAndSpecialOffers) {
                logger.log(`Aven Advisor already fetched Even offers, so not trying to fetch again`)
                return
            }
            try {
                const startedPollingCreditOffersDate = new Date()
                logger.log(`Polling for Aven Advisor credit offers starting ${startedPollingCreditOffersDate}`)
                while (!state.didFetchCreditAndSpecialOffers) {
                    const offersResponse = await pollForCreditOffers()
                    logger.log(`Received Aven Advisor credit offers response w/ data: ${JSON.stringify(offersResponse.data)}`)

                    let creditOffers: LoanOffer[] = []
                    let specialOffers: SpecialOffer[] = []
                    if (offersResponse.data.payload?.offers?.length) {
                        creditOffers = offersResponse.data.payload.offers
                    }
                    if (offersResponse.data.payload?.specialOffers?.length) {
                        specialOffers = offersResponse.data.payload.specialOffers
                    }

                    const elapsedTimeMillis = new Date().getTime() - startedPollingCreditOffersDate.getTime()
                    const pollingTimeout = elapsedTimeMillis > MAX_CREDIT_OFFER_POLLING_MILLIS
                    const stillInProgress = !!offersResponse.data.payload?.inProgress

                    if (!stillInProgress) {
                        logger.log(`Aven Advisor credit offer polling succeeded! Setting offers: ${JSON.stringify({ creditOffers, specialOffers })}`)
                        commit('setEvenOffers', { creditOffers, specialOffers })
                        break
                    }
                    if (pollingTimeout) {
                        logger.log(`Aven Advisor credit offer polling timeout of ${MAX_CREDIT_OFFER_POLLING_MILLIS} millis reached! Setting offers: ${JSON.stringify({ creditOffers, specialOffers })}`)
                        commit('setEvenOffers', { creditOffers, specialOffers })
                        break
                    }

                    logger.log(`Aven Advisor credit offers are still in progress. Waiting ${CREDIT_OFFER_POLLING_INTERVAL_MILLIS} millis then trying again.`)
                    await new Promise((resolve) => setTimeout(resolve, CREDIT_OFFER_POLLING_INTERVAL_MILLIS))
                }
            } catch (e) {
                logger.fatal(`Error polling for Aven Advisor credit offers`, e)
                commit('setEvenOffers', { creditOffers: [], specialOffers: [] })
            }*/
        },
        async getIsPlaidAuthenticatedForSweepstakes({ commit }) {
            logger.info(`Determining whether plaid is authenticated for Aven Advisor sweepstakesV2`)
            const response = await getIsPlaidAuthenticatedForSweepstakes()

            if (!response || response.data.success === false) {
                logger.fatal(`Error loading isPlaidAuthenticatedForSweepstakes`, response?.data.error)
                return
            }

            logger.info(`Successfully determined whether plaid is authenticated for Aven Advisor sweepstakesV2: ${JSON.stringify(response.data.payload)}`)
            commit('setIsPlaidAuthenticatedForSweepstakes', { isPlaidAuthenticatedForSweepstakes: response.data.payload.isPlaidAuthenticated })
            commit('setIsGettingPlaidAuthenticatedForSweepstakes', { isCompleting: false })
        },
        async completePlaidFetch({ dispatch, commit }, linkState: { plaidPublicToken: string; institutionName: string; institutionId: string }) {
            logger.log(`Got Plaid link state: ${JSON.stringify(linkState)}`)
            const { plaidPublicToken, institutionId, institutionName } = linkState

            commit('setIsGettingPlaidAuthenticatedForSweepstakes', { isCompleting: true })
            commit('setCashAndStockRefreshingInstitutionNames', { institutionName, cashAndStockIsRefreshing: true })

            const fetchStartDate = new Date()
            window.logEvent('event_advisor_initial_cash_and_stock_fetch_start', {
                fetchStart: fetchStartDate.getUTCDate().toString(),
            })
            commit('maybeRemoveInstitutionIdFromExpiredPlaidItems', { institutionId })
            const itemId = await PlaidManager.completePlaidFetch(plaidPublicToken, institutionId)
            await Promise.allSettled([dispatch('fetchInvestmentHoldings'), dispatch('fetchCashAndStocks')])
            commit('setCashAndStockRefreshingInstitutionNames', { institutionName, cashAndStockIsRefreshing: false })
            assert(itemId, 'Plaid fetch did not return an item id')

            // Invalidate all the data that depends on the Plaid fetch
            commit('setIsPollingForPlaidTransactions', { isPolling: true })
            commit('setTopMerchantsInfo', null)
            commit('setSubscriptionsInfo', null)
            commit('setWeeklyTransactionsInfo', null)
            commit('setRecentTransactions', null)
            commit('setSpendingCardData', null)
            dispatch('maybeFetchIsPlaidConnected')
            const fetchSuccess = await PlaidManager.pollForPlaidReportCompletion(itemId)
            commit('setIsPollingForPlaidTransactions', { isPolling: false })
            const fetchEndDate = new Date()
            window.logEvent('event_advisor_initial_cash_and_stock_fetch_end', {
                fetchEnd: fetchEndDate.getUTCDate().toString(),
                fetchSuccess,
            })
            if (fetchSuccess) {
                logger.log(`Plaid asset report is ready. Fetching cash & stock information and refreshing top merchants`)
                // The reason were fetching this here and above is that the accounts balances are immediately available after the user connects Plaid but the transactions are not
                // So we call pollForPlaidReportCompletion to wait for the transactions to be available and refetch here.
                await Promise.allSettled([
                    dispatch('fetchCashAndStocks'),
                    dispatch('maybeGetTopMerchants'),
                    dispatch('maybeGetWeeklyTransactions'),
                    dispatch('maybeGetRecentTransactions', { startDate: new Date(new Date().setDate(new Date().getDate() - 7)), endDate: new Date() }),
                    dispatch('maybeGetSpendingCardData'),
                    dispatch('maybeGetSubscriptions'),
                ])
            } else {
                commit('setCashAndStockErrorMessage', { institutionName, cashAndStockErrorMessage: 'Could not connect to Plaid at this time.' })
            }
        },
        async maybeGetHomeEquityInvestmentEstimate({ state, commit }) {
            if (state.homeEquityInvestmentEstimate) {
                logger.log(`Not re-downloading Advisor HEI estimate since the frontend already has it`)
                return
            }
            try {
                const response = await getHeiEstimate()
                if (!response.data.success) {
                    logger.warn(`Unsuccessful downloading Advisor HEI estimate`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieve Advisor HEI estimate: ${JSON.stringify(payload)}`)
                commit('setHomeEquityInvestmentEstimate', {
                    applicationUrl: payload.applicationUrl,
                    minEstimateOfferAmountDollars: payload.minEstimateOfferAmountDollars,
                    maxEstimateOfferAmountDollars: payload.maxEstimateOfferAmountDollars,
                })
            } catch (e) {
                logger.fatal(`Error downloading Advisor HEI estimate`, e)
            }
        },
        async getPlaidLinkToken({ state }) {
            if (isRunningInNativeApp()) {
                return await getPlaidLinkToken()
            }
            return state.plaidLinkToken
        },
        async setPlaidLinkToken({ commit }, plaidLinkToken: string | null) {
            commit('setPlaidLinkToken', plaidLinkToken)

            if (isRunningInNativeApp()) {
                await savePlaidLinkToken(plaidLinkToken)
            }
        },
        async getEnergyPrices({ commit }) {
            try {
                const response = await getEnergyPrices()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unsuccessful downloading Advisor energy prices`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieve Advisor energy prices: ${JSON.stringify(payload)}`)
                commit('setEnergyPrices', payload)
            } catch (e) {
                logger.fatal(`Error downloading Advisor energy prices`, e)
            }
        },
        async maybeGetEnergyPrices({ state, dispatch }) {
            if (state.energyPriceData) {
                logger.log(`Not re-downloading Advisor energy price data since the frontend already has it`)
                return
            }
            dispatch('getEnergyPrices')
        },
        async maybeGetCreditScoreTips({ state, commit }, forceRefresh = false) {
            if (state.creditScoreTips && !forceRefresh) {
                logger.log(`Not re-downloading Advisor credit score tips since the frontend already has it`)
                return
            }
            try {
                const response = await getCreditScoreTips(forceRefresh)
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unsuccessful downloading Advisor credit score tips`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved Advisor credit score tips: ${JSON.stringify(payload)}`)
                commit('setCreditScoreTips', payload?.tips)
            } catch (e) {
                logger.fatal(`Error downloading Advisor credit score tips`, e)
            }
        },
        async forceGetStockBars({ commit }) {
            // I hate copy pasta, bypass state object check and fetch the data directly.
            try {
                const response = await getStockBars()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unsuccessful downloading stock equities data`)
                    return
                }

                const payload = response.data.payload.stockBars
                logger.log(`Successfully retrieve stock equities data: ${JSON.stringify(payload)}`)
                commit('setStockBars', payload)
            } catch (e) {
                logger.fatal(`Error downloading stock equities data`, e)
            }
        },

        async maybeGetStockBars({ state, commit }) {
            if (state.stockBars) {
                logger.log(`Not re-downloading stock equities data since the frontend already has it`)
                return
            }
            try {
                const response = await getStockBars()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unsuccessful downloading stock equities data`)
                    return
                }

                const payload = response.data.payload.stockBars
                logger.log(`Successfully retrieve stock equities data: ${JSON.stringify(payload)}`)
                commit('setStockBars', payload)
            } catch (e) {
                logger.fatal(`Error downloading stock equities data`, e)
            }
        },
        async maybeGetTopMerchants({ state, commit }) {
            if (state.isPollingForPlaidTransactions) {
                logger.log(`Not fetching top merchants because plaid transactions are being polled`)
                return
            }
            if (state.topMerchantsInfo) {
                logger.log(`Top merchants info already set, not refetching`)
                return
            }
            try {
                const response = await getTopMerchants()
                if (!response.data.success) {
                    logger.warn(`Unable to fetch top merchants`)
                    return
                }

                const payload = response.data.payload
                logger.log('Successfully retrieved top merchants')
                commit('setTopMerchantsInfo', payload)
            } catch (e) {
                logger.warn(`Error fetching top merchants info`)
            }
        },
        async maybeGetSubscriptions({ state, commit }) {
            if (state.isPollingForPlaidTransactions) {
                logger.log(`Not fetching subscriptions because plaid transactions are being polled`)
                return
            }
            if (state.subscriptionsInfo) {
                logger.log(`Subscriptions info already set, not refetching`)
                return
            }
            try {
                const response = await getSubscriptions()
                if (!response.data.success) {
                    logger.warn(`Unable to fetch subscriptions`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved subscriptions ${JSON.stringify(payload)}`)
                commit('setSubscriptionsInfo', payload)
            } catch (e) {
                logger.warn(`Error fetching subscriptions info`)
            }
        },
        async maybeGetWeeklyTransactions({ state, commit }) {
            if (state.isPollingForPlaidTransactions) {
                logger.log(`Not fetching subscriptions because plaid transactions are being polled`)
                return
            }
            if (state.weeklyTransactionsInfo) {
                logger.log(`Weekly transactions info already set, not refetching`)
                return
            }
            try {
                const response = await getWeeklyTransactions(DEFAULT_NUM_WEEKLY_TRANSACTIONS_TO_RETURN)
                if (!response.data.success) {
                    logger.warn(`Unable to fetch weekly transactions`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved weekly transactions ${JSON.stringify(payload)}`)
                commit('setWeeklyTransactionsInfo', payload)
            } catch (e) {
                logger.warn(`Error fetching weekly transactions info`)
            }
        },
        async maybeGetRecentTransactions({ state, commit }, params: { startDate: Date; endDate: Date }) {
            if (state.isPollingForPlaidTransactions) {
                logger.log(`Not fetching subscriptions because plaid transactions are being polled`)
                return
            }
            try {
                const response = await getTransactionsBetweenDates(params.startDate.toString(), params.endDate.toString())
                if (!response.data.success) {
                    logger.warn(`Unable to fetch recent transactions`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved recent transactions ${JSON.stringify(payload)}`)
                commit('appendRecentTransactions', payload)
            } catch (e) {
                logger.warn(`Error fetching recent transactions info`)
            }
        },
        async resetAndMaybeGetNotificationDataForPushId({ state, commit }) {
            if (state.notificationDataFromDeeplink) {
                logger.log(`Notification data already set, resetting`)
                commit('setNotificationDataFromDeeplink', null)
            }

            const queryParams = new URLSearchParams(window.location.search)
            const pushId = queryParams.get('pushId')
            if (!pushId) {
                logger.log(`No pushId found in query params, aborting`)
                return
            }

            try {
                const response = await getNotificationDataWithPushId(pushId)
                if (!response.data.success) {
                    logger.warn(`Unable to fetch notification data`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved notification data ${JSON.stringify(payload)}`)
                commit('setNotificationDataFromDeeplink', payload)
            } catch (e) {
                logger.warn(`Error fetching notification data`)
            }
        },
        async maybeGetSpendingCardData({ state, commit }) {
            if (state.isPollingForPlaidTransactions) {
                logger.log(`Not fetching subscriptions because plaid transactions are being polled`)
                return
            }
            if (state.spendingCardData) {
                logger.log(`Spending card data already set, not refetching`)
                return
            }
            try {
                const response = await getSpendingCardData()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unable to fetch spending card data`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved spending card data ${JSON.stringify(payload)}`)
                commit('setSpendingCardData', payload)
            } catch (e) {
                logger.warn(`Error fetching spending card data`)
            }
        },
        async getPayoutHistory({ commit }) {
            try {
                const response = await getPayoutHistory()
                logger.log(`Successfully retrieved payout history ${JSON.stringify(response.data.payload)}`)
                commit('setPayoutHistory', response.data.payload)
            } catch (e) {
                logger.warn(`Error fetching payout history info`)
            }
        },
        async getPayoutDeniedBankNames({ commit }) {
            try {
                const response = await getPayoutDeniedBankNames()
                logger.log(`Successfully retrieved list of denied bank names ${JSON.stringify(response.data.payload)}`)
                commit('setPayoutDeniedBankNames', response.data.payload)
            } catch (e) {
                logger.warn(`Error fetching payout history info`)
            }
        },
        async maybeGetPayoutHistory({ state }) {
            if (state.payoutHistory) {
                logger.log(`Payout history already set, not refetching`)
                return
            }
            await this.dispatch('getPayoutHistory')
        },
        async getGiftCardHistory({ commit }) {
            try {
                const freeCoffeeGiftCardInfo = await getFreeCoffeeGiftCardHistory()
                // Add more gift card info here if needed
                const allIssuedGiftCardInfo = freeCoffeeGiftCardInfo.data.payload.allIssuedFreeCoffeeGiftCardInfo
                logger.log(`Successfully retrieved gift card history ${JSON.stringify(allIssuedGiftCardInfo)}`)
                commit('setGiftCardHistory', allIssuedGiftCardInfo)
            } catch (e) {
                logger.warn(`Error fetching gift card history info`)
            }
        },
        async maybeGetGiftCardHistory({ state }) {
            if (state.giftCardHistory) {
                logger.log(`Gift card history already set, not refetching`)
                return
            }
            await this.dispatch('getGiftCardHistory')
        },
        async generateAutoApplicationLink({ state, commit }) {
            const generateAutoApplicationLinkResponse = await generateLinkToAutoApplication()
            logger.info(`Finished generating link to auto application, response: ${JSON.stringify(generateAutoApplicationLinkResponse.data)}`)
            if (!generateAutoApplicationLinkResponse.data.success || !generateAutoApplicationLinkResponse.data.payload) {
                logger.log(`Aven Advisor call to generate auto application link failed`)
                return
            }
            logger.log(`Aven Advisor call to generate auto application link succeeded! Response payload: ${JSON.stringify(generateAutoApplicationLinkResponse.data.payload)}`)
            const autoApplicationUrl = generateAutoApplicationLinkResponse.data.payload.autoApplicationUrl
            const taggedAutoApplicationUrl = buildTaggedAutoApplicationUrl(autoApplicationUrl, state.avenAdvisorLeadId)
            commit('setAutoApplicationUrl', { taggedAutoApplicationUrl })
            window.logEvent('render_advisor_auto_ad_jodl', {
                avenAdvisorLeadId: state.avenAdvisorLeadId,
            })
            if (isRunningInNativeApp()) {
                await openExternalWebView(taggedAutoApplicationUrl)
            } else {
                window.open(taggedAutoApplicationUrl, '_blank')
            }
        },
        async setSkippedConnectPlaidInterstitial({ commit }) {
            commit('setSkippedConnectPlaidInterstitial')
        },
        async fetchSweepstakesStatus({ commit }) {
            try {
                const response = await getSweepstakesStatus()
                if (!response?.data?.success) {
                    logger.warn(`Unable to fetch sweepstakes status`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved sweepstakes status`)
                commit('setSweepstakeStatus', payload)

                const sweepstakesRenderType = getSweepstakesRenderType(payload)
                commit('setSweepstakesRenderType', sweepstakesRenderType)
            } catch (error) {
                // @ts-ignore - TODO: better typing on error
                logger.fatal(`Error fetching sweepstakes status ${inspect(error)}`)
            }
        },
        async fetchSweepstakesV2({ commit }) {
            try {
                const response = await getSweepstakesV2()
                if (!response?.data?.success) {
                    logger.warn(`Unable to fetch sweepstakes v2 status`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved sweepstakes v2 status`)

                commit('setSweepstakeV2', payload)
            } catch (error) {
                if (typeof error === 'string') {
                    logger.fatal(`Error fetching sweepstakes v2 status ${error}`)
                } else if (error instanceof Error) {
                    logger.fatal(`Error fetching sweepstakes v2 status ${error.message}`)
                } else {
                    // @ts-ignore - TODO: better typing on error
                    logger.fatal(`Error fetching sweepstakes v2 status ${inspect(error)}`)
                }
            }
        },
        async maybeFetchPrefillData({ state, commit }, payload: { phoneNumber: string; last4Ssn: string }) {
            if (state.addressStreet) {
                logger.log(`Already has address saved, meaning probably user has already input PII info, moving on`)
                return
            }

            try {
                const response = await getPrefillInformation(payload.phoneNumber, payload.last4Ssn)
                if (!response?.data?.success) {
                    logger.warn(`Unable to fetch prefill data`)
                    window.logEvent('event_advisor_prefill_data_fetch_failed')
                    return
                }

                const resultPayload = response.data.payload.prefillInformation
                const updatePayload = {
                    dateOfBirth: resultPayload.dateOfBirth,
                    firstName: resultPayload.firstName,
                    lastName: resultPayload.lastName,
                    addressData: {
                        addressStreet: resultPayload.addressData.addressStreet,
                        addressCity: resultPayload.addressData.addressCity,
                        addressState: resultPayload.addressData.addressState,
                        addressPostalCode: resultPayload.addressData.addressPostalCode,
                    },
                } as UpdatePiiDataPayload
                logger.log(`Successfully retrieved prefill data`)
                window.logEvent('event_advisor_prefill_data_fetch_success')
                commit('updatePiiInformation', updatePayload)
            } catch (error) {
                // @ts-ignore - TODO: better typing on error
                logger.fatal(`Error fetching prefill data ${inspect(error)}`)
            }
        },
        async getShouldShowPaidReferrals({ commit }) {
            try {
                const response = await getIsEligibleForPaidReferrals()
                if (!response?.data?.success) {
                    logger.warn(`Unable to fetch getIsEligibleForPaidReferrals`)
                }
                commit('setShouldShowPaidReferrals', response.data.payload.isEligibleForPaidReferrals)
            } catch (error) {
                // @ts-ignore - TODO: better typing on error
                logger.error(`Error fetching paid referrals status ${inspect(error)}`)
            }
        },
        async maybeFetchNotableTrades({ commit, state }) {
            if (state.notablePeopleTrades !== null) {
                logger.log(`Aven Advisor already loaded notable trades`)
                return
            }
            try {
                logger.log(`Loading notable trades for Aven Advisor`)
                const response = await getNotableTrades()
                if (response.data.success && response.data.payload) {
                    logger.log(`Aven Advisor successfully loaded notable trades: ${JSON.stringify(response.data.payload)}`)
                    commit('setNotableTrades', response.data.payload)
                } else {
                    logger.fatal(`Aven Advisor failed to load notable trades with error: ${response.data.error}`)
                }
            } catch (e) {
                logger.fatal(`Error fetching Aven Advisor lead notable trades `, e)
            }
        },
        async maybeFetchCrimeReport({ state, commit }, forceRefresh = false, limit = DEFAULT_LIMIT_ON_CRIME_REPORTS) {
            if (state.crimeReport && !forceRefresh) {
                logger.log(`Already has crime report, not refetching`)
                return
            }

            try {
                const response = await getCrimeReport(forceRefresh, limit)
                if (!response?.data?.success) {
                    logger.warn(`Unable to fetch crime report`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved crime report`)
                commit('setCrimeReport', payload)
            } catch (error) {
                // @ts-ignore - TODO: better typing on error
                logger.error(`Error fetching crime report ${inspect(error)}`)
            }
        },
        async fetchStreakData({ commit }) {
            try {
                const response = await createVisitAndUpdateStreak()
                if (response?.data?.payload) {
                    commit('setStreakData', response.data.payload)
                } else {
                    logger.info(`Did not retrieve streak data`)
                }
            } catch (error) {
                logger.error(`Error fetching streak data`, error)
            }
        },
        async maybeFetchLienInfo({ state, commit }) {
            if (state.lienInfo) {
                logger.log(`Already has lien info, not refetching`)
                return
            }

            try {
                const response = await getLienInfo()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch lien info`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved lien info`)
                commit('setLienInfo', payload)
            } catch (error) {
                logger.error(`Error fetching lien info`, error)
            }
        },
        async maybeFetchHomeEquityInfo({ state, commit }) {
            if (state.homeEquityInfo) {
                logger.log(`Already has home equity info, not refetching`)
                return
            }

            try {
                state.didFetchHomeEquityInfo = false
                const response = await getHomeEquityInfo()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch home equity info`)
                    state.didFetchHomeEquityInfo = true
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved home equity info`)
                commit('setHomeEquityInfo', payload)
            } catch (error) {
                logger.error(`Error fetching home equity info`, error)
            }
        },
        async maybeFetchExpiredPlaidItems({ state, commit }) {
            if (state.expiredPlaidItems) {
                logger.log(`Already has expired plaid items, not refetching`)
                return
            }

            try {
                const response = await getExpiredPlaidItems()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch expired plaid items`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved expired plaid items`)
                commit('setExpiredPlaidItems', payload)
            } catch (error) {
                logger.error(`Error fetching expired plaid items`, error)
            }
        },
        /** @deprecated - use MethodFi */
        async fetchDataDependentOnExperian({ dispatch, getters }) {
            logger.log(`Fetching Aven Advisor data dependent on Experian`)
            // We fetch Experian data first because the subsequent fetches use that Experian data. However,
            // Even if Experian fails, we can call the methods below. They won't throw; they'll either
            // no-op or use fallback values
            await dispatch('maybeFetchCreditScoreData')

            // Todo Uncomment when we're ready to show the HELOC pre-qual card
            // this.helocPrequalOfferData = await this.tryGenerateHelocCardPreQualOffer()
            if (featureFlagConfig.creditOffers && getters.didAcceptCreditOfferDisclosure) {
                await dispatch('maybeInitiateCreditOffers', { fcraLanguage: fcraDisclosure.body, tcpaLanguage: tcpaDisclosure.body })
                await dispatch('maybeFetchCreditOffers')
            }

            await Promise.allSettled([dispatch('maybeGetHomeEquityInvestmentEstimate')])

            logger.log(`Finished fetching Aven Advisor data dependent on Experian`)
        },
        async maybeFetchSaleListings({ state, commit }) {
            if (state.saleListings) {
                logger.log(`Already has sale listings info, not refetching`)
                return
            }

            try {
                const response = await getSaleListings()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch sale listings info`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved sale listings info`)
                commit('setSaleListings', payload)
            } catch (error) {
                logger.error(`Error fetching sale listings info`, error)
            }
        },
        async maybeFetchRentalListings({ state, commit }) {
            if (state.rentalListings) {
                logger.log(`Already has rental listings info, not refetching`)
                return
            }

            try {
                const response = await getRentListings()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch rental listings info`)
                    return
                }

                const payload = response.data.payload
                logger.log(`Successfully retrieved rental listings info`)
                commit('setRentalListings', payload)
            } catch (error) {
                logger.error(`Error fetching rental listings info`, error)
            }
        },
        async maybeFetchCraigslistListings({ state, commit }) {
            if (state.allListings) {
                logger.log(`Already has craigslist listings, not refetching`)
                return
            }
            logger.log('No craigslist listings, fetching')
            try {
                const response = await getCraigslistListings()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unable to fetch craigslist listings`)
                    return
                }
                logger.info(`Successfully fetched craigslist listings`)
                commit('setCraigslistListings', response.data.payload)
            } catch (e) {
                logger.error(`Error fetching craigslist listings`, e)
            }
        },
        async maybeFetchPushNotifications({ state, commit }) {
            if (state.notifications) {
                logger.log(`Already has notifications, not refetching`)
                return
            }
            logger.log('No notifications, fetching')
            try {
                const response = await getPushNotifications()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unable to fetch notifications`)
                    return
                }
                logger.info(`Successfully fetched notifications`)
                commit('setNotifications', response.data.payload)
            } catch (e) {
                logger.error(`Error fetching notifications`, e)
            }
        },
        async logPushNotificationClick({ commit }, payload: { pushNotificationId: number }) {
            commit('markNotificationClicked', payload)
            try {
                const response = await logPushNotificationClick(payload.pushNotificationId)
                if (!response.data.success) {
                    logger.warn(`Unable to log push notification click`)
                    return
                }
                logger.info(`Successfully logged push notification click`)
            } catch (e) {
                logger.error(`Error logging push notification click`, e)
            }
        },
        async maybeFetchMyLienReport({ state, commit }): Promise<FetchAvenAdvisorLienReportResponse | null> {
            const monthAgo = new Date()
            monthAgo.setMonth(monthAgo.getMonth() - 1)

            // if we already have the lien report, don't refetch
            if (state.myLienReportData && state.myLienReportStatus === AvenAdvisorLienReportStatus.FOUND) {
                // check that last fetch date was in the last month
                const lastFetchDate = state.myLienReportDataLastUpdated
                if (lastFetchDate && lastFetchDate > monthAgo) {
                    logger.log(`Already has recent lien report, not refetching`)
                    return {
                        lienReport: state.myLienReportData,
                        updatedAt: lastFetchDate,
                        status: state.myLienReportStatus,
                    }
                }
            }

            // try fetching latest job from backend
            const latestLienReportFetch = await getLatestLienReport()
            if (latestLienReportFetch && latestLienReportFetch.status === AvenAdvisorLienReportStatus.FOUND) {
                // check if this report is from the last month
                if (latestLienReportFetch.updatedAt && latestLienReportFetch.updatedAt > monthAgo) {
                    // we have a recent report, so use it
                    commit('setMyLienReport', latestLienReportFetch)
                    return latestLienReportFetch
                }
            }

            // force new lien report fetch
            await getLatestLienReport(true)

            // poll for lien report
            const maxPollTries = 10
            const pollInterval = 1500

            for (let i = 0; i < maxPollTries; i++) {
                // report won't be ready right away, so wait a bit
                await new Promise((resolve) => setTimeout(resolve, pollInterval))

                logger.info(`Polling for lien report data, attempt ${i + 1}`)
                const pollResponse = await getLatestLienReport()
                if (!pollResponse) {
                    logger.error('Failed to poll for lien report data')
                    continue
                }

                const lienReportData = pollResponse
                if (!lienReportData) {
                    logger.error('Failed to get lien report data from poll response. This may happen if the lien report does not exist')
                    return null
                }

                if ([AvenAdvisorLienReportStatus.FOUND, AvenAdvisorLienReportStatus.NOT_FOUND].includes(lienReportData.status)) {
                    // terminal statuses, we should stop polling
                    commit('setMyLienReport', lienReportData)
                } else if (lienReportData.status === AvenAdvisorLienReportStatus.ERROR) {
                    logger.error('There was an error generating the lien report')
                } else if (lienReportData.status === AvenAdvisorLienReportStatus.PENDING) {
                    // still pending, continue polling
                    continue
                }

                return lienReportData
            }

            logger.error('Max poll tries exceeded for lien report')
            return null
        },
        async maybeFetchAvenAdvisorCreditCardRecommendations({ state, commit }): Promise<AvenAdvisorCreditCardRecommendationData[]> {
            if (state.avenAdvisorCreditCardRecommendations) {
                logger.log(`Already has credit card recommendations, not refetching`)
                return state.avenAdvisorCreditCardRecommendations
            }

            try {
                const cards = await getAvenAdvisorCreditCardRecommendations()
                logger.log(`Successfully retrieved credit card recommendations`)
                commit('setAvenAdvisorCreditCardRecommendations', { recommendations: cards })
                return cards
            } catch (error) {
                logger.error(`Error fetching credit card recommendations`, error)
                return []
            }
        },
        async employeeRefreshMethodFiEntity() {
            try {
                await employeeRefreshMethodFiEntity()
                logger.log(`Successfully refreshed MethodFi entity`)
            } catch (error) {
                logger.error(`Error refreshing MethodFi entity`, error)
            }
        },
        async maybeFetchFreeCoffeeInfo({ state, commit }, force = false) {
            if (state.freeCoffeeInfo && !force) {
                logger.log(`Already has free coffee info, not refetching`)
                return state.freeCoffeeInfo
            }

            try {
                const response = await getFreeCoffeeInfo()
                logger.log(`Successfully retrieved free coffee info}`)
                commit('setFreeCoffeeInfo', response?.data.payload)
                return response?.data.payload
            } catch (error) {
                logger.error(`Error fetching free coffee info`, error)
                return
            }
        },
        async maybeFetchFreeCoffeeSelection({ state, commit }, force = false) {
            if (state.freeCoffeeSelection && !force) {
                logger.log(`Already has free coffee selection, not refetching`)
                return state.freeCoffeeSelection
            }

            try {
                const response = await getFreeCoffeeSelection()
                if (!response?.data?.success || !response?.data?.payload) {
                    logger.warn(`Unable to fetch free coffee selection`)
                    return
                }
                logger.log(`Successfully retrieved free coffee selection ${inspect(response)}`)
                commit('setFreeCoffeeSelection', response?.data.payload)
                logger.log(`Successfully set free coffee selection ${state.freeCoffeeSelection}`)
                return response?.data.payload
            } catch (error) {
                logger.error(`Error fetching free coffee selection`, error)
                return
            }
        },
        async maybeFetchAvenAdvisorBorrowingCapacity({ state, commit }) {
            logger.log(`Maybe fetching Aven Advisor borrowing capacity from API`)
            if (state.borrowingCapacity) {
                logger.log(`Already has borrowing capacity, not refetching`)
                return
            }
            try {
                const response = await getBorrowingCapacity()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unable to fetch borrowing capacity`)
                    return
                }
                logger.info(`Successfully fetched borrowing capacity`)
                commit('setBorrowingCapacity', { borrowingCapacity: response.data.payload })
            } catch (error) {
                logger.error(`Failed fetching borrowing capacity`, error)
                return null
            }
        },
        async maybeGetExperianPullWithTrendedTradelines({ state, commit }) {
            logger.log(`Maybe fetching experian pull with trended tradelines`)
            if (state.didPullTrendedTradelineInfo) {
                logger.log(`Already has experian pull with trended tradelines, not refetching`)
                return
            }
            try {
                const response = await getExperianPullWithTrendedTradelines()
                if (!response.data.success || !response.data.payload) {
                    logger.warn(`Unable to fetch experian pull with trended tradelines`)
                    return
                }
                logger.log(`Successfully retrieved experian pull with trended tradelines`)
                commit('didPullTrendedTradelineInfo', true)
            } catch (error) {
                logger.error(`Error fetching experian pull with trended tradelines`, error)
            }
        },
    },
    plugins: [persistedData],
})
