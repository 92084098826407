"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.freeCoffeePromos = exports.freeCoffeeTypeToAvenAdvisorPromoNameMap = exports.avenAdvisorPromoNameToFreeCoffeeTypeMap = exports.AvenAdvisorPromoName = void 0;
const freeCoffeeTypes_1 = require("./freeCoffeeTypes");
var AvenAdvisorPromoName;
(function (AvenAdvisorPromoName) {
    AvenAdvisorPromoName["freeStarbucks700Vantage"] = "free_starbucks_700_vantage";
    AvenAdvisorPromoName["freeDunkin700Vantage"] = "free_dunkin_700_vantage";
})(AvenAdvisorPromoName = exports.AvenAdvisorPromoName || (exports.AvenAdvisorPromoName = {}));
exports.avenAdvisorPromoNameToFreeCoffeeTypeMap = {
    [AvenAdvisorPromoName.freeStarbucks700Vantage]: freeCoffeeTypes_1.FreeCoffeeType.STARBUCKS,
    [AvenAdvisorPromoName.freeDunkin700Vantage]: freeCoffeeTypes_1.FreeCoffeeType.DUNKIN,
};
exports.freeCoffeeTypeToAvenAdvisorPromoNameMap = Object.entries(exports.avenAdvisorPromoNameToFreeCoffeeTypeMap).reduce((acc, [promo, coffee]) => {
    acc[coffee] = promo;
    return acc;
}, {});
exports.freeCoffeePromos = Object.keys(exports.avenAdvisorPromoNameToFreeCoffeeTypeMap);
