// copied from /aven_frontend/aven/src/mixins/seonManager.ts

import assert from 'assert'

import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { OnError, OnSuccess } from './seonManager.types'

const SEON_AGENT_SDK_SRC = 'https://cdn.seondf.com/js/v5/agent.js'

export class SeonManager {
    private readonly onSeonSuccess: OnSuccess
    private readonly onSeonError: OnError
    private initialized = false
    private initializedPromise: Promise<boolean> | null = null

    constructor(onSeonSuccess: OnSuccess, onSeonError: OnError) {
        this.onSeonSuccess = onSeonSuccess
        this.onSeonError = onSeonError
    }

    private initInternal = async (): Promise<boolean> => {
        // Load the Seon script async
        await this.loadSeonScript()
        this.initialized = await this.setupSeon()
        return this.initialized
    }

    public init = async (): Promise<boolean> => {
        if (this.initialized) {
            logger.info('Seon already initialized')
            return true
        }

        if (this.initializedPromise) {
            logger.info('Seon already initializing')
            return this.initializedPromise
        }

        logger.info('Initializing Seon...')
        this.initializedPromise = this.initInternal()
        const result = await this.initializedPromise
        this.initializedPromise = null
        return result
    }

    private loadSeonScript = async (): Promise<void> => {
        for (const x of document.head.getElementsByTagName('script')) {
            if (x.src === SEON_AGENT_SDK_SRC) {
                logger.info('Seon already loaded')
                return
            }
        }
        logger.info('Seon script not found in DOM. Attaching...')

        return new Promise((resolve) => {
            const script = document.createElement('script')
            script.onload = () => {
                logger.info('Seon script loaded')
                resolve()
            }
            script.setAttribute('src', SEON_AGENT_SDK_SRC)
            logger.info('Waiting for Seon script to load...')
            document.head.appendChild(script)
        })
    }

    private setupSeon = async (): Promise<boolean> => {
        try {
            const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
            assert(sessionId)
            window.seon.config({
                session_id: sessionId,
                onSuccess: this.onSeonSuccess,
                onError: this.onSeonError,
            })
            return true
        } catch (error) {
            logger.fatal('Seon initialize failed error:', null /* event */, error as unknown as any)
        }
        return false
    }

    public getSessionFingerprint = async (): Promise<string | undefined> => {
        if (process.env.VUE_APP_NODE_ENV !== 'production') {
            logger.info(`do not call seon because not in production`)
            return undefined
        }
        try {
            logger.info('Getting encryptedPayload from Seon SDK...')
            const encryptedPayload = await window.seon.getBase64Session()
            logger.info('Getting encryptedPayload from Seon SDK... Done')
            return encryptedPayload
        } catch (error) {
            logger.fatal('Seon get session fingerprint failed error:', null /* event */, error as unknown as any)
        }
        return undefined
    }
}
